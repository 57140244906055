import { useMemo } from 'react';
import { HolidayType } from '../../../../redux/holidays';
import { HOLIDAY_NAMES } from '../../../../redux/holidays/selectors';
import { SocialSecurityBenefitsType } from '../../../../redux/socialSecurityBenefits';
import { MappedHoliday } from '../../../../types/holidayAdmin';
import {
  BAR_HEIGHT_OFF_SET,
  SOCIAL_SECURITY_BENEFIT_NAMES,
} from '../../../../utils/consts';
import {
  checkIsCompanyHolidayOrNational,
  mapColors,
} from '../../../../utils/helpers';

export const useMappedHolidaysData = (
  data: MappedHoliday[][],
  currentUserId?: number
) =>
  useMemo(
    () =>
      data
        .map((holidays) => {
          return holidays.map(
            ({
              userName,
              datePeriod,
              isAcceptedByAdmin,
              isAcceptedByEmployee,
              userId,
              id,
              dateTo,
              type,
              dateFrom,
              addInfo,
            }) => ({
              data: [
                {
                  x: userName,
                  y: datePeriod ?? [],
                  barHeightOffset: checkIsCompanyHolidayOrNational(
                    type as HolidayType
                  )
                    ? BAR_HEIGHT_OFF_SET
                    : null,
                  fillColor: mapColors(
                    isAcceptedByAdmin,
                    isAcceptedByEmployee,
                    currentUserId,
                    userId,
                    type as SocialSecurityBenefitsType
                  ),
                  id: isNaN(Number(id)) ? null : Number(id),
                  type,
                  dateFrom,
                  addInfo,
                  dateTo,
                },
              ],
              name:
                checkIsCompanyHolidayOrNational(type as HolidayType) && addInfo
                  ? addInfo
                  : HOLIDAY_NAMES[type as HolidayType] ??
                    SOCIAL_SECURITY_BENEFIT_NAMES[
                      type as SocialSecurityBenefitsType
                    ],
            })
          );
        })
        .flat(),
    [data, currentUserId]
  );
