import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Heading,
  ModalHeader,
  ModalBody,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { selectUserHolidayPool } from '../../../../../redux/holidayPools/selectors';
import { editUserHolidayPool } from '../../../../../redux/holidayPools/thunks';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { FormWrapper } from '../../../../../components/form/FormWrapper';
import { HolidayPoolForm } from './HolidayPoolForm';
import { getDirtyValues } from '../../../../../utils/hookForm';
import { generateYearOptions } from './utils';

interface EditUserHolidayPoolFormStringYear {
  id: number;
  year?: string;
  days?: string;
  daysFromPreviousYear?: string;
}

interface Props {
  holidayPoolId: number | undefined;
  onClose: () => void;
}
export const EditHolidayPool: FC<Props> = ({ holidayPoolId, onClose }) => {
  const dispatch = useAppDispatch();
  const selectedHolidayPool = useAppSelector((state) =>
    selectUserHolidayPool(state, holidayPoolId)
  );

  const currentYear = selectedHolidayPool ? selectedHolidayPool.year : 1;
  const options = generateYearOptions(currentYear);

  const methods = useForm<EditUserHolidayPoolFormStringYear>({
    defaultValues: selectedHolidayPool
      ? { ...selectedHolidayPool, year: selectedHolidayPool.year.toString() }
      : selectedHolidayPool,
  });
  const dirtyFields = methods.formState.dirtyFields;

  const handleSubmit = async (data: EditUserHolidayPoolFormStringYear) => {
    if (!holidayPoolId) return;
    const changeset = {
      ...{ id: holidayPoolId },
      ...getDirtyValues(
        dirtyFields,
        data.year ? { ...data, year: +data.year } : data
      ),
    };
    try {
      await dispatch(editUserHolidayPool(changeset)).unwrap();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal isOpen onClose={onClose} size="xl" autoFocus={false}>
      <ModalOverlay backdropFilter="blur(30px)" />
      <ModalContent px={['12', '24']} py={['8', '16']} justifyContent="center">
        <ModalHeader textAlign="center">
          <Heading as="h1" m={4} pb={[0, 10]} size="lg" fontWeight={300}>
            EDYTUJ PULĘ URLOPOWĄ
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormWrapper methods={methods} onSubmit={handleSubmit}>
            <HolidayPoolForm options={options} isEditing={true} />
          </FormWrapper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
