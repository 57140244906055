import { FC } from 'react';
import { Link, HStack, Text, Icon } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { Link as ReactLink } from 'react-router-dom';

interface Props {
  destination: string;
  label: string;
  linkIcon: IconType;
  isLinkExternal?: boolean;
  openInNewTab?: boolean;
  onLinkClick: () => void;
}

export const DrawerLink: FC<Props> = ({
  destination,
  isLinkExternal,
  openInNewTab,
  label,
  linkIcon,
  onLinkClick,
}) =>
  isLinkExternal ? (
    <Link
      href={destination}
      isExternal={openInNewTab}
      fontSize="size7"
      fontWeight={300}
      padding="0.5625rem"
      display="inline-block"
      _hover={{
        textDecoration: 'none',
        color: 'active',
      }}
      onClick={onLinkClick}
    >
      <HStack>
        <Icon fontSize="1.25rem" as={linkIcon} />
        <Text textTransform="uppercase" lineHeight="1">
          {label}
        </Text>
      </HStack>
    </Link>
  ) : (
    <Link
      as={ReactLink}
      to={destination}
      fontSize="size7"
      fontWeight={300}
      padding="0.5625rem"
      display="inline-block"
      _hover={{
        textDecoration: 'none',
        color: 'active',
      }}
      onClick={onLinkClick}
    >
      <HStack>
        <Icon fontSize="1.25rem" as={linkIcon} />
        <Text textTransform="uppercase" lineHeight="1">
          {label}
        </Text>
      </HStack>
    </Link>
  );
