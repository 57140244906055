import { EventInput } from '@fullcalendar/react';
import { useMemo } from 'react';
import { UserWithHolidaysModel } from '../../../../redux/holidays';
import { SocialSecurityBenefitsWithUserModel } from '../../../../redux/socialSecurityBenefits';
import { apexChartSeries } from '../config';
import { useMappedHolidays } from './useMappedHolidays';
import { useMappedHolidaysData } from './useMappedHolidaysData';
import { useSetHolidays } from './useSetHolidays';

export const useHolidaysSeries = (
  data: UserWithHolidaysModel[],
  nationalHolidayEvents: EventInput[],
  socialBenefits: SocialSecurityBenefitsWithUserModel[],
  month: number,
  currentUserId?: number
) => {
  const setHolidays = useSetHolidays(
    data,
    nationalHolidayEvents,
    socialBenefits
  );

  const mappedHolidays = useMappedHolidays(setHolidays, month);

  const mappedHolidaysData = useMappedHolidaysData(
    mappedHolidays,
    currentUserId
  );

  return useMemo(
    () => apexChartSeries(mappedHolidaysData),
    [mappedHolidaysData]
  );
};
