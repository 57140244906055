import { Modal, ModalOverlay } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../store';
import { DataForReportInterface } from '../../../redux/holidays';
import { selectIsLoadingGenerateReport } from '../../../redux/holidays/selectors';
import { generateHolidayReport } from '../../../redux/holidays/thunks';
import { UserWithHolidaysModel } from '../../../redux/holidays';
import { ReportByContractType } from './generateReportByContractType';
import { ReportByEmployee } from './generateReportByEmployee';
import { ReportTypeSelection } from './reportTypeSelection';
import { CenteredSpinner } from '../../common/CenteredSpinner';
import { SelectOption } from '../../../types/SelectOptions';
import { MULTISELECT_ALL_SELECTED_LABEL } from '../../../utils/consts';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  holidays: UserWithHolidaysModel[];
}

export const GenerateReport: FC<Props> = ({ onClose, isOpen, holidays }) => {
  const isLoading = useAppSelector(selectIsLoadingGenerateReport);

  const methods = useForm<DataForReportInterface>();
  const [selectedReportType, setSelectedReportType] = useState<
    string | undefined
  >();
  const { watch, setValue, control } = methods;

  const dispatch = useAppDispatch();

  const submitHandler = async (
    data: DataForReportInterface,
    usersFromMultiSelect?: readonly SelectOption[]
  ) => {
    if (!selectedReportType) return;

    const selectedEmployees = usersFromMultiSelect
      ?.filter(({ value }) => value !== MULTISELECT_ALL_SELECTED_LABEL)
      .map((sel) => Number(sel.value));

    try {
      await dispatch(
        generateHolidayReport({
          ...data,
          employees: selectedEmployees,
          reportType: selectedReportType,
        })
      )?.unwrap();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const getReportGenerationModal = () => {
    if (!selectedReportType)
      return (
        <ReportTypeSelection
          onClose={onClose}
          setSelectedReportType={setSelectedReportType}
        />
      );

    if (selectedReportType === 'by_contract_type') {
      return (
        <ReportByContractType
          submitHandler={submitHandler}
          dateFromWatcher={dateFromWatcher}
          methods={methods}
          setSelectedReportType={setSelectedReportType}
          isLoading={isLoading}
        />
      );
    } else if (selectedReportType === 'by_employee') {
      return (
        <ReportByEmployee
          submitHandler={submitHandler}
          dateFromWatcher={dateFromWatcher}
          methods={methods}
          holidays={holidays}
          setSelectedReportType={setSelectedReportType}
          isLoading={isLoading}
        />
      );
    }
  };

  const dateFromWatcher = useWatch({ name: 'dateFrom', control });

  useEffect(() => {
    const subscription = watch((value, { type, name }) => {
      if (
        name === 'dateFrom' &&
        type === 'change' &&
        value.dateFrom != null &&
        value.dateTo != null &&
        value.dateFrom > value.dateTo
      )
        setValue('dateTo', value.dateFrom);
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return isLoading ? (
    <CenteredSpinner />
  ) : (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      autoFocus={false}
      returnFocusOnClose={false}
    >
      <ModalOverlay backdropFilter="blur(30px)" />
      {getReportGenerationModal()}
    </Modal>
  );
};
