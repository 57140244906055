import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { getYear } from 'date-fns/esm';
import { selectIsAddHolidayPoolLoading } from '../../../../../redux/holidayPools/selectors';
import { addUserHolidayPool } from '../../../../../redux/holidayPools/thunks';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { FormWrapper } from '../../../../../components/form/FormWrapper';
import { CenteredSpinner } from '../../../../../components/common/CenteredSpinner';
import { AddUserHolidayPoolForm } from '../../../../../types/forms';
import { HolidayPoolForm } from './HolidayPoolForm';
import { generateYearOptions } from './utils';

interface Props {
  userId: number;
}
export const AddHolidayPool: FC<Props> = ({ userId }) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsAddHolidayPoolLoading);

  const currentYear = getYear(new Date());
  const options = generateYearOptions(currentYear);

  const methods = useForm<AddUserHolidayPoolForm>({
    defaultValues: {
      user: userId,
    },
  });

  const handleSubmit = async (data: AddUserHolidayPoolForm) => {
    methods.reset({
      user: userId,
      year: undefined,
      days: undefined,
      daysFromPreviousYear: undefined,
    });
    try {
      await dispatch(
        addUserHolidayPool({ ...data, year: data.year ? +data.year : null })
      ).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  return isLoading ? (
    <CenteredSpinner />
  ) : (
    <FormWrapper methods={methods} onSubmit={handleSubmit}>
      <HolidayPoolForm options={options} isEditing={false} />
    </FormWrapper>
  );
};
