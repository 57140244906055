import { createAsyncThunk } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';
import {
  DeleteUserHolidayForm,
  HolidayAddModel,
  HolidayWithUserModel,
  UserWithHolidaysModel,
  DataForReportInterface,
} from '.';
import client from '../../api/axiosConfig';
import { formatToBackendDate } from '../../utils/date';
import { displaySuccessToast } from '../../utils/toasts';
import { ThunkError } from '../../types/thunk';
import { handleError } from '../../utils/handleError';
import { AppDispatch } from '../../store';
import { setCurrentHolidays, DeleteUserHolidayFormReturn } from '.';

// ---------------------------- CALENDAR HOLIDAYS ----------------------------

export const fetchUsersWithHolidays = createAsyncThunk<
  UserWithHolidaysModel[] | undefined,
  string | undefined
>('user/fetch/holidays', async (orderingField, { rejectWithValue }) => {
  try {
    const response = await client.get<{ results: UserWithHolidaysModel[] }>(
      `people/holiday-user/${orderingField ? `?ordering=${orderingField}` : ''}`
    );

    return response.data.results;
  } catch (error) {
    handleError(error as ThunkError);
    return rejectWithValue((error as ThunkError)?.response?.data);
  }
});

export const addHolidayAsEmployee = createAsyncThunk<
  HolidayWithUserModel | undefined,
  HolidayAddModel
>(
  'holiday/create',
  async (
    { user, dateFrom, dateTo, type, addInfo, isHalfDay },
    { rejectWithValue }
  ) => {
    try {
      if (!dateFrom || !dateTo) return; // Required in validation
      const response = await client.post<{ results: HolidayWithUserModel }>(
        'people/holiday/create-employee-holiday/',
        {
          user,
          dateFrom: formatToBackendDate(dateFrom),
          dateTo: formatToBackendDate(dateTo),
          type,
          isHalfDay,
          addInfo: addInfo === '' ? null : addInfo,
        }
      );
      if (response.data.results)
        displaySuccessToast({ title: 'Dodano wniosek urlopowy' });
      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

export const cancelHolidayAsEmployee = createAsyncThunk<
  DeleteUserHolidayFormReturn | undefined,
  DeleteUserHolidayForm
>(
  'user/delete/holidays-employee',
  async ({ id, cancellationInfo }, { rejectWithValue }) => {
    try {
      const response = await client.patch<{
        results: DeleteUserHolidayFormReturn;
      }>(`people/holiday/${id}/cancel-employee-holiday/`, {
        cancellationInfo,
      });

      displaySuccessToast({ title: 'Usunięto pomyślnie' });

      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

export const generateHolidayReport = createAsyncThunk<
  DataForReportInterface | undefined,
  DataForReportInterface
>(
  'holidayPool/generateHolidayReport',
  async (dataForReport, { rejectWithValue }) => {
    try {
      const response = await client.get<Blob>(
        'people/holiday/generate-holiday-report/',
        {
          params: {
            reportType: dataForReport.reportType,
            reportScope: dataForReport.reportScope,
            dateFrom: formatToBackendDate(dataForReport.dateFrom),
            dateTo: formatToBackendDate(dataForReport.dateTo),
            contractsType: dataForReport.contractsType,
            employees: dataForReport.employees?.toString(),
          },
          responseType: 'blob',
        }
      );

      if (!response.data) return;

      fileDownload(
        response.data,
        response.headers['content-disposition']
          .split('filename=')[1]
          .split(';')[0]
          .slice(0, -1)
      );

      displaySuccessToast({ title: 'Wygenerowano pomyślnie' });
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

export function fetchHolidayUsersAndSetCurrentHolidays(
  id: number,
  orderingField?: string
) {
  return async (dispatch: AppDispatch) => {
    await dispatch(fetchUsersWithHolidays(orderingField));
    return dispatch(setCurrentHolidays(id));
  };
}
