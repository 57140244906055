import { GridItem } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  label: string;
}

export const GridRow: FC<Props> = ({ label, children }) => (
  <>
    <GridItem pb={1}>{label}</GridItem>
    <GridItem
      pb={1}
      pl={4}
      ml={4}
      borderLeft="1px solid"
      borderColor="active"
      color="secondary"
    >
      {children ?? '-'}
    </GridItem>
  </>
);
