import { FC } from 'react';
import {
  Button,
  ModalBody,
  ModalContent,
  ModalHeader,
  Heading,
  VStack,
} from '@chakra-ui/react';

interface Props {
  onClose: () => void;
  setSelectedReportType: (selectedReportType: string | undefined) => void;
}

export const ReportTypeSelection: FC<Props> = ({
  onClose,
  setSelectedReportType,
}) => {
  return (
    <ModalContent px="8.4375rem">
      <ModalHeader textAlign="center" px="0" pt="6.25rem">
        <Heading as="h1" m={4} size="lg" fontWeight={300}>
          EKSPORTUJ WEDŁUG
        </Heading>
      </ModalHeader>
      <ModalBody px="0" pb="6.875rem">
        <VStack>
          <Button
            variant="main"
            size="md"
            height="44px"
            width={['100%', null, null, '300px']}
            borderRadius="30px"
            onClick={() => setSelectedReportType('by_employee')}
          >
            OSÓB
          </Button>
          <Button
            variant="main"
            size="md"
            height="44px"
            width={['100%', null, null, '300px']}
            borderRadius="30px"
            onClick={() => setSelectedReportType('by_contract_type')}
          >
            RODZAJU UMOWY
          </Button>
          <Button variant="inverted" size="md" height="44px" onClick={onClose}>
            Cofnij
          </Button>
        </VStack>
      </ModalBody>
    </ModalContent>
  );
};
