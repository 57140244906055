import { Stack, Text } from '@chakra-ui/react';
import { UserHolidayPool } from '../../../redux/holidayPools';
import { displayHolidayPoolField } from '../../../utils/holidayPool';

export function formatHolidayPoolGeneralInfoForOneUser(
  holidayPool?: UserHolidayPool
) {
  return (
    <Stack
      borderLeft="1px solid"
      borderColor="active"
      spacing={1}
      pl={4}
      pt={1}
      fontSize="size4"
    >
      <Text>
        WYKORZYSTANE: {displayHolidayPoolField(holidayPool?.usedHolidayPool)}
      </Text>
      <Text>
        DO WYKORZYSTANIA:{' '}
        {displayHolidayPoolField(holidayPool?.leftHolidayPool)}
      </Text>
    </Stack>
  );
}
