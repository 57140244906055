import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  Button,
  List,
  Box,
  theme,
  useMediaQuery,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { setCurrentHolidays } from '../../../redux/holidays';
import { ALL_HOLIDAYS } from '../../../utils/consts';
import {
  fetchAllUsersSocialSecurityBenefits,
  fetchUserSocialSecurityBenefits,
} from '../../../redux/socialSecurityBenefits/thunks';
import { ScrollableSelect } from '../../../components/common/Select/scrollableSelect';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  selectCurrentHolidays,
  selectHolidayUsers,
} from '../../../redux/holidays/selectors';
import { displayHolidayPools } from '../../../components/calendar/displayHolidayPoolsDetails';
import {
  selectIsHolidayPoolLoading,
  selectSpecificYearUserHolidayPool,
} from '../../../redux/holidayPools/selectors';
import { selectSocialSecurityBenefitsDaysSum } from '../../../redux/socialSecurityBenefits/selectors';
import { selectCurrentUserId } from '../../../redux/user/selectors';
import { UserHolidayPool } from '../../../redux/holidayPools';
import { CenteredSpinner } from '../../../components/common/CenteredSpinner';
import { colors } from '../../../theme';
import { SingleValue } from 'react-select';
import { MultiSelectOption } from '../../../types/multiSelectOption';
import { holidayInfo } from './styles';

interface HolidayInfoProps {
  specificYearUsersHolidayPool: UserHolidayPool[];
  currentYear: number;
  selectedUserId: number;
  setSelectedUserId: (userId: number) => void;
}

export const HolidayInfo: FC<HolidayInfoProps> = ({
  currentYear,
  setSelectedUserId,
  selectedUserId,
  specificYearUsersHolidayPool,
}) => {
  const dispatch = useAppDispatch();

  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
  const [allSelected, setAllSelected] = useState(true);

  const isLoading = useAppSelector(selectIsHolidayPoolLoading);
  const currentHolidays = useAppSelector(selectCurrentHolidays);
  const holidays = useAppSelector(selectHolidayUsers);
  const specificYearUserHolidayPool = useAppSelector((state) =>
    selectSpecificYearUserHolidayPool(state, selectedUserId)
  );
  const socialSecurityBenefitsDaysSum = useAppSelector(
    selectSocialSecurityBenefitsDaysSum
  );
  const userId = useAppSelector(selectCurrentUserId);

  const { isOpen, onToggle } = useDisclosure();

  if (isLoading || !currentHolidays) return <CenteredSpinner />;

  // variable stores a list of all employees
  const options = [
    { value: 0, label: 'Wszyscy' },
    ...(!holidays.length ? [currentHolidays] : holidays).map(
      ({ id, firstName, lastName }) => ({
        value: id,
        label: `${firstName} ${lastName}`,
      })
    ),
  ];

  const scrollableSelectHandler = (value: SingleValue<MultiSelectOption>) => {
    const numberValue = Number(value?.value);
    if (isNaN(numberValue)) return;
    dispatch(setCurrentHolidays(numberValue));
    setSelectedUserId(numberValue);

    if (numberValue !== ALL_HOLIDAYS) {
      void dispatch(
        fetchUserSocialSecurityBenefits({
          id: numberValue,
          isCancelled: false,
        })
      );
      setAllSelected(false);
    } else if (numberValue === ALL_HOLIDAYS) {
      void dispatch(
        fetchAllUsersSocialSecurityBenefits({ isCancelled: false })
      );
      setAllSelected(true);
    }
  };

  return (
    <VStack alignSelf="flex-start">
      {isMobile ? null : (
        <Button
          onClick={() => {
            onToggle();
            window.dispatchEvent(new Event('resize'));
          }}
          rightIcon={
            isOpen ? (
              <MdKeyboardArrowLeft color={colors.main} size={25} />
            ) : (
              <MdKeyboardArrowRight color={colors.main} size={25} />
            )
          }
          sx={holidayInfo.collapseButton(isOpen)}
        >
          {isOpen ? 'Zwiń' : 'Rozwiń'}
        </Button>
      )}
      {isOpen || isMobile ? (
        <>
          <Box sx={holidayInfo.scrollContainer}>
            <ScrollableSelect
              options={options}
              onChange={scrollableSelectHandler}
              currentValue={options.find(
                (option) => option.value === currentHolidays?.id
              )}
              defaultValue={options.find(
                (option) => option.value === ALL_HOLIDAYS
              )}
            />
          </Box>
          <Box sx={holidayInfo.listContainer}>
            <List
              className="list-with-invisible-scrollbar"
              sx={holidayInfo.list}
            >
              {displayHolidayPools(
                allSelected,
                true,
                specificYearUsersHolidayPool,
                currentYear,
                userId,
                selectedUserId,
                socialSecurityBenefitsDaysSum,
                specificYearUserHolidayPool
              )}
            </List>
            <Box className="blur-effect-list" />
          </Box>
        </>
      ) : null}
    </VStack>
  );
};
