import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { FormWrapper } from '../../../../../components/form/FormWrapper';
import { AddUserHolidayAdminForm } from '../../../../../types/forms';
import { HolidayForm } from './HolidayForm';
import { addHolidayAsAdmin } from '../../../../../redux/holidaysAdmin/thunks';
import { selectIsHolidaysLoading } from '../../../../../redux/holidays/selectors';
import { CenteredSpinner } from '../../../../../components/common/CenteredSpinner';

interface Props {
  userId: number;
}
export const AddHoliday: FC<Props> = ({ userId }) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsHolidaysLoading);

  const methods = useForm<AddUserHolidayAdminForm>({
    defaultValues: { user: userId },
  });

  const handleSubmit = async (data: AddUserHolidayAdminForm) => {
    const { isHalfDay = false, ...rest } = data;
    try {
      await dispatch(
        addHolidayAsAdmin({
          ...rest,
          isHalfDay,
          addInfo: data.compassionateSubType
            ? `${data.compassionateSubType} - ${data.addInfo}`
            : data.addInfo,
        })
      ).unwrap();
      methods.reset({
        user: userId,
        dateTo: null,
        dateFrom: null,
        type: '',
        compassionateSubType: '',
        addInfo: '',
        isHalfDay: false,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const subscription = methods.watch((value, { type, name }) => {
      if (
        (name === 'dateFrom' &&
          type === 'change' &&
          value.dateFrom != null &&
          value.dateTo != null &&
          value.dateFrom > value.dateTo) ||
        (name === 'isHalfDay' &&
          type === 'change' &&
          value.dateFrom != null &&
          value.dateTo != null &&
          value.isHalfDay === true)
      )
        methods.setValue('dateTo', value.dateFrom);
    });
    return () => subscription.unsubscribe();
  }, [methods]);

  return isLoading ? (
    <CenteredSpinner />
  ) : (
    <FormWrapper methods={methods} onSubmit={handleSubmit}>
      <HolidayForm />
    </FormWrapper>
  );
};
