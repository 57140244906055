import {
  Flex,
  Grid,
  IconButton,
  ListIcon,
  Spacer,
  theme,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { MdModeEdit } from 'react-icons/md';
import { FaTrashAlt } from 'react-icons/fa';
import { FC, useEffect, useState } from 'react';
import {
  selectIsHolidayPoolLoading,
  selectUserHolidayPools,
} from '../../../../../redux/holidayPools/selectors';
import { fetchUserHolidayPools } from '../../../../../redux/holidayPools/thunks';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { DateIcon } from '../../../../../components/common/Icons';
import { UserProfileListElement } from '../../../../../components/UserProfileList/UserProfileListElement';
import { UserProfileList } from '../../../../../components/UserProfileList';
import { EditHolidayPool } from './EditHolidayPool';
import { DeleteHolidayPool } from './DeleteHolidayPool';
import { CenteredSpinner } from '../../../../../components/common/CenteredSpinner';

interface Props {
  userId: number;
}
export const HolidayPool: FC<Props> = ({ userId }) => {
  const dispatch = useAppDispatch();
  const holidayPoolList = useAppSelector(selectUserHolidayPools);
  const isLoading = useAppSelector(selectIsHolidayPoolLoading);

  const deleteModalDisclosure = useDisclosure();
  const editModalDisclosure = useDisclosure();

  const [holidayPoolId, setHolidayPoolId] = useState<number>();
  const [holidayPoolIdDelete, setHolidayPoolIdDelete] = useState<number>();

  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);

  useEffect(() => {
    try {
      if (userId) void dispatch(fetchUserHolidayPools(userId)).unwrap();
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, userId]);

  const handleDeleteClick = (id: number) => {
    setHolidayPoolIdDelete(id);
  };

  const handleEditClick = (id: number) => {
    setHolidayPoolId(id);
  };

  if (holidayPoolList.length < 1 && isMobile) return null;

  return isLoading ? (
    <CenteredSpinner />
  ) : (
    <UserProfileList>
      {holidayPoolId != null && (
        <EditHolidayPool
          holidayPoolId={holidayPoolId}
          onClose={() => {
            editModalDisclosure.onClose();
            setHolidayPoolId(undefined);
          }}
        />
      )}
      {holidayPoolIdDelete != null && (
        <DeleteHolidayPool
          holidayPoolId={holidayPoolIdDelete}
          onClose={() => {
            deleteModalDisclosure.onClose();
            setHolidayPoolIdDelete(undefined);
          }}
        />
      )}
      {holidayPoolList?.map(
        ({ id, year, fullHolidayPool, usedHolidayPool, leftHolidayPool }) => (
          <UserProfileListElement key={id} id={id}>
            <Grid
              w="100%"
              templateColumns={[
                '1.5fr 1.5fr 2fr 0.9fr',
                null,
                null,
                null,
                null,
                '1.5fr 1.5fr 2fr 1fr',
              ]}
              fontSize="size4"
            >
              <Flex alignItems="center" pr={10} gap="30%">
                <ListIcon as={DateIcon} id={`${id}`} />
                {year}
              </Flex>
              <Flex alignItems="center" pr={3}>
                pula: {fullHolidayPool}
              </Flex>
              <Flex alignItems="center" pr={3}>
                wykorzystane: {usedHolidayPool}
              </Flex>
              <Flex alignItems="center">zostało: {leftHolidayPool}</Flex>
            </Grid>
            <Spacer />
            <IconButton
              icon={<FaTrashAlt />}
              aria-label={'Delete'}
              mr={1}
              ml={10}
              size="xs"
              variant="iconButton"
              onClick={() => handleDeleteClick(id)}
            />
            <IconButton
              icon={<MdModeEdit />}
              variant="iconButton"
              aria-label={'Edit'}
              size="xs"
              onClick={() => handleEditClick(id)}
            />
          </UserProfileListElement>
        )
      )}
    </UserProfileList>
  );
};
