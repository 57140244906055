import { Box, Button, IconButton } from '@chakra-ui/react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { FC, useMemo } from 'react';
import { addMonths, subMonths } from 'date-fns';
import { navigationButtons } from './styles';

interface NavigationButtonProps {
  date: Date;
  setDate: (date: Date) => void;
}

export const NavigationButtons: FC<NavigationButtonProps> = ({
  date,
  setDate,
}) => {
  const isCurrentMonth = useMemo(
    () =>
      date.getFullYear() === new Date().getFullYear() &&
      date.getMonth() === new Date().getMonth(),
    [date]
  );

  return (
    <Box sx={navigationButtons.container}>
      <IconButton
        aria-label={'previous'}
        onClick={() => setDate(subMonths(date, 1))}
        icon={<MdKeyboardArrowLeft size={25} />}
        sx={navigationButtons.iconButton('left')}
      />
      <IconButton
        aria-label={'next'}
        onClick={() => setDate(addMonths(date, 1))}
        icon={<MdKeyboardArrowRight size={25} />}
        sx={navigationButtons.iconButton('right')}
      />
      <Button
        sx={navigationButtons.button}
        isDisabled={isCurrentMonth}
        onClick={() => setDate(new Date())}
      >
        BIEŻĄCY MIESIĄC
      </Button>
    </Box>
  );
};
