import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CenteredSpinner } from '../components/common/CenteredSpinner';
import { HOLIDAY_ACCEPTED_MESSAGE } from '../utils/consts';
import {
  sendHolidayActionRequest,
  useNavigateAfterHolidayAcion,
} from '../utils/holidayActionRequests';
import { acceptHolidayEmployeeUrl } from '../utils/paths';

export const AcceptHolidayEmployee: FC = () => {
  const { holidayAcceptToken } = useParams<{ holidayAcceptToken: string }>();
  const navigateAfterHolidayAction = useNavigateAfterHolidayAcion();

  useEffect(() => {
    if (!holidayAcceptToken) return;

    let isCancelled = false;
    void sendHolidayActionRequest(
      acceptHolidayEmployeeUrl,
      holidayAcceptToken
    ).then((data) => {
      if (isCancelled) return;

      navigateAfterHolidayAction(data, HOLIDAY_ACCEPTED_MESSAGE, '/');

      return;
    });

    return () => {
      isCancelled = true;
    };
  }, [holidayAcceptToken, navigateAfterHolidayAction]);

  return <CenteredSpinner />;
};
