import { createSlice } from '@reduxjs/toolkit';
import { fetchUser } from './thunks';

export interface UserModel {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  isCompanyAdmin: boolean;
}

export interface UsersState {
  current: UserModel | null;
  isLoading: boolean;
}

const initialState: UsersState = {
  current: null,
  isLoading: true,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logOut(state) {
      state.current = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isLoading = false;

        if (action.payload != null) {
          state.current = action.payload;
        } else {
          state.current = null;
        }
      })
      .addCase(fetchUser.rejected, (state) => {
        state.isLoading = false;
        state.current = null;
      });
  },
});

export const { logOut } = userSlice.actions;

export default userSlice.reducer;
