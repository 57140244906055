import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { selectIsAddContractLoading } from '../../../../../redux/contracts/selectors';
import { addUserContract } from '../../../../../redux/contracts/thunks';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { FormWrapper } from '../../../../../components/form/FormWrapper';
import { AddUserContractForm } from '../../../../../types/forms';
import { FormContract } from './FormContract';
import { CenteredSpinner } from '../../../../../components/common/CenteredSpinner';

interface Props {
  userId: number;
}
export const AddContract: FC<Props> = ({ userId }) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsAddContractLoading);

  const methods = useForm<AddUserContractForm>({
    defaultValues: {
      user: userId,
    },
  });

  const handleSubmit = async (data: AddUserContractForm) => {
    methods.reset({
      user: userId,
      type: undefined,
      dateTo: null,
      dateFrom: null,
      holidayPool: undefined,
    });
    try {
      await dispatch(addUserContract(data)).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  return isLoading ? (
    <CenteredSpinner />
  ) : (
    <FormWrapper methods={methods} onSubmit={handleSubmit}>
      <FormContract />
    </FormWrapper>
  );
};
