import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { FormWrapper } from '../../../../../components/form/FormWrapper';
import { selectUserDelegation } from '../../../../../redux/delegation/selectors';
import { editUserDelegation } from '../../../../../redux/delegation/thunks';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { EditUserDelegationForm } from '../../../../../types/forms';
import { FormDelegation } from './FormDelegation';
import { getDirtyValues } from '../../../../../utils/hookForm';

interface Props {
  onClose: () => void;
  delegationId: number;
  userId: number;
}
export const EditDelegation: FC<Props> = ({
  delegationId,
  onClose,
  userId,
}) => {
  const dispatch = useAppDispatch();
  const result = useAppSelector((state) =>
    selectUserDelegation(state, delegationId)
  );

  const methods = useForm<EditUserDelegationForm>({
    defaultValues: {
      id: delegationId,
      dateTo: result?.dateTo ? new Date(result.dateTo) : undefined,
      dateFrom: result?.dateFrom ? new Date(result.dateFrom) : undefined,
      addInfo: result?.addInfo,
    },
  });
  const dirtyFields = methods.formState.dirtyFields;

  const handleSubmit = async (data: EditUserDelegationForm) => {
    if (!delegationId) return;

    dirtyFields.dateFrom = true;
    dirtyFields.dateTo = true;

    const changeset = getDirtyValues(dirtyFields, data);

    try {
      await dispatch(
        editUserDelegation({ ...changeset, id: delegationId, user: userId })
      ).unwrap();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal isOpen onClose={onClose} size="xl" autoFocus={false}>
      <ModalOverlay backdropFilter="blur(30px)" />
      <ModalContent px={['12', '24']} py={['8', '16']} justifyContent="center">
        <ModalHeader textAlign="center">
          <Heading as="h1" m={4} pb={[0, 10]} size="lg" fontWeight={300}>
            EDYTUJ DELEGACJĘ
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormWrapper methods={methods} onSubmit={handleSubmit}>
            <FormDelegation isEditing={true} />
          </FormWrapper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
