import {
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { FormWrapper } from '../../../../../components/form/FormWrapper';
import { EditUserHolidayAdmin } from '../../../../../types/forms';
import { selectHolidayById } from '../../../../../redux/holidays/selectors';
import { HolidayForm } from './HolidayForm';
import { editHoliday } from '../../../../../redux/holidaysAdmin/thunks';
import { fetchUserHolidayPools } from '../../../../../redux/holidayPools/thunks';
import { getDirtyValues } from '../../../../../utils/hookForm';

interface Props {
  onClose: () => void;
  holidayId: number;
  userId: number;
}
export const EditHoliday: FC<Props> = ({ holidayId, onClose, userId }) => {
  const dispatch = useAppDispatch();
  const holiday = useAppSelector((state) =>
    selectHolidayById(state, holidayId)
  );

  const methods = useForm<EditUserHolidayAdmin>({
    defaultValues: {
      id: holidayId,
      dateTo: holiday?.dateTo ? new Date(holiday.dateTo) : undefined,
      dateFrom: holiday?.dateFrom ? new Date(holiday.dateFrom) : undefined,
      type: holiday?.type,
      isHalfDay: holiday?.isHalfDay,
      addInfo: holiday?.addInfo,
    },
  });
  const dirtyFields = methods.formState.dirtyFields;

  useEffect(() => {
    const subscription = methods.watch((value, { type, name }) => {
      if (
        (name === 'dateFrom' &&
          type === 'change' &&
          value.dateFrom != null &&
          value.dateTo != null &&
          value.dateFrom > value.dateTo) ||
        (name === 'isHalfDay' &&
          type === 'change' &&
          value.dateFrom != null &&
          value.dateTo != null &&
          value.isHalfDay === true)
      )
        methods.setValue('dateTo', value.dateFrom);
    });
    return () => subscription.unsubscribe();
  }, [methods]);

  const handleSubmit = async (data: EditUserHolidayAdmin) => {
    if (!holidayId) return;

    dirtyFields.dateFrom = true;
    dirtyFields.dateTo = true;
    dirtyFields.isHalfDay = true;

    const changeset = getDirtyValues(dirtyFields, data);

    try {
      await dispatch(
        editHoliday({
          id: holidayId,
          user: userId,
          ...changeset,
        })
      ).unwrap();
      onClose();
      void dispatch(fetchUserHolidayPools(userId));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal isOpen onClose={onClose} size="xl" autoFocus={false}>
      <ModalOverlay backdropFilter="blur(30px)" />
      <ModalContent px={['12', '24']} py={['8', '16']} justifyContent="center">
        <ModalHeader textAlign="center">
          <Heading as="h1" m={4} pb={[0, 10]} size="lg" fontWeight={300}>
            EDYTUJ WNIOSEK
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormWrapper methods={methods} onSubmit={handleSubmit}>
            <Center>
              <HolidayForm isEditing />
            </Center>
          </FormWrapper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
