import { useMemo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { FC } from 'react';
import {
  DaysUsedByMonthSplit,
  HolidayTypeCountsByMonth,
} from '../../redux/statistics';
import { colors } from '../../theme';
import {
  getDatasetsForDaysUsedChart,
  getDatasetsForHolidayTypesChart,
} from './getDatasetsForChart';
import { chartTextStyles, chartTitleStyles } from './utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  horizontalAxisLabels: string[];
  daysUsedDataForChart?: DaysUsedByMonthSplit;
  holidayTypesDataForChart?: HolidayTypeCountsByMonth;
  stepSizeY?: number;
  title?: string;
  labelX?: string;
  labelY?: string;
}

export const UserStatsChart: FC<Props> = ({
  horizontalAxisLabels,
  daysUsedDataForChart,
  holidayTypesDataForChart,
  stepSizeY,
  title,
  labelX,
  labelY,
}) => {
  const datasetsForChart = useMemo(() => {
    if (holidayTypesDataForChart) {
      return getDatasetsForHolidayTypesChart(holidayTypesDataForChart);
    } else if (daysUsedDataForChart) {
      return getDatasetsForDaysUsedChart(daysUsedDataForChart);
    } else {
      return [];
    }
  }, [holidayTypesDataForChart, daysUsedDataForChart]);

  return (
    <Line
      options={{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            min: 0,
            title: { display: !!labelY, text: labelY, font: chartTextStyles },
            ticks: {
              color: colors.dark,
              font: chartTextStyles,
              stepSize: stepSizeY,
            },
            grid: {
              display: true,
            },
          },
          x: {
            title: { display: !!labelX, text: labelX, font: chartTextStyles },
            ticks: {
              color: colors.dark,
              font: chartTextStyles,
            },
            grid: {
              display: true,
            },
          },
        },
        plugins: {
          title: {
            display: !!title,
            text: title,
            font: chartTitleStyles,
          },
          legend: {
            labels: {
              usePointStyle: true,
              color: colors.dark,
              font: chartTextStyles,
              boxPadding: 30,
              padding: 30,
            },
            position: 'right',
          },
          tooltip: {
            titleFont: chartTextStyles,
            bodyFont: chartTextStyles,
            boxPadding: 4,
            callbacks: {
              label: (context) =>
                context.dataset.label
                  ? `${context.dataset.label.trim()}: ${context.parsed.y}`
                  : '',
            },
          },
        },
      }}
      data={{ labels: horizontalAxisLabels, datasets: datasetsForChart }}
    />
  );
};
