import { createStandaloneToast } from '@chakra-ui/react';
import { theme } from '../theme';

export const { ToastContainer, toast } = createStandaloneToast({ theme });

interface ToastProps {
  title: string;
  description?: string;
}

export const displaySuccessToast = ({ title, description }: ToastProps) =>
  toast({
    title: title,
    description: description,
    status: 'success',
    duration: 5000,
    isClosable: true,
  });

export const displayErrorToast = ({ title, description }: ToastProps) =>
  toast({
    title: title,
    description: description,
    status: 'error',
    duration: 5000,
    isClosable: true,
  });
