import { createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../api/axiosConfig';
import { formatToBackendDate } from '../../utils/date';
import {
  AcceptUserHolidayAdmin,
  AddUserHolidayAdmin,
  DeleteUserHolidayAdmin,
  EditUserHolidayAdmin,
} from '../../types/forms';
import { ThunkError } from '../../types/thunk';
import { handleError } from '../../utils/handleError';
import { HolidayModel, HolidayWithUserModel } from '../holidays';
import { displaySuccessToast } from '../../utils/toasts';

export const fetchUserHolidays = createAsyncThunk<
  HolidayModel[] | undefined,
  number
>('user/fetch/current-holidays', async (id, { rejectWithValue }) => {
  try {
    const response = await client.get<{ results: HolidayModel[] }>(
      `people/holiday/?user=${id}`
    );

    return response.data.results;
  } catch (error) {
    handleError(error as ThunkError);
    return rejectWithValue((error as ThunkError)?.response?.data);
  }
});

export const addHolidayAsAdmin = createAsyncThunk<
  HolidayModel | undefined,
  AddUserHolidayAdmin
>(
  'user/add/holidays',
  async (
    { user, dateFrom, dateTo, type, addInfo, isHalfDay },
    { rejectWithValue }
  ) => {
    try {
      if (!dateFrom || !dateTo) return;
      const response = await client.post<{ results: HolidayModel }>(
        'people/holiday/create-admin-holiday/',
        {
          user,
          dateFrom: formatToBackendDate(dateFrom),
          dateTo: formatToBackendDate(dateTo),
          type,
          isHalfDay,
          addInfo: addInfo === '' ? null : addInfo,
        }
      );
      if (response.data.results)
        displaySuccessToast({ title: 'Dodano pomyślnie' });
      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

export const editHoliday = createAsyncThunk<
  HolidayModel | undefined,
  EditUserHolidayAdmin
>(
  'user/edit/holidays',
  async (
    { id, dateTo, dateFrom, type, addInfo, user, isHalfDay },
    { rejectWithValue }
  ) => {
    try {
      const formattedDateFrom = dateFrom
        ? formatToBackendDate(dateFrom)
        : undefined;
      const formattedDateTo = dateTo ? formatToBackendDate(dateTo) : undefined;
      const response = await client.patch<{
        results: HolidayModel;
      }>(`people/holiday/${id}/`, {
        dateTo: formattedDateTo,
        dateFrom: formattedDateFrom,
        type,
        isHalfDay,
        addInfo: addInfo === '' ? null : addInfo,
        user,
      });
      if (response.data.results)
        displaySuccessToast({ title: 'Edytowano pomyślnie' });

      return { ...response.data.results, id };
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

export const cancelHolidayAsAdmin = createAsyncThunk<
  HolidayModel | undefined,
  DeleteUserHolidayAdmin
>('user/delete/holidays-admin', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await client.patch<{ results: HolidayModel }>(
      `people/holiday/${id}/cancel-admin-holiday/`,
      {}
    );
    if (response.data.results)
      displaySuccessToast({ title: 'Usunięto pomyślnie' });

    return { ...response.data.results, id };
  } catch (error) {
    handleError(error as ThunkError);
    return rejectWithValue((error as ThunkError)?.response?.data);
  }
});

export const cancelHolidayFromCalendarAsAdmin = createAsyncThunk<
  HolidayWithUserModel | undefined,
  DeleteUserHolidayAdmin
>(
  'user/delete/holidays-admin-calendar',
  async ({ id, cancellationInfo }, { rejectWithValue }) => {
    try {
      const response = await client.patch<{ results: HolidayWithUserModel }>(
        `people/holiday/${id}/cancel-admin-holiday/`,
        { cancellationInfo: cancellationInfo }
      );
      if (response.data.results)
        displaySuccessToast({ title: 'Usunięto pomyślnie' });

      return { ...response.data.results, id };
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

export const acceptHolidayAsAdmin = createAsyncThunk<
  HolidayModel | undefined,
  AcceptUserHolidayAdmin
>('user/accept/holidays-admin', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await client.patch<{ results: HolidayModel }>(
      `people/holiday/${id}/`,
      { isAcceptedByAdmin: true }
    );
    if (response.data.results)
      displaySuccessToast({ title: 'Zaakceptowano pomyślnie' });

    return { ...response.data.results, id };
  } catch (error) {
    handleError(error as ThunkError);
    return rejectWithValue((error as ThunkError)?.response?.data);
  }
});

export const acceptHolidayFromCalendarAsAdmin = createAsyncThunk<
  HolidayWithUserModel | undefined,
  AcceptUserHolidayAdmin
>(
  'user/accept/holidays-admin-calendar',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await client.patch<{ results: HolidayWithUserModel }>(
        `people/holiday/${id}/`,
        { isAcceptedByAdmin: true }
      );
      if (response.data.results)
        displaySuccessToast({ title: 'Zaakceptowano pomyślnie' });

      return { ...response.data.results, id };
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);
