import { Box, Center } from '@chakra-ui/react';
import { BsCalendar, BsCalendarCheck, BsCalendarX } from 'react-icons/bs';
import { FiInfo } from 'react-icons/fi';
import { MdBeachAccess, MdLabel, MdGrade } from 'react-icons/md';
import { AiFillInfoCircle } from 'react-icons/ai';
import { ReactComponent as HalfDayIcon } from '../../images/assets/halfDayIcon.svg';
import { ReactComponent as SortDescendingIconSvg } from '../../images/assets/sortDescending.svg';
import { ReactComponent as SortAscendingIconSvg } from '../../images/assets/sortAscending.svg';
export const HolidayIcon = () => (
  <Box>
    <MdBeachAccess size={30} />
  </Box>
);
export const HolidayInfoIcon = () => (
  <Box>
    <AiFillInfoCircle size={30} />
  </Box>
);
export const DateIcon = () => (
  <Box>
    <BsCalendar size={28} />
  </Box>
);
export const DateIconCheck = () => (
  <Box>
    <BsCalendarCheck size={28} className="calendarcheck" />
  </Box>
);
export const InfoIcon = () => (
  <Box>
    <FiInfo size={22} />
  </Box>
);
export const DateIconX = () => (
  <Box>
    <BsCalendarX size={28} className="calendarcancelled" />
  </Box>
);
export const TypeIcon = () => (
  <Box>
    <MdLabel size={30} />
  </Box>
);
export const StarIcon = () => (
  <Box>
    <MdGrade size={30} />
  </Box>
);
export const TypeHalfDayIcon = () => (
  <Center minWidth={30} padding="auto">
    <HalfDayIcon className="halfDayIcon" />
  </Center>
);

export const SortAscendingIcon = () => (
  <SortDescendingIconSvg
    className="sortDescendingIcon"
    transform="scale(1, -1)"
  />
);

export const SortDescendingIcon = () => (
  <SortAscendingIconSvg
    className="sortAscendingIcon"
    transform="scale(1, -1)"
  />
);
