import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { formatDate } from '../../../utils/date';
import { FormWrapper } from '../../form/FormWrapper';
import { TextInput } from '../../form/inputs/TextInput';
import { DeleteUserHolidayForm } from '../../../redux/holidays';
import { selectCurrentUser } from '../../../redux/user/selectors';
import { useAppDispatch, useAppSelector } from '../../../store';
import { cancelHolidayAsEmployee } from '../../../redux/holidays/thunks';
import { cancelHolidayFromCalendarAsAdmin } from '../../../redux/holidaysAdmin/thunks';
import { selectIsModalActionLoading } from '../../../redux/app/selectors';
import { requiredValidation } from '../../../utils/validation';
import { getDirtyValues } from '../../../utils/hookForm';
import { fetchSpecificYearUsersHolidayPool } from '../../../redux/holidayPools/thunks';

interface Props {
  onClose: () => void;
  dateFrom: Date | null;
  dateTo: Date | null;
  id: number;
  isForAdmin: boolean;
  currentYear: number;
}

export const DeleteHolidayApplication: FC<Props> = ({
  onClose,
  dateFrom,
  dateTo,
  id,
  isForAdmin,
  currentYear,
}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);
  const isLoading = useAppSelector(selectIsModalActionLoading);

  const methods = useForm<DeleteUserHolidayForm>();
  const dirtyFields = methods.formState.dirtyFields;

  const submitHandler = async (data: DeleteUserHolidayForm) => {
    if (!id) return;
    const changeset = {
      id,
      ...getDirtyValues(dirtyFields, data),
    };
    try {
      if (isForAdmin) {
        await dispatch(cancelHolidayFromCalendarAsAdmin(changeset)).unwrap();
      } else {
        await dispatch(cancelHolidayAsEmployee(changeset)).unwrap();
      }

      void dispatch(
        fetchSpecificYearUsersHolidayPool({
          year: currentYear,
          orderingField: isForAdmin
            ? 'user__last_name,user__first_name'
            : 'user__first_name,user__last_name',
        })
      );

      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal isOpen={true} onClose={onClose} size="xl" autoFocus={false}>
      <ModalOverlay backdropFilter="blur(30px)" />
      <ModalContent>
        <ModalHeader>
          <HStack justifyContent="space-between">
            <Text fontWeight="light" fontSize="size7" variant="secondary">
              {user?.firstName} {user?.lastName}
            </Text>
            <Text
              fontWeight="light"
              fontSize="size7"
              align="end"
              variant="secondary"
            >
              {dateFrom ? formatDate(dateFrom) : 'nieokreślony'} -{' '}
              {dateTo ? formatDate(dateTo) : 'nieokreślony'}
            </Text>
          </HStack>
        </ModalHeader>
        <Text align="center" pr={38} pl={38} fontSize="size11" pt={20} pb={10}>
          CZY NA PEWNO CHCESZ ANULOWAĆ TEN WNIOSEK?
        </Text>
        <ModalBody pl="135px" pr="135px">
          <FormWrapper onSubmit={submitHandler} methods={methods}>
            <TextInput
              name="cancellationInfo"
              label="uzasadnienie"
              registerProps={requiredValidation}
            />
            <VStack pt={10} pb={10}>
              <Button variant="main" size="md" height="44px" type="submit">
                WYŚLIJ
              </Button>
              <Button
                variant="inverted"
                size="md"
                height="44px"
                onClick={onClose}
                isDisabled={isLoading}
              >
                Cofnij
              </Button>
            </VStack>
          </FormWrapper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
