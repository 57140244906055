import { RootState } from '../../store';

export const selectIsLoadingStatsForTable = (state: RootState) =>
  state.statistics.isLoadingStatsForTable;

export const selectIsLoadingDaysUsedStatsForChart = (state: RootState) =>
  state.statistics.isLoadingDaysUsedStatsForChart;

export const selectIsLoadingHolidayTypesStatsForChart = (state: RootState) =>
  state.statistics.isLoadingHolidayTypesStatsForChart;

export const selectUserStatsTable = (state: RootState) =>
  state.statistics.userStatsTable;

export const selectUserDaysUsedStatsChart = (state: RootState) =>
  state.statistics.userDaysUsedStatsChart;

export const selectUserHolidayTypesStatsChart = (state: RootState) =>
  state.statistics.userHolidayTypesStatsChart;
