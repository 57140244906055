import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CenteredSpinner } from 'components/common/CenteredSpinner';
import {
  sendHolidayActionRequest,
  useNavigateAfterHolidayAcion,
} from 'utils/holidayActionRequests';
import { calendarAdminPath, cancelHolidayAdminUrl } from 'utils/paths';

export const CancelHolidayAdmin: FC = () => {
  const { holidayAlterToken } = useParams<{ holidayAlterToken: string }>();
  const navigateAfterHolidayAction = useNavigateAfterHolidayAcion();

  useEffect(() => {
    if (!holidayAlterToken) return;

    let isCancelled = false;
    void sendHolidayActionRequest(
      cancelHolidayAdminUrl,
      holidayAlterToken
    ).then((data) => {
      if (isCancelled) return;

      navigateAfterHolidayAction(
        data,
        'Wniosek urlopowy został odrzucony',
        calendarAdminPath
      );

      return;
    });

    return () => {
      isCancelled = true;
    };
  }, [holidayAlterToken, navigateAfterHolidayAction]);

  return <CenteredSpinner />;
};
