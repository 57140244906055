import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import {
  Controller,
  ControllerFieldState,
  FieldValues,
  Noop,
  RefCallBack,
  useFormContext,
  UseFormStateReturn,
} from 'react-hook-form';
import { FC, SyntheticEvent } from 'react';
import { DefaultInputProps, InputWrapper } from '../InputWrapper';
import { defaultValidation } from '../../../utils/validation';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, Input } from '@chakra-ui/react';
import plLocale from 'date-fns/locale/pl';
import { dateFormats } from '../../../utils/date';

type Props = DefaultInputProps & Omit<ReactDatePickerProps, 'onChange'>;

interface RenderProps {
  field: {
    onChange: (date: Date | null, event: SyntheticEvent | undefined) => void;
    onBlur: Noop;
    value?: Date | null;
    name: string;
    ref: RefCallBack;
  };
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
}

export const DatePicker: FC<Props> = ({
  registerProps = defaultValidation,
  name,
  label,
  isStandalone,
  helperTxt,
  ...rest
}) => {
  const { control } = useFormContext();
  return (
    <InputWrapper
      name={name}
      label={label}
      helperTxt={helperTxt}
      isStandalone={isStandalone}
    >
      <Controller
        rules={registerProps}
        control={control}
        name={name}
        render={({ field: { onChange, value, onBlur } }: RenderProps) => (
          <Box data-value={value}>
            <ReactDatePicker
              onChange={onChange}
              autoComplete="off"
              locale={plLocale}
              onBlur={onBlur}
              selected={value}
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={8}
              customInput={<Input />}
              dateFormat={dateFormats.main}
              {...rest}
            />
          </Box>
        )}
      />
    </InputWrapper>
  );
};
