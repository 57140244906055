import { createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../api/axiosConfig';
import { UserModel } from '.';
import { ThunkError } from '../../types/thunk';
import { ErrorCode, handleError } from '../../utils/handleError';

export const fetchUser = createAsyncThunk<UserModel | undefined>(
  'user/fetch',
  async () => {
    try {
      const response = await client.get<{ results: UserModel }>('users/user/');

      return response.data.results;
    } catch (error) {
      if (
        (error as ThunkError)?.response?.data?.code !==
        ErrorCode.NotAuthenticated
      )
        handleError(error as ThunkError);
      else console.error(error);
    }
  }
);
