// request & page paths
export const acceptHolidayAdminUrl = 'accept-holiday-admin';
export const acceptHolidayEmployeeUrl = 'accept-holiday-employee';
export const cancelHolidayAdminUrl = 'cancel-holiday-admin';

export const oldIntranetUrl = process.env.REACT_APP_INTRANET_URL || '';

// only page paths
export const calendarAdminPath = '/company-admin/calendar';
export const calendarUserPath = '/user/calendar';
export const holidayFailedPath = '/display-holiday-action-failed';
export const holidaySuccessfulPath = '/display-holiday-action-successful';
export const statisticsPath = '/company-admin/statistics';
export const userListPath = '/company-admin/users-list';
