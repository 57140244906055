import { Th } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  columnWidth?: string;
}

export const UsersTh: FC<Props> = ({ columnWidth, children }) => (
  <Th
    fontSize="size10"
    p={4}
    textAlign="left"
    fontWeight={300}
    minWidth={columnWidth}
    maxWidth={columnWidth}
    borderBottomColor="active"
    borderBottomWidth="0.09375rem"
  >
    {children}
  </Th>
);
