import { FC } from 'react';
import { HStack } from '@chakra-ui/react';
import { MdFace, MdPieChart } from 'react-icons/md';
import { BiCalendar } from 'react-icons/bi';
import { HeaderButton } from './HeaderButton';
import { useLocation } from 'react-router-dom';
import {
  calendarAdminPath,
  statisticsPath,
  userListPath,
} from '../../../utils/paths';

const REDIRECT_LIST = [
  {
    text: 'KALENDARZ',
    icon: BiCalendar,
    pathName: calendarAdminPath,
  },
  {
    text: 'LISTA OSÓB',
    icon: MdFace,
    pathName: userListPath,
  },
  {
    text: 'STATYSTYKI',
    icon: MdPieChart,
    pathName: statisticsPath,
  },
] as const;

export const NavigationButtons: FC = () => {
  const { pathname } = useLocation();

  return (
    <HStack
      w="8xl"
      maxW="100%"
      p={8}
      justifyContent="space-around"
      gap={[8, null, null, 0]}
      flexDirection="row"
    >
      {REDIRECT_LIST.map((props) => (
        <HeaderButton
          isActive={pathname.includes(props.pathName)}
          key={props.pathName}
          {...props}
        />
      ))}
    </HStack>
  );
};
