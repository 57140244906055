import { EventInput } from '@fullcalendar/react';
import { UserWithHolidaysModel, HolidayType } from '../../../redux/holidays';
import { HolidayDataMap } from '../../../types/holidayAdmin';

export const currentEventsMapper = (
  currentEvent: EventInput[],
  { firstName, lastName, userId }: UserWithHolidaysModel
): HolidayDataMap[] =>
  currentEvent.map(
    ({ extendedProps, start, end }): HolidayDataMap => ({
      firstName,
      lastName,
      userId,
      addInfo: extendedProps?.additionalInfo as string,
      type: extendedProps?.type as HolidayType,
      dateFrom: start as string,
      dateTo: end as string,
      isAcceptedByAdmin: false,
      isAcceptedByEmployee: false,
    })
  );
