import { FC } from 'react';
import {
  Button,
  ModalBody,
  ModalContent,
  ModalHeader,
  Heading,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { UseFormReturn } from 'react-hook-form';
import { requiredValidation } from '../../../utils/validation';
import { FormSelect } from '../../form/inputs/Select';
import { getYear10YearsAgo } from '../../../utils/date';
import { options } from '../../../types/contractTypes';
import { FormWrapper } from '../../form/FormWrapper';
import { DatePicker } from '../../form/inputs/DatePicker';
import { DataForReportInterface } from '../../../redux/holidays';
import { SelectOption } from '../../../types/SelectOptions';

interface Props {
  submitHandler: (
    data: DataForReportInterface,
    usersFromMultiSelect?: readonly SelectOption[]
  ) => void;
  dateFromWatcher: Date;
  methods: UseFormReturn<DataForReportInterface>;
  setSelectedReportType: (selectedReportType: string | undefined) => void;
  isLoading: boolean;
}

export const ReportByContractType: FC<Props> = ({
  submitHandler,
  dateFromWatcher,
  methods,
  setSelectedReportType,
  isLoading,
}) => {
  return (
    <ModalContent px="8.4375rem">
      <ModalHeader textAlign="center" px="0" pt="6.25rem">
        <Heading as="h1" m={4} size="lg" fontWeight={300}>
          EKSPORTUJ WG RODZAJU UMOWY
        </Heading>
      </ModalHeader>
      <ModalBody px="0" pb="6.875rem">
        <FormWrapper
          onSubmit={(data: DataForReportInterface) => submitHandler(data)}
          methods={methods}
        >
          <FormSelect
            name="contractsType"
            label="rodzaj umowy"
            options={options}
            registerProps={requiredValidation}
          ></FormSelect>
          <HStack w="100%" alignItems="center" paddingBottom={8}>
            <DatePicker
              label="data od"
              name="dateFrom"
              minDate={getYear10YearsAgo()}
              registerProps={requiredValidation}
              isStandalone
            />
            <DatePicker
              label="data do"
              name="dateTo"
              minDate={dateFromWatcher}
              isStandalone
            />
          </HStack>
          <FormSelect
            name="reportScope"
            label="zakres eksportu"
            options={[
              { label: 'wszystkie urlopy', value: 'all_holidays' },
              { label: 'wykorzystane urlopy', value: 'used_holidays' },
            ]}
            registerProps={requiredValidation}
          ></FormSelect>
          <VStack pt={10} pb={10}>
            <Button
              variant="main"
              size="md"
              height="44px"
              type="submit"
              isDisabled={isLoading}
            >
              EKSPORTUJ
            </Button>
            <Button
              variant="inverted"
              size="md"
              height="44px"
              onClick={() => setSelectedReportType(undefined)}
            >
              Cofnij
            </Button>
          </VStack>
        </FormWrapper>
      </ModalBody>
    </ModalContent>
  );
};
