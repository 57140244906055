import { Tooltip } from '@chakra-ui/react';
import { FiInfo } from 'react-icons/fi';
import { FC } from 'react';
import { EventContentInterface } from '../../../types/calendarEventContent';
import { PublicHolidayType } from '../../../redux/publicHolidays';
import { colors } from '../../../theme';

interface Props {
  eventInfo: EventContentInterface;
}

export const TooltipForPublicHolidays: FC<Props> = ({ eventInfo }) => (
  <Tooltip
    key={eventInfo.event.id}
    label={eventInfo.event.extendedProps.additionalInfo}
    placement="bottom-start"
    fontSize="size6"
  >
    <span>
      <FiInfo
        size={24}
        style={{ margin: '0.3em', position: 'relative' }}
        opacity={1}
        color={
          eventInfo.event.extendedProps.type === PublicHolidayType.Company
            ? colors.companyHolidayIconColor
            : colors.nationalHolidayIconColor
        }
      />
    </span>
  </Tooltip>
);
