import {
  Flex,
  Grid,
  IconButton,
  ListIcon,
  Spacer,
  theme,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { MdModeEdit } from 'react-icons/md';
import { FaTrashAlt } from 'react-icons/fa';
import { FC, useEffect, useState } from 'react';
import {
  selectIsContractLoading,
  selectUserContracts,
} from '../../../../../redux/contracts/selectors';
import { fetchUserContracts } from '../../../../../redux/contracts/thunks';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { formatDate } from '../../../../../utils/date';
import {
  DateIcon,
  HolidayIcon,
  TypeIcon,
} from '../../../../../components/common/Icons';
import { DeleteContract } from './DeleteContract';
import { UserProfileListElement } from '../../../../../components/UserProfileList/UserProfileListElement';
import { UserProfileList } from '../../../../../components/UserProfileList';
import { EditContract } from './EditContract';
import { CenteredSpinner } from '../../../../../components/common/CenteredSpinner';
import { contractOptions } from './utils';

interface Props {
  userId: number | undefined;
}

export const DisplayContract: FC<Props> = ({ userId }) => {
  const dispatch = useAppDispatch();
  const contractList = useAppSelector(selectUserContracts);
  const isLoading = useAppSelector(selectIsContractLoading);
  const { onClose } = useDisclosure();
  const editModalDisclosure = useDisclosure();
  const [contractId, setContractId] = useState<number>();
  const [contractIdDelete, setContractIdDelete] = useState<number>();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);

  useEffect(() => {
    if (userId) void dispatch(fetchUserContracts(userId));
  }, [dispatch, userId]);

  const handleClickDelete = (id: number) => {
    setContractIdDelete(id);
  };

  const handleClickEdit = (id: number) => {
    setContractId(id);
  };

  if (contractList.length < 1 && isMobile) return null;

  return isLoading ? (
    <CenteredSpinner />
  ) : (
    <UserProfileList>
      {contractId != null && (
        <EditContract
          onClose={() => {
            editModalDisclosure.onClose();
            setContractId(undefined);
          }}
          contractId={contractId}
        />
      )}
      {contractIdDelete != null && (
        <DeleteContract
          contractId={contractIdDelete}
          onClose={() => {
            onClose();
            setContractIdDelete(undefined);
          }}
        />
      )}
      {contractList?.map(({ id, dateTo, dateFrom, type, holidayPool }) => (
        <UserProfileListElement key={id} id={id}>
          <Grid
            w="100%"
            templateColumns={[
              '3fr 2.2fr 0.7fr',
              null,
              null,
              null,
              null,
              '3fr 2fr 1fr',
            ]}
            fontSize="size4"
          >
            <Flex alignItems="center" pr={5} gap="8%">
              <ListIcon as={DateIcon} id={`${id}`} />
              {dateFrom
                ? formatDate(new Date(dateFrom))
                : 'nieokreślony'} -{' '}
              {dateTo ? formatDate(new Date(dateTo)) : 'nieokreślony'}
            </Flex>
            <Flex alignItems="center" pr={3} gap="8%">
              <ListIcon as={TypeIcon} />
              {contractOptions[type]}
            </Flex>
            <Flex alignItems="center" gap="8%">
              <ListIcon as={HolidayIcon} />
              {holidayPool}
            </Flex>
          </Grid>
          <Spacer />
          <IconButton
            icon={<FaTrashAlt />}
            aria-label="Delete"
            mr={1}
            ml={9}
            size="xs"
            variant="iconButton"
            onClick={() => handleClickDelete(id)}
          />
          <IconButton
            icon={<MdModeEdit />}
            variant="iconButton"
            aria-label={'Edit'}
            onClick={() => handleClickEdit(id)}
            size="xs"
          />
        </UserProfileListElement>
      ))}
    </UserProfileList>
  );
};
