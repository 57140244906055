import { Center, Spinner, theme as baseTheme } from '@chakra-ui/react';
import { useAppSelector } from '../../../store';
import { selectIsModalActionLoading } from '../../../redux/app/selectors';

export const AppSpinner = () => {
  const isLoading = useAppSelector(selectIsModalActionLoading);

  return isLoading ? (
    <Center
      position="fixed"
      w="100%"
      h="100%"
      bg="light"
      opacity={0.66}
      zIndex={baseTheme.zIndices.modal + 1}
    >
      <Spinner size="xl" />
    </Center>
  ) : null;
};
