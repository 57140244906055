import { Tr } from '@chakra-ui/react';
import { FC } from 'react';
import { StatsTableTh } from './statsTableTh';

interface Props {
  columnSpacing: (string | null)[] | string;
  tableBackgroundColors: string[];
  labels: string[];
  innerPaddingTop?: (string | null)[] | string;
  innerPaddingBottom?: (string | null)[] | string;
}

export const MonthLabelsTr: FC<Props> = ({
  columnSpacing,
  tableBackgroundColors,
  labels,
  innerPaddingTop,
  innerPaddingBottom,
}) => (
  <Tr>
    <StatsTableTh isForNames={true} />
    {labels.map((monthName, index) => (
      <StatsTableTh
        key={index}
        colSpan={2}
        innerPaddingLeft={columnSpacing}
        innerPaddingRight={columnSpacing}
        innerPaddingTop={innerPaddingTop}
        innerPaddingBottom={innerPaddingBottom}
        fontSize="size8"
        innerBackgroundColor={tableBackgroundColors[index]}
      >
        {monthName}
      </StatsTableTh>
    ))}
  </Tr>
);
