import {
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberInputStepper,
  UseCounterProps,
} from '@chakra-ui/react';
import { FC } from 'react';
import {
  Controller,
  ControllerFieldState,
  FieldValues,
  Noop,
  RefCallBack,
  useFormContext,
  UseFormStateReturn,
} from 'react-hook-form';
import { DefaultInputProps, InputWrapper } from '../InputWrapper';
import { defaultValidation } from '../../../utils/validation';

interface Props extends DefaultInputProps {
  min?: number;
  precision?: number;
}

interface RenderProps {
  field: {
    onChange: UseCounterProps['onChange'];
    onBlur: Noop;
    value?: number;
    name: string;
    ref: RefCallBack;
  };
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
}

export const NumberInput: FC<Props> = ({
  name,
  min,
  precision = 0,
  registerProps = defaultValidation,
  ...rest
}) => {
  const { control } = useFormContext();
  // setting 'pattern' for NumberInputField is a workaround,
  // necessary because by default this element doesn't validate signed numbers with decimal point properly
  return (
    <InputWrapper name={name} {...rest}>
      <Controller
        control={control}
        rules={registerProps}
        name={name}
        render={({ field: { onChange, value, onBlur } }: RenderProps) => (
          <ChakraNumberInput
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            min={min}
            precision={precision}
            data-value={value != null ? value : undefined}
          >
            <NumberInputField pattern="(-)?[0-9]*(.[0-9]+)?" />
            <NumberInputStepper />
          </ChakraNumberInput>
        )}
      />
    </InputWrapper>
  );
};
