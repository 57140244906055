import { Text, Td } from '@chakra-ui/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { userListPath } from '../../../utils/paths';

interface Props {
  id: number;
  firstLastName: string;
  hasTitleProp?: boolean;
  text?: string;
  columnWidth?: string;
}

export const UsersTd: FC<Props> = ({
  id,
  hasTitleProp,
  text,
  columnWidth,
  firstLastName,
}) => (
  // "text-overflow: ellipsis" (set by "noOfLines" property) requires maxWidth to be set
  <Td
    border={0}
    p={0}
    minWidth={columnWidth}
    maxWidth={columnWidth}
    height="inherit"
    backgroundColor="transparent"
  >
    <Text
      noOfLines={1}
      py={1}
      px={4}
      display="block"
      width="100%"
      as={Link}
      to={`${userListPath}/${id}/${firstLastName}/`}
      title={hasTitleProp ? text : undefined}
    >
      {text?.trim() || '-'}
    </Text>
  </Td>
);
