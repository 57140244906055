import { IconButton } from '@chakra-ui/react';
import { FC, SetStateAction } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import { fetchUserHolidayPools } from '../../../../../redux/holidayPools/thunks';
import { acceptHolidayAsAdmin } from '../../../../../redux/holidaysAdmin/thunks';
import { useAppDispatch } from '../../../../../store';

interface Props {
  isAcceptedByAdmin: boolean;
  isAcceptedByEmployee: boolean;
  isCancelled: boolean;
  id: number;
  userId: number;
  handleEdit: (id: number) => void;
  handleDelete: (id: number) => void;
  setHolidayId: (id: SetStateAction<number | undefined>) => void;
}
export const HolidayAdminOptions: FC<Props> = ({
  isAcceptedByAdmin,
  isAcceptedByEmployee,
  isCancelled,
  id,
  userId,
  handleEdit,
  handleDelete,
  setHolidayId,
}) => {
  const dispatch = useAppDispatch();

  const handleAccept = async (id: number) => {
    setHolidayId(id);
    try {
      await dispatch(acceptHolidayAsAdmin({ id })).unwrap();
      await dispatch(fetchUserHolidayPools(userId)).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  if (isCancelled) {
    return (
      <IconButton
        icon={<MdModeEdit />}
        variant="iconButton"
        aria-label={'Edit'}
        mr="1"
        ml="44px"
        size="xs"
        onClick={() => handleEdit(id)}
      />
    );
  } else if (isAcceptedByEmployee && !isAcceptedByAdmin) {
    return (
      <>
        <IconButton
          icon={<FaTimes />}
          aria-label={'Delete'}
          mr="1"
          ml="5"
          size="xs"
          variant="iconButton"
          onClick={() => handleDelete(id)}
        />
        <IconButton
          icon={<FaCheck />}
          aria-label={'Accept'}
          size="xs"
          variant="iconButton"
          onClick={() => handleAccept(id)}
        />
      </>
    );
  } else if (
    (isAcceptedByAdmin && isAcceptedByEmployee) ||
    !isAcceptedByEmployee
  ) {
    return (
      <>
        <IconButton
          icon={<FaTimes />}
          aria-label={'Delete'}
          mr="1"
          ml="5"
          size="xs"
          variant="iconButton"
          onClick={() => handleDelete(id)}
        />
        <IconButton
          icon={<MdModeEdit />}
          aria-label={'Edit'}
          size="xs"
          variant="iconButton"
          onClick={() => handleEdit(id)}
        />
      </>
    );
  } else {
    return null;
  }
};
