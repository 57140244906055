import { createSlice } from '@reduxjs/toolkit';
import { AddUserContractForm } from '../../types/forms';

import {
  fetchUserContracts,
  editUserContract,
  addUserContract,
  deleteUserContract,
} from './thunks';

export enum ContractType {
  B2B = 'B2B',
  UoP = 'UoP',
  UoD = 'UoD',
  UoZ = 'UoZ',
}

export interface UserContracts {
  id: number;
  dateFrom: string;
  dateTo: string;
  type: ContractType;
  holidayPool: string;
}
export interface ContractState {
  isLoadingAdd: boolean;
  isLoading: boolean;
  userContracts: UserContracts[];
  adduserContract: AddUserContractForm[];
  userContract?: UserContracts;
}

const initialState: ContractState = {
  isLoadingAdd: false,
  isLoading: false,
  userContracts: [],
  adduserContract: [],
};
const contractSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchUserContracts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserContracts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userContracts = action?.payload ?? [];
      })
      .addCase(fetchUserContracts.rejected, (state) => {
        state.isLoading = false;
        state.userContracts = [];
      })

      .addCase(editUserContract.fulfilled, (state, { payload }) => {
        state.userContracts = state.userContracts?.map((userContract) =>
          userContract.id === payload?.id
            ? { ...payload, ...state.userContract }
            : userContract
        );
      })
      .addCase(editUserContract.rejected, (state) => {
        state.userContract = undefined;
      })

      .addCase(addUserContract.pending, (state) => {
        state.isLoadingAdd = true;
      })
      .addCase(addUserContract.fulfilled, (state, { payload }) => {
        state.isLoadingAdd = false;

        if (state.userContracts && payload) {
          state.userContracts = [...state.userContracts, payload].sort(
            (userContracts, payload) => {
              return payload.dateFrom.localeCompare(userContracts.dateFrom);
            }
          );
        }
      })
      .addCase(addUserContract.rejected, (state) => {
        state.isLoadingAdd = false;
      })
      .addCase(deleteUserContract.fulfilled, (state, { payload }) => {
        state.userContracts = state.userContracts.filter(
          (userConctract) => userConctract.id !== payload?.id
        );
      });
  },
});

export default contractSlice.reducer;
