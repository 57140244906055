import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DaysUsedByMonth,
  DaysUsedByMonthSplit,
  HolidayTypeCountsByMonth,
} from '.';
import client from '../../api/axiosConfig';
import { ThunkError } from '../../types/thunk';
import { handleError } from '../../utils/handleError';

export const fetchStatsForTable = createAsyncThunk<
  DaysUsedByMonth[] | undefined,
  number
>('statsForTable/fetch', async (year, { rejectWithValue }) => {
  try {
    const response = await client.get<{ results: DaysUsedByMonth[] }>(
      `people/get-stats-for-table/?year=${year}`
    );
    return response.data.results;
  } catch (error) {
    handleError(error as ThunkError);
    return rejectWithValue((error as ThunkError)?.response?.data);
  }
});

export const fetchUsedDaysStatsForChart = createAsyncThunk<
  DaysUsedByMonthSplit | undefined,
  { year: number; userId?: number[] }
>(
  'daysUsedstatsForChart/fetch',
  async ({ year, userId }, { rejectWithValue }) => {
    try {
      const response = await client.get<{ results: DaysUsedByMonthSplit }>(
        userId
          ? `people/get-used-days-statistics-for-chart/?year=${year}&user=${userId.toString()}`
          : `people/get-used-days-statistics-for-chart/?year=${year}`
      );
      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

export const fetchHolidayTypesStatsForChart = createAsyncThunk<
  HolidayTypeCountsByMonth | undefined,
  number
>('holidayTypeStatsForChart/fetch', async (year, { rejectWithValue }) => {
  try {
    const response = await client.get<{ results: HolidayTypeCountsByMonth }>(
      `people/get-holiday-type-statistics-for-chart/?year=${year}`
    );
    return response.data.results;
  } catch (error) {
    handleError(error as ThunkError);
    return rejectWithValue((error as ThunkError)?.response?.data);
  }
});
