import { FC } from 'react';
import { Box } from '@chakra-ui/react';

export const DrawerCloseIcon: FC = () => (
  <Box as="span" position="absolute" top="6px" right="14px">
    <Box
      as="span"
      position="absolute"
      width="3px"
      height="14px"
      transform="rotate(45deg)"
      backgroundColor="light"
    ></Box>
    <Box
      as="span"
      position="absolute"
      width="3px"
      height="14px"
      transform="rotate(-45deg)"
      backgroundColor="light"
    ></Box>
  </Box>
);
