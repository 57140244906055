import axios from 'axios';
import { getCookie } from 'typescript-cookie';

export const CSRF_TOKEN_COOKIE_NAME = 'csrftoken';
export const CSRF_TOKEN_HEADER_NAME = 'X-CSRFToken';
export const CSRF_HIDDEN_FORM_FIELD_NAME = 'csrfmiddlewaretoken'; // an input name on the forms with CSRF token

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL ?? ''}/api`,
  timeout: 30000,
  withCredentials: true,
});

// attach csrf token from the cookie to the axios requests
instance.interceptors.request.use((config) => {
  const csrftoken = getCookie(CSRF_TOKEN_COOKIE_NAME);

  if (csrftoken && config.headers) {
    config.headers[CSRF_TOKEN_HEADER_NAME] = csrftoken;
  }

  return config;
});

export default instance;
