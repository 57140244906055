import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { FormWrapper } from '../../form/FormWrapper';
import { useForm, useWatch } from 'react-hook-form';
import { TextInput } from '../../form/inputs/TextInput';
import { DatePicker } from '../../form/inputs/DatePicker';
import { useAppDispatch, useAppSelector } from '../../../store';
import { requiredValidation } from '../../../utils/validation';
import { selectCurrentUser } from '../../../redux/user/selectors';
import { formatDate, getYear10YearsAgo } from '../../../utils/date';
import {
  AddPublicHolidayModel,
  PublicHolidayType,
} from '../../../redux/publicHolidays';
import { addPublicHolidayAdmin } from '../../../redux/publicHolidays/thunks';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export const NationalHolidayApplication: FC<Props> = ({ onClose, isOpen }) => {
  const user = useAppSelector(selectCurrentUser);

  const methods = useForm<AddPublicHolidayModel>({
    defaultValues: {
      type: PublicHolidayType.NationalHoliday,
    },
  });
  const { watch, setValue, control } = methods;

  const dispatch = useAppDispatch();

  const submitHandler = async (data: AddPublicHolidayModel) => {
    try {
      await dispatch(addPublicHolidayAdmin(data)).unwrap();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const dateFromWatcher = useWatch({ name: 'dateFrom', control });

  useEffect(() => {
    const subscription = watch((value, { type, name }) => {
      if (
        name === 'dateFrom' &&
        type === 'change' &&
        value.dateFrom != null &&
        value.dateTo != null &&
        value.dateFrom > value.dateTo
      )
        setValue('dateTo', value.dateFrom);
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      autoFocus={false}
      returnFocusOnClose={false}
    >
      <ModalOverlay backdropFilter="blur(30px)" />
      <ModalContent>
        <ModalHeader>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <GridItem colSpan={2} h="10">
              <Text fontWeight="light" fontSize="size7" variant="secondary">
                {user?.firstName} {user?.lastName}
              </Text>
            </GridItem>
            <GridItem colStart={4} colEnd={6} h="10">
              <Text
                fontWeight="light"
                fontSize="size7"
                align="end"
                variant="secondary"
              >
                {formatDate(new Date())}
              </Text>
            </GridItem>
          </Grid>
        </ModalHeader>
        <Text
          align="center"
          pr={18}
          pl={18}
          fontSize="size13"
          pt={10}
          pb={10}
          fontFamily="Roboto"
        >
          DODAJ ŚWIĘTO
        </Text>
        <ModalBody pl="135px" pr="135px">
          <FormWrapper onSubmit={submitHandler} methods={methods}>
            <Grid
              templateColumns="repeat(2, 1fr)"
              gap={8}
              alignItems="baseline"
              maxHeight={12}
            >
              <GridItem>
                <DatePicker
                  label="od"
                  name="dateFrom"
                  minDate={getYear10YearsAgo()}
                  registerProps={requiredValidation}
                />
              </GridItem>
              <GridItem>
                <DatePicker
                  label="do"
                  name="dateTo"
                  minDate={dateFromWatcher}
                  registerProps={requiredValidation}
                />
              </GridItem>
            </Grid>
            <TextInput
              name="additionalInfo"
              label="nazwa święta"
              registerProps={requiredValidation}
            />
            <VStack pt={3} pb={10}>
              <Button variant="main" size="md" height="44px" type="submit">
                WYŚLIJ
              </Button>
              <Button
                variant="inverted"
                size="md"
                height="44px"
                onClick={onClose}
              >
                Cofnij
              </Button>
            </VStack>
          </FormWrapper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
