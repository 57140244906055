import { EventInput } from '@fullcalendar/react';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { correctToCalendarDate, formatDate } from '../../utils/date';
import { colors } from '../../theme';
import { SocialSecurityBenefitsWithUserModel } from '.';
import { SOCIAL_SECURITY_BENEFIT_NAMES } from '../../utils/consts';

export const selectSocialSecurityBenefits = (state: RootState) =>
  state.socialSecurityBenefits.socialSecurityBenefits;

export const selectSocialSecurityBenefitsDaysSum = (state: RootState) =>
  state.socialSecurityBenefits.daysSum;

export const selectIsLoading = (state: RootState) =>
  state.socialSecurityBenefits.isLoading;

const selectBenefitId = (state: RootState, id: number) => id;

const getSickLeaveColor = (
  { user }: SocialSecurityBenefitsWithUserModel,
  userId?: number,
  isCompanyAdmin?: boolean
) => {
  if (isCompanyAdmin) return colors.sickLeaveHoliday;

  if (user === userId) {
    return colors.sickLeaveHoliday;
  } else {
    return colors.holidayAcceptedOthers;
  }
};

export const selectSickLeaveEvents = (state: RootState): EventInput[] =>
  state.socialSecurityBenefits.socialSecurityBenefits.map((benefit) => ({
    title: `${benefit.firstName} ${benefit.lastName.substring(0, 1)}.`,
    start: benefit.dateFrom.toString(),
    end: correctToCalendarDate(benefit.dateTo),
    color: getSickLeaveColor(
      benefit,
      state.user.current?.id,
      state.user.current?.isCompanyAdmin
    ),
    display: 'auto',
    extendedProps: {
      alertInfo: benefit.type
        ? `${formatDate(benefit.dateFrom)}-${formatDate(benefit.dateTo)} - ${
            SOCIAL_SECURITY_BENEFIT_NAMES[benefit.type]
          }`
        : `${formatDate(benefit.dateFrom)}-${formatDate(benefit.dateTo)}`,
    },
  })) ?? [];

export const selectBenefit = createSelector(
  [selectSocialSecurityBenefits, selectBenefitId],
  (data, id) => {
    return data.find((benefit) => benefit.id === id);
  }
);
