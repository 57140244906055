import { FC, useState, useMemo } from 'react';
import {
  Button,
  ModalBody,
  ModalContent,
  ModalHeader,
  Heading,
  VStack,
  HStack,
  Box,
} from '@chakra-ui/react';
import { UseFormReturn } from 'react-hook-form';
import { ActionMeta, MultiValue } from 'react-select';
import { requiredValidation } from '../../../utils/validation';
import {
  MULTISELECT_NONE_SELECTED_LABEL,
  MULTISELECT_ALL_SELECTED_LABEL,
} from '../../../utils/consts';
import { getYear10YearsAgo } from '../../../utils/date';
import { FormWrapper } from '../../form/FormWrapper';
import { DatePicker } from '../../form/inputs/DatePicker';
import { FormSelect } from '../../form/inputs/Select';
import {
  DataForReportInterface,
  UserWithHolidaysModel,
} from '../../../redux/holidays';
import { SelectOption } from '../../../types/SelectOptions';
import { MultiSelect } from '../../form/inputs/MulitSelectInput';

interface Props {
  submitHandler: (
    data: DataForReportInterface,
    usersFromMultiSelect?: readonly SelectOption[]
  ) => void;
  dateFromWatcher: Date;
  methods: UseFormReturn<DataForReportInterface>;
  holidays: UserWithHolidaysModel[];
  setSelectedReportType: (selectedReportType: string | undefined) => void;
  isLoading: boolean;
}

export const ReportByEmployee: FC<Props> = ({
  submitHandler,
  dateFromWatcher,
  methods,
  holidays,
  setSelectedReportType,
  isLoading,
}) => {
  const [selected, setSelected] = useState<readonly SelectOption[]>([]);
  const [allClicked, setAllClicked] = useState(false);
  const [multiSelectLabel, setMultiSelectLabel] = useState(
    MULTISELECT_NONE_SELECTED_LABEL
  );

  const allOptions = useMemo(
    () => [
      {
        label: MULTISELECT_ALL_SELECTED_LABEL,
        value: MULTISELECT_ALL_SELECTED_LABEL,
      },
      ...holidays.map(({ firstName, lastName, id }) => ({
        label: `${firstName} ${lastName}`,
        value: id.toString(),
      })),
    ],
    [holidays]
  );

  const handleChange = (
    e: MultiValue<SelectOption>,
    value: ActionMeta<SelectOption>
  ) => {
    const { action, option } = value;
    if (option?.label !== MULTISELECT_ALL_SELECTED_LABEL) {
      const selectedOptionsWithoutAll = e.filter(
        ({ value }) => value !== MULTISELECT_ALL_SELECTED_LABEL
      );

      setSelected(e);
      setMultiSelectLabel(
        selectedOptionsWithoutAll.length > 0
          ? `wybrano: ${selectedOptionsWithoutAll.length}`
          : MULTISELECT_NONE_SELECTED_LABEL
      );
      setAllClicked(false);
    } else if (action === 'select-option') {
      setSelected(allOptions);
      setAllClicked(true);
      setMultiSelectLabel(MULTISELECT_ALL_SELECTED_LABEL.toLowerCase());
    } else if (action === 'deselect-option') {
      setSelected([]);
      setAllClicked(true);
      setMultiSelectLabel(MULTISELECT_NONE_SELECTED_LABEL);
    }
  };

  return (
    <ModalContent px="8.4375rem">
      <ModalHeader textAlign="center" px="0" pt="6.25rem">
        <Heading as="h1" m={4} size="lg" fontWeight={300}>
          EKSPORTUJ WG LISTY OSÓB
        </Heading>
      </ModalHeader>
      <ModalBody px="0" pb="6.875rem">
        <FormWrapper
          onSubmit={(data: DataForReportInterface) =>
            submitHandler(data, selected)
          }
          methods={methods}
        >
          <Box paddingBottom={4}>
            <MultiSelect
              options={allOptions}
              name="employees"
              propsValue={selected}
              label={multiSelectLabel}
              isAll={allClicked}
              isStandalone
              onChange={handleChange}
            />
          </Box>
          <HStack w="100%" alignItems="center" paddingBottom={4}>
            <DatePicker
              label="data od"
              name="dateFrom"
              minDate={getYear10YearsAgo()}
              registerProps={requiredValidation}
              isStandalone
            />
            <DatePicker
              label="data do"
              name="dateTo"
              minDate={dateFromWatcher}
              isStandalone
            />
          </HStack>
          <FormSelect
            name="reportScope"
            label="zakres eksportu"
            options={[
              { label: 'wszystkie urlopy', value: 'all_holidays' },
              { label: 'wykorzystane urlopy', value: 'used_holidays' },
            ]}
            registerProps={requiredValidation}
          ></FormSelect>
          <VStack pt={10} pb={10}>
            <Button
              variant="main"
              size="md"
              height="44px"
              type="submit"
              isDisabled={isLoading}
            >
              EKSPORTUJ
            </Button>
            <Button
              variant="inverted"
              size="md"
              height="44px"
              onClick={() => setSelectedReportType(undefined)}
            >
              Cofnij
            </Button>
          </VStack>
        </FormWrapper>
      </ModalBody>
    </ModalContent>
  );
};
