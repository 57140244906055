import {
  Flex,
  Grid,
  IconButton,
  ListIcon,
  Spacer,
  theme,
  useDisclosure,
  useMediaQuery,
  Tooltip,
} from '@chakra-ui/react';
import { MdModeEdit } from 'react-icons/md';
import { FaTrashAlt } from 'react-icons/fa';
import { FC, useEffect, useState } from 'react';
import {
  selectIsDelegationLoading,
  selectUserDelegations,
} from '../../../../../redux/delegation/selectors';
import { fetchUserDelegation } from '../../../../../redux/delegation/thunks';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { formatDate } from '../../../../../utils/date';
import { DateIcon, InfoIcon } from '../../../../../components/common/Icons';
import { DeleteDelegation } from './DeleteDelegation';
import { UserProfileListElement } from '../../../../../components/UserProfileList/UserProfileListElement';
import { UserProfileList } from '../../../../../components/UserProfileList';
import { EditDelegation } from './EditDelegation';
import { CenteredSpinner } from '../../../../../components/common/CenteredSpinner';

interface Props {
  userId: number;
}

export const DisplayDelegation: FC<Props> = ({ userId }) => {
  const dispatch = useAppDispatch();
  const delegationList = useAppSelector(selectUserDelegations);
  const isLoading = useAppSelector(selectIsDelegationLoading);
  const { onClose } = useDisclosure();
  const editModalDisclosure = useDisclosure();
  const [delegationId, setDelegationId] = useState<number>();
  const [delegationIdDelete, setDelegationIdDelete] = useState<number>();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);

  useEffect(() => {
    if (userId) void dispatch(fetchUserDelegation(userId));
  }, [dispatch, userId]);

  const handleClickDelete = (id: number) => {
    setDelegationIdDelete(id);
  };

  const handleClickEdit = (id: number) => {
    setDelegationId(id);
  };

  if (delegationList.length < 1 && isMobile) return null;

  return isLoading ? (
    <CenteredSpinner />
  ) : (
    <UserProfileList>
      {delegationId != null && (
        <EditDelegation
          onClose={() => {
            editModalDisclosure.onClose();
            setDelegationId(undefined);
          }}
          delegationId={delegationId}
          userId={userId}
        />
      )}
      {delegationIdDelete != null && (
        <DeleteDelegation
          delegationId={delegationIdDelete}
          onClose={() => {
            onClose();
            setDelegationIdDelete(undefined);
          }}
        />
      )}
      {delegationList?.map(({ id, dateTo, dateFrom, addInfo }) => (
        <UserProfileListElement key={id} id={id}>
          <Grid
            w="100%"
            templateColumns="1.5fr 1.2fr 0.3fr auto"
            fontSize="size4"
          >
            <Flex alignItems="center" pr="5" gap="8%">
              <ListIcon as={DateIcon} id={`${id}`} />
              {dateFrom
                ? formatDate(new Date(dateFrom))
                : 'nieokreślony'} -{' '}
              {dateTo ? formatDate(new Date(dateTo)) : 'nieokreślony'}
            </Flex>
            <Flex alignItems="center" gap="8%">
              {addInfo ? (
                <Tooltip
                  key={id}
                  label={addInfo}
                  placement="bottom-start"
                  fontSize="size6"
                >
                  <span>
                    <ListIcon as={InfoIcon} justifyContent="flex-end" />
                  </span>
                </Tooltip>
              ) : undefined}
            </Flex>
          </Grid>
          <Spacer />
          <IconButton
            icon={<FaTrashAlt />}
            aria-label="Delete"
            mr="1"
            ml="10"
            size="xs"
            variant="iconButton"
            onClick={() => handleClickDelete(id)}
          />
          <IconButton
            icon={<MdModeEdit />}
            variant="iconButton"
            aria-label={'Edit'}
            onClick={() => handleClickEdit(id)}
            size="xs"
          />
        </UserProfileListElement>
      ))}
    </UserProfileList>
  );
};
