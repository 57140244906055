import {
  Flex,
  Grid,
  IconButton,
  ListIcon,
  theme,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import { MdModeEdit } from 'react-icons/md';
import { FaTimes } from 'react-icons/fa';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { formatDate } from '../../../../../utils/date';
import {
  DateIcon,
  DateIconX,
  InfoIcon,
  TypeIcon,
} from '../../../../../components/common/Icons';
import { UserProfileListElement } from '../../../../../components/UserProfileList/UserProfileListElement';
import { UserProfileList } from '../../../../../components/UserProfileList';
import { CenteredSpinner } from '../../../../../components/common/CenteredSpinner';
import {
  selectIsLoading,
  selectSocialSecurityBenefits,
} from '../../../../../redux/socialSecurityBenefits/selectors';
import { fetchUserSocialSecurityBenefits } from '../../../../../redux/socialSecurityBenefits/thunks';
import { DeleteSocialSecurityBenefits } from './DeleteSocialSecurityBenefits';
import { EditSocialSecurityBenefits } from './EditSocialSecurityBenefits';
import { SOCIAL_SECURITY_BENEFIT_NAMES } from '../../../../../utils/consts';

interface Props {
  userId: number | undefined;
}

export const DisplaySocialSecurityBenefits: FC<Props> = ({ userId }) => {
  const dispatch = useAppDispatch();
  const socialSecurityBenefitsList = useAppSelector(
    selectSocialSecurityBenefits
  );
  const isLoading = useAppSelector(selectIsLoading);

  const [socialSecurityBenefitId, setSocialSecurityBenefitId] =
    useState<number>();
  const [socialSecurityBenefitIdEdit, setSocialSecurityBenefitIdEdit] =
    useState<number>();

  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);

  useEffect(() => {
    if (userId)
      void dispatch(
        fetchUserSocialSecurityBenefits({
          id: userId,
          isCancelled: undefined,
        })
      );
  }, [dispatch, userId]);

  const handleClickDelete = (id: number) => {
    setSocialSecurityBenefitId(id);
  };

  const handleEditClick = (id: number) => {
    setSocialSecurityBenefitIdEdit(id);
  };

  if (socialSecurityBenefitsList.length < 1 && isMobile) return null;

  return isLoading ? (
    <CenteredSpinner />
  ) : (
    <UserProfileList>
      {socialSecurityBenefitId != null && (
        <DeleteSocialSecurityBenefits
          id={socialSecurityBenefitId}
          onClose={() => {
            setSocialSecurityBenefitId(undefined);
          }}
        />
      )}
      {socialSecurityBenefitIdEdit != null && userId && (
        <EditSocialSecurityBenefits
          user={userId}
          id={socialSecurityBenefitIdEdit}
          onClose={() => {
            setSocialSecurityBenefitIdEdit(undefined);
          }}
        />
      )}
      {socialSecurityBenefitsList?.map(
        ({ id, dateTo, dateFrom, type, addInfo, isCancelled }) => (
          <UserProfileListElement key={id} id={id}>
            <Grid w="100%" templateColumns="1.5fr 1.4fr 0.1fr" fontSize="size4">
              <Flex alignItems="center" pr={5} gap="8%">
                <ListIcon
                  as={isCancelled ? DateIconX : DateIcon}
                  id={`${id}`}
                />
                {dateFrom ? formatDate(new Date(dateFrom)) : 'nieokreślony'} -{' '}
                {dateTo ? formatDate(new Date(dateTo)) : 'nieokreślony'}
              </Flex>
              <Flex alignItems="center" pr={4} gap="8%">
                <ListIcon as={TypeIcon} />
                {type ? SOCIAL_SECURITY_BENEFIT_NAMES[type] : undefined}
              </Flex>
              <Flex alignItems="center" gap="8%" minWidth="22px">
                {addInfo ? (
                  <Tooltip
                    key={id}
                    label={addInfo}
                    placement="bottom-start"
                    fontSize="size6"
                  >
                    <span>
                      <ListIcon as={InfoIcon} justifyContent="flex-end" />
                    </span>
                  </Tooltip>
                ) : undefined}
              </Flex>
            </Grid>
            <IconButton
              icon={<FaTimes />}
              visibility={isCancelled ? 'hidden' : 'visible'}
              aria-label="Delete"
              mr={1}
              ml={5}
              size="xs"
              variant="iconButton"
              onClick={() => handleClickDelete(id)}
            />
            <IconButton
              icon={<MdModeEdit />}
              variant="iconButton"
              aria-label={'Edit'}
              size="xs"
              onClick={() => handleEditClick(id)}
            />
          </UserProfileListElement>
        )
      )}
    </UserProfileList>
  );
};
