import {
  CSRF_HIDDEN_FORM_FIELD_NAME,
  CSRF_TOKEN_COOKIE_NAME,
} from '../../../api/axiosConfig';
import { Button } from '@chakra-ui/react';
import { getCookie } from 'typescript-cookie';
import { MdOutlineExitToApp } from 'react-icons/md';

export const LogoutButton = () => (
  <form
    method="POST"
    action={`${process.env.REACT_APP_BACKEND_URL || ''}/accounts/logout/`}
  >
    <input
      type="hidden"
      name={CSRF_HIDDEN_FORM_FIELD_NAME}
      value={getCookie(CSRF_TOKEN_COOKIE_NAME)}
    />
    <Button
      fontSize="size7"
      padding="0.5rem"
      height="fit-content"
      type="submit"
      variant="inverted"
      color="light"
      leftIcon={<MdOutlineExitToApp size="1.25rem" />}
    >
      Wyloguj się
    </Button>
  </form>
);
