import { HolidayType } from '../redux/holidays';
import { SelectOption } from './SelectOptions';

export const options: SelectOption[] = [
  { label: 'Wypoczynkowy', value: HolidayType.Leisure },
  { label: 'Na żądanie', value: HolidayType.OnDemand },
  { label: 'Firmowy', value: HolidayType.Company },
  { label: 'Bezpłatny', value: HolidayType.Unpaid },
  { label: 'Okolicznościowy', value: HolidayType.Compassionate },
  { label: 'Opiekuńczy', value: HolidayType.Care },
  { label: 'Za święto', value: HolidayType.HolidayComp },
  { label: 'Za delegację', value: HolidayType.DelegationComp },
];

export const subTypeOptions: SelectOption[] = [
  { label: 'Ślub', value: 'Ślub' },
  { label: 'Narodziny dziecka', value: 'Narodziny dziecka' },
  { label: 'Pogrzeb', value: 'Pogrzeb' },
];
