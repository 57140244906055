import { lastDayOfMonth } from 'date-fns';
import { ApexOptions } from 'apexcharts';
import { useMediaQuery, theme } from '@chakra-ui/react';
import {
  CustomTooltip,
  HolidayDataResponse,
} from '../../../types/holidayAdmin';
import { colors } from '../../../theme';

export const apexChartSeries = (holidaysData: HolidayDataResponse[]) =>
  holidaysData
    ? holidaysData.map(({ data, name }) => {
        return {
          data: data ?? {},
          name,
        };
      })
    : [];

const useResponsiveXAxisLabels = () => {
  // offset represents how much the x-axis label is shifted so that the dates are in the middle of the grid
  const [isBelow2XL, isBelowXL] = useMediaQuery([
    `(max-width: ${theme.breakpoints['2xl']})`,
    `(max-width: ${theme.breakpoints.xl})`,
  ]);

  if (!isBelow2XL) {
    return { offsetX: -27, fontSize: '14px' };
  } else if (isBelow2XL && !isBelowXL) {
    return { offsetX: -17, fontSize: '10px' };
  } else {
    return { offsetX: -13, fontSize: '8px' };
  }
};

export const useApexChartOptions = (
  tooltipCustom: ({ seriesIndex, dataPointIndex, w }: CustomTooltip) => string,
  selectedMonthStartDay: number,
  selectedMonthEndDay: number,
  date: Date,
  xaxisDateFormatter: (value: string) => string,
  isScrolled: boolean
): ApexOptions => {
  const { offsetX, fontSize } = useResponsiveXAxisLabels();

  return {
    chart: {
      type: 'rangeBar',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
      redrawOnWindowResize: true,
      redrawOnParentResize: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '70%',
        rangeBarGroupRows: true,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.5,
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      position: 'back',
      borderColor: colors.adminCalendarBorder,
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    tooltip: {
      theme: 'dark',
      custom: tooltipCustom,
    },
    xaxis: {
      position: isScrolled ? 'bottom' : 'top',
      type: 'datetime',
      min: selectedMonthStartDay,
      max: selectedMonthEndDay,
      tickAmount: lastDayOfMonth(date).getDate(),
      labels: {
        offsetX,
        formatter: xaxisDateFormatter,
        style: {
          fontSize,
        },
      },
    },
    yaxis: {
      labels: {
        maxWidth: 550,
        style: {
          fontSize: '0.8rem',
        },
      },
    },
  };
};
