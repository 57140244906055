import { fonts } from '../../theme';

export const chartTextStyles = { size: 14, weight: '300', family: fonts.body };
export const chartTitleStyles = { size: 18, weight: '600', family: fonts.body };

export const legendPaddingRight = '           ';

export const topBottomCellPadding = '10px';

export const commonChartConfig = {
  tension: 0.1,
  borderWidth: 3,
  pointStyle: 'circle',
  pointRadius: 5,
  pointHoverRadius: 10,
};

export const getTableBackgroundColors = (
  labels: string[],
  backgroundColors: [string, string]
) => {
  if (labels.length % 2 === 0) {
    return Array<string[]>(labels.length / 2)
      .fill(backgroundColors)
      .flat();
  } else {
    return Array<string[]>(Math.ceil(labels.length / 2))
      .fill(backgroundColors)
      .flat()
      .slice(0, -1);
  }
};
