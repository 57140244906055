import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ActionMeta, MultiValue } from 'react-select';
import { FormWrapper } from '../../form/FormWrapper';
import { useForm, useWatch } from 'react-hook-form';
import { TextInput } from '../../form/inputs/TextInput';
import { DatePicker } from '../../form/inputs/DatePicker';
import { useAppDispatch, useAppSelector } from '../../../store';
import { requiredValidation } from '../../../utils/validation';
import { selectCurrentUser } from '../../../redux/user/selectors';
import { UserWithHolidaysModel } from '../../../redux/holidays';
import { formatDate, getYear10YearsAgo } from '../../../utils/date';
import {
  AddPublicHolidayForm,
  PublicHolidayType,
} from '../../../redux/publicHolidays';
import {
  addPublicHolidayAdmin,
  fetchPublicHolidays,
} from '../../../redux/publicHolidays/thunks';
import { SelectOption } from '../../../types/SelectOptions';
import { FormSelect } from '../../form/inputs/Select';
import { fetchHolidayUsersAndSetCurrentHolidays } from '../../../redux/holidays/thunks';
import { fetchSpecificYearUsersHolidayPool } from '../../../redux/holidayPools/thunks';
import { MultiSelect } from '../../form/inputs/MulitSelectInput';
import {
  MULTISELECT_NONE_SELECTED_LABEL,
  MULTISELECT_ALL_SELECTED_LABEL,
  ALL_HOLIDAYS,
} from '../../../utils/consts';
interface Props {
  onClose: () => void;
  isOpen: boolean;
  holidays: UserWithHolidaysModel[];
  setCurrentDate: Dispatch<SetStateAction<Date>>;
  currentYear: number;
}

export const CompanyHolidayApplication: FC<Props> = ({
  onClose,
  isOpen,
  holidays,
  setCurrentDate,
  currentYear,
}) => {
  const options: SelectOption[] = [
    { label: 'Firmowy', value: PublicHolidayType.Company },
    { label: 'Za święto', value: PublicHolidayType.HolidayComp },
  ];

  const user = useAppSelector(selectCurrentUser);
  const [allClicked, setAllClicked] = useState(false);
  const [multiSelectLabel, setMultiSelectLabel] = useState(
    MULTISELECT_NONE_SELECTED_LABEL
  );
  const allOptions = useMemo(
    () => [
      {
        label: MULTISELECT_ALL_SELECTED_LABEL,
        value: MULTISELECT_ALL_SELECTED_LABEL,
      },
      ...holidays.map(({ id, firstName, lastName }) => ({
        value: id.toString(),
        label: `${firstName} ${lastName}`,
      })),
    ],
    [holidays]
  );

  const methods = useForm<AddPublicHolidayForm>();
  const { watch, setValue, control } = methods;

  const dispatch = useAppDispatch();

  const handleValueUsers = (data: readonly SelectOption[]) =>
    setValue('users', data, { shouldValidate: true });

  const submitHandler = useCallback(
    async (data: AddPublicHolidayForm) => {
      const selectedEmployees = data.users
        .filter(({ value }) => value !== MULTISELECT_ALL_SELECTED_LABEL)
        .map((sel) => Number(sel.value));

      try {
        await dispatch(
          addPublicHolidayAdmin({
            ...data,
            users: selectedEmployees,
          })
        ).unwrap();

        void dispatch(
          fetchSpecificYearUsersHolidayPool({
            year: currentYear,
            orderingField: 'user__last_name,user__first_name',
          })
        );
        void dispatch(fetchPublicHolidays());
        void dispatch(
          fetchHolidayUsersAndSetCurrentHolidays(
            ALL_HOLIDAYS,
            'last_name,first_name'
          )
        );

        if (data.dateFrom) setCurrentDate(data.dateFrom);

        onClose();
      } catch (error) {
        console.error(error);
      }
    },
    [currentYear, dispatch, onClose, setCurrentDate]
  );

  const dateFromWatcher = useWatch({ name: 'dateFrom', control });

  useEffect(() => {
    const subscription = watch((value, { type, name }) => {
      if (
        name === 'dateFrom' &&
        type === 'change' &&
        value.dateFrom != null &&
        value.dateTo != null &&
        value.dateFrom > value.dateTo
      )
        setValue('dateTo', value.dateFrom);
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const handleChange = (
    e: MultiValue<SelectOption>,
    value: ActionMeta<SelectOption>
  ) => {
    const { action, option } = value;
    if (option?.label !== MULTISELECT_ALL_SELECTED_LABEL) {
      const selectedOptionsWithoutAll = e.filter(
        ({ value }) => value !== MULTISELECT_ALL_SELECTED_LABEL
      );

      handleValueUsers(e);
      setMultiSelectLabel(
        selectedOptionsWithoutAll.length > 0
          ? `wybrano: ${selectedOptionsWithoutAll.length}`
          : MULTISELECT_NONE_SELECTED_LABEL
      );
      setAllClicked(false);
    } else if (action === 'select-option') {
      handleValueUsers(allOptions);
      setAllClicked(true);
      setMultiSelectLabel(MULTISELECT_ALL_SELECTED_LABEL.toLowerCase());
    } else if (action === 'deselect-option') {
      handleValueUsers([]);
      setAllClicked(true);
      setMultiSelectLabel(MULTISELECT_NONE_SELECTED_LABEL);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      autoFocus={false}
      returnFocusOnClose={false}
    >
      <ModalOverlay backdropFilter="blur(30px)" />
      <ModalContent>
        <ModalHeader>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <GridItem colSpan={2} h="10">
              <Text fontWeight="light" fontSize="size7" variant="secondary">
                {user?.firstName} {user?.lastName}
              </Text>
            </GridItem>
            <GridItem colStart={4} colEnd={6} h="10">
              <Text
                fontWeight="light"
                fontSize="size7"
                align="end"
                variant="secondary"
              >
                {formatDate(new Date())}
              </Text>
            </GridItem>
          </Grid>
        </ModalHeader>
        <Text
          align="center"
          px={18}
          py={18}
          fontSize="size13"
          fontFamily="Roboto"
        >
          DODAJ URLOP FIRMOWY
        </Text>
        <ModalBody pl="135px" pr="135px">
          <FormWrapper onSubmit={submitHandler} methods={methods}>
            <Grid gap={6} alignItems="baseline">
              <GridItem w="100%" h={'3rem'} colSpan={2}>
                <MultiSelect
                  options={allOptions}
                  name="users"
                  label={multiSelectLabel}
                  registerProps={requiredValidation}
                  isAll={allClicked}
                  onChange={handleChange}
                />
              </GridItem>
              <GridItem w="100%" h={'2rem'} colSpan={2}>
                <FormSelect
                  name="type"
                  label="rodzaj urlopu"
                  registerProps={requiredValidation}
                  options={options}
                />
              </GridItem>
              <GridItem>
                <DatePicker
                  label="od"
                  name="dateFrom"
                  minDate={getYear10YearsAgo()}
                  registerProps={requiredValidation}
                />
              </GridItem>
              <GridItem>
                <DatePicker
                  label="do"
                  name="dateTo"
                  minDate={dateFromWatcher}
                  registerProps={requiredValidation}
                />
              </GridItem>
            </Grid>
            <TextInput
              name="additionalInfo"
              label="nazwa święta"
              registerProps={requiredValidation}
            />
            <VStack pt={3} pb={10}>
              <Button variant="main" size="md" height="44px" type="submit">
                WYŚLIJ
              </Button>
              <Button
                variant="inverted"
                size="md"
                height="44px"
                onClick={onClose}
              >
                Cofnij
              </Button>
            </VStack>
          </FormWrapper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
