import { Button, GridItem, VStack, Box, Grid } from '@chakra-ui/react';
import {
  DateIcon,
  HolidayIcon,
  StarIcon,
} from '../../../../../components/common/Icons';
import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { NumberInput } from '../../../../../components/form/inputs/NumberInput';
import { FormSelect } from '../../../../../components/form/inputs/Select';
import {
  AddUserHolidayPoolForm,
  EditUserHolidayPoolForm,
} from '../../../../../types/forms';
import { requiredValidation } from '../../../../../utils/validation';
import { SelectOption } from '../../../../../types/SelectOptions';
import { selectIsHolidayPoolLoading } from '../../../../../redux/holidayPools/selectors';
import { useAppSelector } from '../../../../../store';

interface Props {
  options: SelectOption[];
  isEditing: boolean;
}

export const HolidayPoolForm: FC<Props> = ({ options, isEditing }) => {
  const { control } = useFormContext<
    AddUserHolidayPoolForm | EditUserHolidayPoolForm
  >();
  useWatch({ control });

  const isLoading = useAppSelector(selectIsHolidayPoolLoading);

  return (
    <VStack
      alignItems="flex-start"
      width={isEditing ? undefined : ['100%', null, 'sm']}
    >
      <Grid
        paddingLeft={isEditing ? undefined : 4}
        alignItems={isEditing ? 'center' : 'inherit'}
        borderLeft={isEditing ? undefined : '1px solid'}
        borderColor={isEditing ? undefined : 'active'}
        templateColumns="0.1fr 1fr 1fr"
        columnGap={3}
        rowGap={isEditing ? 8 : 4}
        width={isEditing ? undefined : ['100%', null, 'sm']}
      >
        <GridItem color="active">
          <DateIcon />
        </GridItem>
        <GridItem>
          <FormSelect
            name="year"
            isStandalone
            label="rok"
            registerProps={requiredValidation}
            options={options}
          />
        </GridItem>
        <GridItem color="active" gridRow={2}>
          <HolidayIcon />
        </GridItem>
        <GridItem colSpan={2}>
          <NumberInput
            label="pula przysługujących dni"
            name="days"
            precision={1}
            registerProps={requiredValidation}
            isStandalone
          />
        </GridItem>
        <GridItem color="active">
          <StarIcon />
        </GridItem>
        <GridItem colSpan={2}>
          <NumberInput
            label="dni z poprzedniego roku"
            name="daysFromPreviousYear"
            precision={1}
            registerProps={requiredValidation}
            isStandalone
          />
        </GridItem>
      </Grid>
      <Box paddingTop={2} alignSelf={isEditing ? 'center' : 'flex-end'}>
        <Button isDisabled={isLoading} type="submit" variant="submitForm">
          Zapisz
        </Button>
      </Box>
    </VStack>
  );
};
