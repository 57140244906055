import {
  Flex,
  Grid,
  GridItem,
  ListIcon,
  Stack,
  Text,
  theme,
  Tooltip,
  useDisclosure,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { formatDate, formatDateTime } from '../../../../../utils/date';
import {
  DateIcon,
  DateIconCheck,
  DateIconX,
  InfoIcon,
  TypeIcon,
  TypeHalfDayIcon,
} from '../../../../../components/common/Icons';
import {
  selectFilteredHolidays,
  selectIsHolidaysLoading,
} from '../../../../../redux/holidays/selectors';
import { fetchUserHolidays } from '../../../../../redux/holidaysAdmin/thunks';
import { UserProfileListElement } from '../../../../../components/UserProfileList/UserProfileListElement';
import { UserProfileList } from '../../../../../components/UserProfileList';
import { CenteredSpinner } from '../../../../../components/common/CenteredSpinner';
import { EditHoliday } from './EditHoliday';
import { DeleteHoliday } from './DeleteHoliday';
import { HolidayAdminOptions } from './HolidayAdminOptions';
import {
  HolidayFilter,
  HolidayFilterFieldName,
} from '../../../../../types/holidayFilter';
import { FilterMultiSelect } from '../../../../../components/common/filterSelect/multiSelect';
import { FilterSelect } from '../../../../../components/common/filterSelect/select';
import { MultiValue, SingleValue } from 'react-select';
import { MultiSelectOption } from '../../../../../types/multiSelect';
import { options as holidayTypeOptions } from '../../../../../types/holidayTypes';
import { booleanOptions, holidayNames, monthSelectOptions } from './utils';
import {
  datesListItemElementStyling,
  filtersGridStyling,
  tooltipListItemElementStyling,
  typeListItemElementStyling,
  userProfileListElementGridStyling,
} from './styles';

interface Props {
  userId: number;
}

export const DisplayHoliday: FC<Props> = ({ userId }) => {
  const [holidayId, setHolidayId] = useState<number>();
  const [selectedFilters, setSelectedFilters] = useState<HolidayFilter[]>([]);

  const dispatch = useAppDispatch();
  const holidays = useAppSelector((state) =>
    selectFilteredHolidays(state, selectedFilters)
  );
  const isLoading = useAppSelector(selectIsHolidaysLoading);

  const deleteModalDisclosure = useDisclosure();
  const editModalDisclosure = useDisclosure();

  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);

  useEffect(() => {
    if (userId) void dispatch(fetchUserHolidays(userId));
  }, [dispatch, userId]);

  const handleDeleteClick = (id: number) => {
    setHolidayId(id);
    deleteModalDisclosure.onOpen();
  };

  const handleEditClick = (id: number) => {
    setHolidayId(id);
    editModalDisclosure.onOpen();
  };

  const getHolidayIcon = (
    isAcceptedByAdmin: boolean,
    isAcceptedByEmployee: boolean,
    isCancelled: boolean
  ) => {
    if (isCancelled) {
      return DateIconX;
    } else if (isAcceptedByEmployee && isAcceptedByAdmin) {
      return DateIconCheck;
    } else {
      return DateIcon;
    }
  };

  const getHolidayTypeIcon = (isHalfDay: boolean) => {
    if (isHalfDay) {
      return TypeHalfDayIcon;
    } else {
      return TypeIcon;
    }
  };

  const handleFilterSelectChange = (
    newValue: SingleValue<MultiSelectOption>,
    filterFieldName: HolidayFilterFieldName
  ) => {
    const existingFilterIndex = selectedFilters.findIndex(
      (value) => value.fieldName === filterFieldName
    );

    if (existingFilterIndex >= 0 && newValue !== null) {
      const currentSelectedFilters = selectedFilters;
      (currentSelectedFilters[existingFilterIndex] = {
        fieldName: filterFieldName,
        valuesToMatch: [newValue.value],
      }),
        setSelectedFilters([...currentSelectedFilters]);
    } else if (existingFilterIndex >= 0 && newValue === null) {
      setSelectedFilters(
        selectedFilters.filter((value, index) => index !== existingFilterIndex)
      );
    } else if (existingFilterIndex < 0 && newValue !== null) {
      setSelectedFilters([
        ...selectedFilters,
        {
          fieldName: filterFieldName,
          valuesToMatch: [newValue.value],
        },
      ]);
    }
  };

  const handleFilterMultiSelectChange = (
    newValue: MultiValue<MultiSelectOption>,
    filterFieldName: HolidayFilterFieldName
  ) => {
    const existingFilterIndex = selectedFilters.findIndex(
      (value) => value.fieldName === filterFieldName
    );

    if (existingFilterIndex >= 0 && newValue.length > 0) {
      const currentSelectedFilters = selectedFilters;
      (currentSelectedFilters[existingFilterIndex] = {
        fieldName: filterFieldName,
        valuesToMatch: newValue.map((value) => value.value),
      }),
        setSelectedFilters([...currentSelectedFilters]);
    } else if (existingFilterIndex >= 0 && newValue.length == 0) {
      setSelectedFilters(
        selectedFilters.filter((value, index) => index !== existingFilterIndex)
      );
    } else if (existingFilterIndex < 0 && newValue.length > 0) {
      setSelectedFilters([
        ...selectedFilters,
        {
          fieldName: filterFieldName,
          valuesToMatch: newValue.map((value) => value.value),
        },
      ]);
    }
  };

  if (holidays.length < 1 && isMobile) return null;

  return isLoading ? (
    <CenteredSpinner />
  ) : (
    <VStack>
      <Grid sx={filtersGridStyling}>
        <FilterMultiSelect
          options={holidayTypeOptions}
          onChange={(newValue) =>
            handleFilterMultiSelectChange(newValue, 'type')
          }
          placeholder="rodzaj"
        />
        <GridItem gridColumn={1}>
          <FilterSelect
            options={booleanOptions}
            onChange={(newValue) =>
              handleFilterSelectChange(newValue, 'isAcceptedByAdmin')
            }
            placeholder="akc. admina"
          />
        </GridItem>
        <FilterSelect
          options={booleanOptions}
          onChange={(newValue) =>
            handleFilterSelectChange(newValue, 'isAcceptedByEmployee')
          }
          placeholder="akc. pracownika"
        />
        <FilterSelect
          options={monthSelectOptions}
          onChange={(newValue) =>
            handleFilterSelectChange(newValue, 'dateFromMonthFrom')
          }
          placeholder="rozp. urlopu od"
        />
        <FilterSelect
          options={monthSelectOptions}
          onChange={(newValue) =>
            handleFilterSelectChange(newValue, 'dateFromMonthTo')
          }
          placeholder="rozp. urlopu do"
        />
        <FilterSelect
          options={monthSelectOptions}
          onChange={(newValue) =>
            handleFilterSelectChange(newValue, 'dateToMonthFrom')
          }
          placeholder="zak. urlopu od"
        />
        <FilterSelect
          options={monthSelectOptions}
          onChange={(newValue) =>
            handleFilterSelectChange(newValue, 'dateToMonthTo')
          }
          placeholder="zak. urlopu do"
        />
      </Grid>
      <UserProfileList minHeight={352}>
        {editModalDisclosure.isOpen && holidayId && (
          <EditHoliday
            onClose={() => {
              editModalDisclosure.onClose();
            }}
            holidayId={holidayId}
            userId={userId}
          />
        )}
        {deleteModalDisclosure.isOpen && holidayId && (
          <DeleteHoliday
            holidayId={holidayId}
            onClose={deleteModalDisclosure.onClose}
            userId={userId}
          />
        )}
        {holidays?.map(
          ({
            id,
            dateTo,
            dateFrom,
            type,
            isAcceptedByAdmin,
            isAcceptedByEmployee,
            isCancelled,
            isHalfDay,
            addInfo,
            cancellationInfo,
            createdAt,
            cancelledAt,
          }) => (
            <UserProfileListElement id={id} key={id}>
              <Grid sx={userProfileListElementGridStyling}>
                <Flex sx={datesListItemElementStyling}>
                  <ListIcon
                    as={getHolidayIcon(
                      isAcceptedByAdmin,
                      isAcceptedByEmployee,
                      isCancelled
                    )}
                    id={`${id}`}
                  />
                  {`${
                    dateFrom ? formatDate(new Date(dateFrom)) : 'nieokreślony'
                  } - ${
                    dateTo ? formatDate(new Date(dateTo)) : 'nieokreślony'
                  }`}
                </Flex>
                <Flex sx={typeListItemElementStyling}>
                  <ListIcon as={getHolidayTypeIcon(isHalfDay)} />
                  {holidayNames[type]}
                </Flex>
                <Flex sx={tooltipListItemElementStyling}>
                  <Tooltip
                    key={id}
                    label={
                      <Stack>
                        <Text>utworzono: {formatDateTime(createdAt)}</Text>
                        {addInfo ? <Text>uwagi: {addInfo}</Text> : undefined}
                        {cancelledAt ? (
                          <Text>anulowano: {formatDateTime(cancelledAt)}</Text>
                        ) : undefined}
                        {cancellationInfo ? (
                          <Text>uzasadnienie: {cancellationInfo}</Text>
                        ) : undefined}
                      </Stack>
                    }
                    placement="bottom-start"
                    fontSize="size6"
                  >
                    <span>
                      <ListIcon as={InfoIcon} justifyContent="flex-end" />
                    </span>
                  </Tooltip>
                </Flex>
              </Grid>
              <HolidayAdminOptions
                isAcceptedByAdmin={isAcceptedByAdmin}
                isAcceptedByEmployee={isAcceptedByEmployee}
                isCancelled={isCancelled}
                id={id}
                userId={userId}
                handleEdit={handleEditClick}
                handleDelete={handleDeleteClick}
                setHolidayId={setHolidayId}
              />
            </UserProfileListElement>
          )
        )}
      </UserProfileList>
    </VStack>
  );
};
