import { Box, ButtonGroup, Flex, IconButton, Text } from '@chakra-ui/react';
import { FaTrashAlt, FaTimes, FaCheck } from 'react-icons/fa';
import { FC } from 'react';
import { EventContentInterface } from '../../../types/calendarEventContent';

interface Props {
  eventInfo: EventContentInterface;
  handleDelete: (id: number) => void;
  handleAccept: (id: number) => Promise<void>;
  isForAdmin: boolean;
  hoveredEventId?: string;
}

export const AdminActionIconsOnCalendarEvent: FC<Props> = ({
  eventInfo,
  handleDelete,
  handleAccept,
  isForAdmin,
  hoveredEventId,
}) => {
  const returnColor = () => {
    if (
      (hoveredEventId === eventInfo.event.id &&
        eventInfo.event.extendedProps.isAcceptedByEmployee &&
        eventInfo.event.backgroundColor === 'holidayAccepted') ||
      !isForAdmin
    ) {
      return 'holidayAccepted';
    } else if (
      (hoveredEventId === eventInfo.event.id &&
        !eventInfo.event.extendedProps.isAcceptedByEmployee &&
        eventInfo.event.backgroundColor === 'holidayUnaccepted') ||
      !isForAdmin
    ) {
      return 'holidayUnaccepted';
    } else if (
      (hoveredEventId === eventInfo.event.id &&
        eventInfo.event.extendedProps.isAcceptedByEmployee &&
        eventInfo.event.backgroundColor === 'holidayAcceptedOthers') ||
      !isForAdmin
    ) {
      return 'holidayAcceptedOthers';
    } else if (
      (hoveredEventId === eventInfo.event.id &&
        !eventInfo.event.extendedProps.isAcceptedByEmployee &&
        eventInfo.event.backgroundColor === 'holidayUnacceptedOthers') ||
      !isForAdmin
    ) {
      return 'holidayUnacceptedOthers';
    } else {
      return 'none';
    }
  };

  if (
    eventInfo.event.extendedProps.hasTrashIcon &&
    eventInfo.event.extendedProps.hasAcceptIcon
  ) {
    return (
      <Flex
        key={eventInfo.event.id}
        alignItems="center"
        alignContent="space-between"
        justifyContent="space-between"
        fontSize="size3"
        maxHeight={'26px'}
        pl={2}
      >
        <Text>{eventInfo.event.title}</Text>
        <ButtonGroup
          backgroundColor={
            eventInfo.event.extendedProps.isHalfDay &&
            hoveredEventId === eventInfo.event.id &&
            eventInfo.event.backgroundColor === 'holidayUnaccepted'
              ? 'holidayUnaccepted'
              : eventInfo.event.extendedProps.isHalfDay &&
                hoveredEventId === eventInfo.event.id &&
                eventInfo.event.backgroundColor === 'holidayUnacceptedOthers'
              ? 'holidayUnacceptedOthers'
              : 'none'
          }
          minHeight={eventInfo.event.extendedProps.isHalfDay ? '28px' : 'none'}
          borderRadius={4}
        >
          <IconButton
            variant="iconButtonCalendar"
            position="relative"
            icon={
              isForAdmin ? <FaTimes size="10px" /> : <FaTrashAlt size="10px" />
            }
            aria-label={'Delete'}
            ml="2"
            py="2"
            h="20px"
            minW="20px"
            visibility={
              (eventInfo.isEnd && !isForAdmin) ||
              (eventInfo.isEnd &&
                hoveredEventId === eventInfo.event.id &&
                isForAdmin)
                ? 'visible'
                : 'hidden'
            }
            onClick={() =>
              handleDelete(eventInfo.event.extendedProps.idHoliday)
            }
          />
          <IconButton
            variant="iconButtonCalendar"
            position="relative"
            icon={<FaCheck size="10px" />}
            aria-label={'Accept'}
            h="20px"
            mr={2}
            minW="20px"
            visibility={
              eventInfo.isEnd &&
              hoveredEventId === eventInfo.event.id &&
              isForAdmin
                ? 'visible'
                : 'hidden'
            }
            onClick={() =>
              handleAccept(eventInfo.event.extendedProps.idHoliday)
            }
          />
        </ButtonGroup>
      </Flex>
    );
  } else if (
    eventInfo.event.extendedProps.hasTrashIcon &&
    !eventInfo.event.extendedProps.hasAcceptIcon
  ) {
    return (
      <Flex
        key={eventInfo.event.id}
        alignItems="center"
        alignContent="space-between"
        justifyContent="space-between"
        fontSize="size3"
        maxHeight={'26px'}
        pl={2}
      >
        <Text>{eventInfo.event.title}</Text>
        <Box
          minHeight={eventInfo.event.extendedProps.isHalfDay ? '28px' : 'none'}
          backgroundColor={returnColor()}
          borderRadius={4}
        >
          <IconButton
            variant="iconButtonCalendar"
            position="relative"
            icon={
              isForAdmin ? <FaTimes size="10px" /> : <FaTrashAlt size="10px" />
            }
            aria-label={'Delete'}
            mr="2"
            h="20px"
            minW="20px"
            visibility={
              (eventInfo.isEnd && !isForAdmin) ||
              (eventInfo.isEnd &&
                hoveredEventId === eventInfo.event.id &&
                isForAdmin)
                ? 'visible'
                : 'hidden'
            }
            onClick={() =>
              handleDelete(eventInfo.event.extendedProps.idHoliday)
            }
          />
        </Box>
      </Flex>
    );
  } else {
    return null;
  }
};
