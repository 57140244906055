import { FC } from 'react';
import { Center, Link, Text } from '@chakra-ui/react';

export const Footer: FC = () => (
  <Center bg="dark" py="1.75rem" as="footer">
    <Text color="light" fontSize="size4">
      2022 Revolve -{' '}
      <Link href="https://www.revolve.pro/">www.revolve.pro</Link> . All rights
      reserved.
    </Text>
  </Center>
);
