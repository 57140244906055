import { VStack, GridItem, Button, Grid, Box } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  DateIcon,
  TypeIcon,
  HolidayIcon,
} from '../../../../../components/common/Icons';
import { DatePicker } from '../../../../../components/form/inputs/DatePicker';
import { options } from '../../../../../types/contractTypes';
import {
  AddUserContractForm,
  EditUserContractForm,
} from '../../../../../types/forms';
import { FormSelect } from '../../../../../components/form/inputs/Select';
import { NumberInput } from '../../../../../components/form/inputs/NumberInput';
import { requiredValidation } from '../../../../../utils/validation';
import { getYear10YearsAgo } from '../../../../../utils/date';
import { useAppSelector } from '../../../../../store';
import { selectIsContractLoading } from '../../../../../redux/contracts/selectors';

interface Props {
  isEditing?: boolean;
  onOpen?: () => void;
}
export const FormContract: FC<Props> = ({ isEditing }) => {
  const { control, watch, setValue } = useFormContext<
    AddUserContractForm | EditUserContractForm
  >();

  const dateFromWatcher = useWatch({
    name: 'dateFrom',
    control,
  });

  useEffect(() => {
    const subscription = watch((value, { type, name }) => {
      if (
        name === 'dateFrom' &&
        type === 'change' &&
        value.dateFrom != null &&
        value.dateTo != null &&
        value.dateFrom > value.dateTo
      )
        setValue('dateTo', value.dateFrom);
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const isLoading = useAppSelector(selectIsContractLoading);

  return (
    <VStack
      alignItems="flex-start"
      width={isEditing ? undefined : ['100%', null, 'sm']}
    >
      <Grid
        paddingLeft={isEditing ? undefined : 4}
        alignItems={isEditing ? 'center' : 'inherit'}
        borderLeft={isEditing ? undefined : '1px solid'}
        borderColor={isEditing ? undefined : 'active'}
        templateColumns="0.1fr 1fr 1fr"
        columnGap={3}
        rowGap={isEditing ? 8 : 4}
        width={isEditing ? undefined : ['100%', null, 'sm']}
      >
        <GridItem color="active">
          <DateIcon />
        </GridItem>
        <DatePicker
          label="data od"
          name="dateFrom"
          minDate={getYear10YearsAgo()}
          registerProps={requiredValidation}
          isStandalone
        />
        <DatePicker
          label="data do"
          name="dateTo"
          minDate={dateFromWatcher}
          isStandalone
        />
        <GridItem color="active">
          <TypeIcon />
        </GridItem>
        <GridItem colSpan={2}>
          <FormSelect
            name="type"
            isStandalone
            label="rodzaj umowy"
            registerProps={requiredValidation}
            options={options}
          />
        </GridItem>
        <GridItem color="active">
          <HolidayIcon />
        </GridItem>
        <GridItem colSpan={2}>
          <NumberInput
            name="holidayPool"
            precision={1}
            registerProps={requiredValidation}
            label="pula przysługujących dni"
            isStandalone
          />
        </GridItem>
      </Grid>
      <Box paddingTop={2} alignSelf={isEditing ? 'center' : 'flex-end'}>
        <Button isDisabled={isLoading} type="submit" variant="submitForm">
          Zapisz
        </Button>
      </Box>
    </VStack>
  );
};
