import { FC, ReactElement } from 'react';
import { Flex } from '@chakra-ui/react';
import { gradients } from '../../../theme';

interface Props {
  id: number;
  children: ReactElement[];
}

export const UserProfileListElement: FC<Props> = ({ id, children }) => (
  <Flex
    key={id}
    className="parent"
    alignItems="center"
    justifyItems="center"
    padding={1}
    mb={2}
    sx={{
      'button': {
        opacity: 0,
        pointerEvents: 'none',
      },
      'div svg': {
        color: 'active',
      },
      'svg.calendarcheck': {
        color: 'holidayApplicationAccepted',
      },
      'svg.calendarcancelled': {
        color: 'holidayApplicationCancelled',
      },
    }}
    _hover={{
      'background': gradients.inverted,
      'textColor': 'light',
      'button': {
        opacity: 1,
        pointerEvents: 'auto',
      },
      'div svg': { color: 'light' },
      'borderRadius': '0 1.875rem 1.875rem 0',
      'color': 'light',
      'svg.halfDayIcon > path.background': { fill: 'light' },
      'svg.halfDayIcon > path.halfText': { fill: 'active' },
    }}
  >
    {children}
  </Flex>
);
