import { Stack, Text, HStack, Tooltip } from '@chakra-ui/react';
import { FiInfo } from 'react-icons/fi';
import { UserHolidayPool } from '../../../redux/holidayPools';
import { removeTrailingZeros } from '../../../utils/numberFormatting';

export function mapHolidayPoolsDetailsForAdmin(
  currentYear: number,
  holidayPools: UserHolidayPool[]
) {
  return (
    <Stack
      direction={['row', null, null, null, 'column']}
      gap="1.25rem"
      whiteSpace="nowrap"
      overflowX="auto"
      pb="10px"
      mr="3rem"
      mb="5rem"
    >
      {holidayPools.map(
        ({
          userData,
          usedHolidayPool,
          leftHolidayPool,
          fullHolidayPool,
          daysFromPreviousYear,
          days,
          acceptedCompanyHolidaysDays,
          acceptedCompanyHolidaysDaysAddInfo,
          pendingAcceptCompanyHolidaysDays,
        }) => (
          <Stack
            borderLeft="1px solid"
            borderColor="active"
            display="inline-block"
            key={userData.id}
            spacing={1}
            pl={4}
            pt={1}
            fontSize="size4"
          >
            <Text textTransform="uppercase">
              {userData.firstName} {userData.lastName}
            </Text>
            <Text>
              CAŁKOWITA PULA URLOPOWA: {removeTrailingZeros(fullHolidayPool)}
            </Text>
            <Text>
              URLOP Z {currentYear - 1}:{' '}
              {removeTrailingZeros(daysFromPreviousYear)}
            </Text>
            <Text>
              URLOP Z {currentYear}: {removeTrailingZeros(days)}
            </Text>
            <HStack>
              <Text>
                URLOP FIRMOWY:{' '}
                {removeTrailingZeros(acceptedCompanyHolidaysDays.toString())}
              </Text>
              {acceptedCompanyHolidaysDaysAddInfo.length > 0 ? (
                <Tooltip
                  label={
                    <Stack>
                      {acceptedCompanyHolidaysDaysAddInfo.map(
                        (addInfo, index) => (
                          <Text key={index} color="light">
                            {addInfo}
                          </Text>
                        )
                      )}
                    </Stack>
                  }
                  placement="right-start"
                  fontSize="size6"
                >
                  <span>
                    <FiInfo
                      size={12}
                      style={{ margin: '0.3em', position: 'relative' }}
                      color="dark"
                    />
                  </span>
                </Tooltip>
              ) : null}
            </HStack>
            <Text>WYKORZYSTANE: {removeTrailingZeros(usedHolidayPool)}</Text>
            <HStack>
              <Text>
                DO WYKORZYSTANIA: {removeTrailingZeros(leftHolidayPool)}
              </Text>
              {pendingAcceptCompanyHolidaysDays > 0 ? (
                <Tooltip
                  label={`${
                    removeTrailingZeros(
                      pendingAcceptCompanyHolidaysDays.toString()
                    ) || ''
                  } dni urlopu firmowego jest jeszcze niezaakceptowane`}
                  placement="right-start"
                  fontSize="size6"
                >
                  <span>
                    <FiInfo
                      size={12}
                      style={{ margin: '0.3em', position: 'relative' }}
                      color="dark"
                    />
                  </span>
                </Tooltip>
              ) : undefined}
            </HStack>
          </Stack>
        )
      )}
    </Stack>
  );
}
