import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  Heading,
  ModalBody,
} from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { FormWrapper } from '../../../../../components/form/FormWrapper';
import { FormSocialSecurityBenefits } from './FormSocialSecurityBenefits';
import { SocialSecurityBenefitsEditModel } from '../../../../../redux/socialSecurityBenefits';
import { selectBenefit } from '../../../../../redux/socialSecurityBenefits/selectors';
import { editUserSocialSecurityBenefits } from '../../../../../redux/socialSecurityBenefits/thunks';
import { getDirtyValues } from '../../../../../utils/hookForm';

interface Props {
  onClose: () => void;
  id: number;
  user: number;
}
export const EditSocialSecurityBenefits: FC<Props> = ({
  id,
  user,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const result = useAppSelector((state) => selectBenefit(state, id));

  const methods = useForm<SocialSecurityBenefitsEditModel>({
    defaultValues: {
      id,
      user,
      dateTo: result?.dateTo ? new Date(result.dateTo) : undefined,
      dateFrom: result?.dateFrom ? new Date(result.dateFrom) : undefined,
      type: result?.type || undefined,
      addInfo: result?.addInfo,
    },
  });
  const dirtyFields = methods.formState.dirtyFields;

  useEffect(() => {
    const subscription = methods.watch((value, { type, name }) => {
      if (
        name === 'dateFrom' &&
        type === 'change' &&
        value.dateFrom != null &&
        value.dateTo != null &&
        value.dateFrom > value.dateTo
      )
        methods.setValue('dateTo', value.dateFrom);
    });
    return () => subscription.unsubscribe();
  }, [methods]);

  const handleSubmit = async (data: SocialSecurityBenefitsEditModel) => {
    if (!id) return;

    dirtyFields.dateFrom = true;
    dirtyFields.dateTo = true;

    const changeset = getDirtyValues(dirtyFields, data);

    try {
      await dispatch(
        editUserSocialSecurityBenefits({ ...changeset, id: id, user: user })
      ).unwrap();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal isOpen onClose={onClose} size="xl" autoFocus={false}>
      <ModalOverlay backdropFilter="blur(30px)" />
      <ModalContent px={['12', '24']} py={['8', '16']} justifyContent="center">
        <ModalHeader textAlign="center">
          <Heading as="h1" m={4} pb={[0, 10]} size="lg" fontWeight={300}>
            EDYTUJ ŚWIADCZENIE
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody justifyItems="center">
          <FormWrapper methods={methods} onSubmit={handleSubmit}>
            <FormSocialSecurityBenefits isEditing />
          </FormWrapper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
