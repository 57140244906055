import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export const selectIsDelegationLoading = (state: RootState) =>
  state.delegation.isLoading;
export const selectIsAddDelegationLoading = (state: RootState) =>
  state.delegation.isLoadingAdd;
export const selectUserDelegations = (state: RootState) =>
  state.delegation.userDelegations;

const selectUserDelegationId = (
  state: RootState,
  delegationId: number | undefined
) => delegationId;

export const selectUserDelegation = createSelector(
  [selectUserDelegations, selectUserDelegationId],
  (delegations, delegationId) => {
    return delegations.find((delegation) => delegation.id == delegationId);
  }
);
