import { HolidayType } from '../redux/holidays';
import { SocialSecurityBenefitsType } from '../redux/socialSecurityBenefits';
import { colors } from '../theme';
import { FULL_WEEK_DAYS_NAMES, SHORT_WEEK_DAYS_NAMES } from './consts';

export const checkIsSocialSecurityBenefits = (
  type: SocialSecurityBenefitsType | HolidayType
) => {
  return Object.values(SocialSecurityBenefitsType).includes(
    type as SocialSecurityBenefitsType
  );
};

export const checkIsCompanyHoliday = (
  type: HolidayType | SocialSecurityBenefitsType
) => {
  return [
    HolidayType.HolidayComp,
    HolidayType.Company,
    HolidayType.DelegationComp,
  ].includes(type as HolidayType);
};

export const checkIsCompanyHolidayOrNational = (
  type: HolidayType | SocialSecurityBenefitsType
) => {
  return [
    HolidayType.HolidayComp,
    HolidayType.NationalHoliday,
    HolidayType.Company,
  ].includes(type as HolidayType);
};

const checkIsNationalHoliday = (
  type: HolidayType | SocialSecurityBenefitsType
) => {
  return [HolidayType.NationalHoliday, HolidayType.HolidayComp].includes(
    type as HolidayType
  );
};

export const mapColors = (
  isAcceptedByAdmin: boolean,
  isAcceptedByEmployee: boolean,
  currentUserId: number | undefined,
  userId: number,
  type: HolidayType | SocialSecurityBenefitsType
) => {
  if (checkIsNationalHoliday(type)) {
    return colors.nationalHoliday;
  } else if (checkIsCompanyHoliday(type)) {
    return colors.companyHolidayAdmin;
  } else if (checkIsSocialSecurityBenefits(type)) {
    return colors.sickLeaveHoliday;
  } else if (isAcceptedByAdmin && isAcceptedByEmployee) {
    return userId === currentUserId
      ? colors.holidayAccepted
      : colors.holidayAcceptedOthers;
  } else if (!isAcceptedByAdmin || !isAcceptedByEmployee) {
    return userId === currentUserId
      ? colors.holidayUnaccepted
      : colors.holidayUnacceptedOthers;
  }
};

export const xAxisDateFormatter = (value: string) => {
  const date = new Date(value).getDate();
  const weekName = new Date(value).toLocaleDateString('pl-PL', {
    weekday: 'long',
  }) as FULL_WEEK_DAYS_NAMES;

  return isNaN(date)
    ? value
    : `${date.toString().padStart(2, '0')} ${
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        SHORT_WEEK_DAYS_NAMES[weekName]
      }`;
};
