import { BaseSyntheticEvent, PropsWithChildren } from 'react';
import { FormProvider, UseFormReturn, SubmitHandler } from 'react-hook-form';

interface Props<T> extends PropsWithChildren {
  methods: UseFormReturn<T>;
  onSubmit: SubmitHandler<T>;
}

export const FormWrapper = <T,>({ methods, children, onSubmit }: Props<T>) => {
  const submitHandler = async (data: T, event?: BaseSyntheticEvent) => {
    event?.preventDefault();
    await onSubmit(data, event);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submitHandler)}>{children}</form>
    </FormProvider>
  );
};
