import {
  Heading,
  Input,
  Table,
  TableContainer,
  Tbody,
  Thead,
  Tr,
  VStack,
  HStack,
  Text,
  Flex,
  Grid,
  InputRightElement,
  InputGroup,
  useDisclosure,
  IconButton,
  Fade,
} from '@chakra-ui/react';
import { TbSearch } from 'react-icons/tb';
import { formatDate } from '../../../utils/date';
import { useAppDispatch, useAppSelector } from '../../../store';
import React, { useEffect, useState, useMemo } from 'react';
import { fetchUsers } from '../../../redux/users/thunks';
import {
  selectIsUsersLoading,
  selectUsers,
} from '../../../redux/users/selectors';
import { UserListModel } from '../../../redux/users';
import { UsersTh } from './UsersTh';
import { UsersTd } from './UsersTd';
import { SortButton } from './SortButton';
import { CenteredSpinner } from '../../../components/common/CenteredSpinner';
import { gradients } from '../../../theme';
import { orderingFieldInterface } from '../../../types/thunk';

const INFINITY_CHAR = '\u221E';

export const UsersList = () => {
  const dispatch = useAppDispatch();
  const userList = useAppSelector(selectUsers);
  const isLoading = useAppSelector(selectIsUsersLoading);

  const [searchName, setSearchName] = useState('');
  const filteredList = useMemo(() => {
    return userList.filter(
      (searching) =>
        searching.lastName.toLowerCase().startsWith(searchName.toLowerCase()) ||
        searching.firstName.toLowerCase().startsWith(searchName.toLowerCase())
    );
  }, [searchName, userList]);

  const { isOpen, onToggle } = useDisclosure();
  const [orderingInfo, setOrderingInfo] = useState<orderingFieldInterface>({
    orderingFieldName: 'last_name',
    desc: false,
  });

  useEffect(() => {
    void dispatch(fetchUsers(orderingInfo));
  }, [dispatch, orderingInfo]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const serachtext = event.target.value.toString();
    setSearchName(serachtext);
  };

  const handleSortButtonClick = (modelFieldName: string) => {
    if (
      !orderingInfo.desc &&
      modelFieldName === orderingInfo.orderingFieldName
    ) {
      setOrderingInfo({
        orderingFieldName: orderingInfo.orderingFieldName,
        desc: true,
      });
    } else if (
      orderingInfo.desc &&
      modelFieldName === orderingInfo.orderingFieldName
    ) {
      setOrderingInfo({
        orderingFieldName: orderingInfo.orderingFieldName,
        desc: false,
      });
    } else if (modelFieldName !== orderingInfo.orderingFieldName) {
      setOrderingInfo({
        orderingFieldName: modelFieldName,
        desc: false,
      });
    }
  };

  const columnWidth = '218px';

  if (isLoading) return <CenteredSpinner />;
  return (
    <VStack p={[2, null, 4]}>
      <Grid
        templateColumns={['1fr', null, '0.2fr 1fr 0.2fr']}
        w="100%"
        paddingX={18}
        pb={10}
      >
        <Flex
          justifyContent="center"
          direction={['column', 'row']}
          gridColumn={[1, null, 2]}
        >
          <Heading variant="main">LISTA OSÓB ZATRUDNIONYCH</Heading>
        </Flex>
        <InputGroup
          size="lg"
          variant="main"
          mt={3}
          flexDirection={['column', 'row']}
          justifyContent="flex-end"
        >
          <InputRightElement>
            <IconButton
              variant="unstyled"
              icon={<TbSearch />}
              aria-label={'search'}
              fontSize="size11"
              onClick={onToggle}
              pb={5}
            />
          </InputRightElement>
          <Fade in={isOpen}>
            <Input
              fontSize="size8"
              onChange={handleInputChange}
              width="1fr"
              height="auto"
              borderBottomColor="active"
            />
          </Fade>
        </InputGroup>
      </Grid>
      <Grid w="100%" paddingX={18} justifyContent="center">
        <TableContainer overflowY="auto" minH="xl">
          <Table variant="simple">
            <Thead
              _after={{
                margin: 4,
                content: '""',
                display: 'block',
              }}
            >
              <Tr display="block">
                <UsersTh columnWidth={columnWidth}>
                  <HStack>
                    <Text>NAZWISKO</Text>
                    <SortButton
                      modelFieldName="last_name"
                      orderingInfo={orderingInfo}
                      handleClick={handleSortButtonClick}
                    />
                  </HStack>
                </UsersTh>
                <UsersTh columnWidth={columnWidth}>
                  <HStack>
                    <Text>IMIĘ</Text>
                    <SortButton
                      modelFieldName="first_name"
                      orderingInfo={orderingInfo}
                      handleClick={handleSortButtonClick}
                    />
                  </HStack>
                </UsersTh>
                <UsersTh columnWidth={columnWidth}>
                  <HStack>
                    <Text>UMOWA</Text>
                    <SortButton
                      modelFieldName="contract_type"
                      orderingInfo={orderingInfo}
                      handleClick={handleSortButtonClick}
                    />
                  </HStack>
                </UsersTh>
                <UsersTh columnWidth={columnWidth}>
                  <HStack>
                    <Text>STANOWISKO</Text>
                    <SortButton
                      modelFieldName="position"
                      orderingInfo={orderingInfo}
                      handleClick={handleSortButtonClick}
                    />
                  </HStack>
                </UsersTh>
                <UsersTh columnWidth={columnWidth}>
                  <HStack>
                    <Text>DATA UR.</Text>
                    <SortButton
                      modelFieldName="date_of_birth"
                      orderingInfo={orderingInfo}
                      handleClick={handleSortButtonClick}
                    />
                  </HStack>
                </UsersTh>
                <UsersTh columnWidth={columnWidth}>
                  <HStack>
                    <Text>UMOWA OD</Text>
                    <SortButton
                      modelFieldName="contract_date_from"
                      orderingInfo={orderingInfo}
                      handleClick={handleSortButtonClick}
                    />
                  </HStack>
                </UsersTh>
                <UsersTh columnWidth={columnWidth}>
                  <HStack>
                    <Text>UMOWA DO</Text>
                    <SortButton
                      modelFieldName="contract_date_to"
                      orderingInfo={orderingInfo}
                      handleClick={handleSortButtonClick}
                    />
                  </HStack>
                </UsersTh>
              </Tr>
            </Thead>
            <Tbody border={0}>
              {(searchName.length > 0 ? filteredList : userList).map(
                ({
                  id,
                  firstName,
                  lastName,
                  position,
                  dateOfBirth,
                  contractData,
                }: UserListModel) => {
                  const firstLastName = `${firstName.toLowerCase()}-${lastName.toLowerCase()}`;

                  return (
                    <Tr
                      key={id}
                      overflow="hidden"
                      display="block"
                      width="fit-content"
                      _hover={{
                        background: gradients.inverted,
                        color: 'light',
                        borderRadius: '0 1.875rem 1.875rem 0',
                      }}
                    >
                      <UsersTd
                        id={id}
                        firstLastName={firstLastName}
                        text={lastName}
                        hasTitleProp={true}
                        columnWidth={columnWidth}
                      />
                      <UsersTd
                        id={id}
                        firstLastName={firstLastName}
                        text={firstName}
                        columnWidth={columnWidth}
                      />
                      <UsersTd
                        id={id}
                        firstLastName={firstLastName}
                        text={contractData.type}
                        columnWidth={columnWidth}
                      />
                      <UsersTd
                        id={id}
                        firstLastName={firstLastName}
                        text={position}
                        hasTitleProp={true}
                        columnWidth={columnWidth}
                      />
                      <UsersTd
                        id={id}
                        firstLastName={firstLastName}
                        text={
                          dateOfBirth
                            ? formatDate(new Date(dateOfBirth))
                            : undefined
                        }
                        columnWidth={columnWidth}
                      />
                      <UsersTd
                        id={id}
                        firstLastName={firstLastName}
                        text={
                          contractData.dateFrom
                            ? formatDate(new Date(contractData.dateFrom))
                            : undefined
                        }
                        columnWidth={columnWidth}
                      />
                      <UsersTd
                        id={id}
                        firstLastName={firstLastName}
                        text={
                          contractData.dateTo
                            ? formatDate(new Date(contractData.dateTo))
                            : contractData.dateFrom ?? contractData.type
                            ? INFINITY_CHAR
                            : undefined
                        }
                        columnWidth={columnWidth}
                      />
                    </Tr>
                  );
                }
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Grid>
    </VStack>
  );
};
