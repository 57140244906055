import 'react-datepicker/dist/react-datepicker.css';
import { components, DropdownIndicatorProps } from 'react-select';
import { MdArrowDropDown } from 'react-icons/md';
import { SelectOption } from '../../../types/SelectOptions';

export const DropdownIndicator = <T = SelectOption,>(
  props: DropdownIndicatorProps<T>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <MdArrowDropDown size={21} color="black" />
    </components.DropdownIndicator>
  );
};
