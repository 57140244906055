import {
  Grid,
  Heading,
  Box,
  IconButton,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FC } from 'react';
import { MdEdit } from 'react-icons/md';
import { GridRow } from '../../../../../components/common/GridRow';
import { UserDetails } from '../../../../../redux/users';
import avatarPlaceholderUrl from '../../../../../images/assets/avatarPlaceholder.png';
import { EditUserData } from './EditUserData';
import { formatDate } from '../../../../../utils/date';

export const DisplayUserData: FC<{ user?: UserDetails }> = ({ user }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Grid
      w={['100%', null, null, null, '7xl']}
      templateColumns={['1fr', null, '128px minmax(0, 1fr)']}
      gap={7}
      mb={16}
    >
      <Image
        w={32}
        h={32}
        display="block"
        src={user?.image?.imageUrl ?? avatarPlaceholderUrl}
        margin="auto"
        fallbackSrc={avatarPlaceholderUrl}
        borderRadius="100%"
        alt="avatar"
      />
      <Box>
        <EditUserData isOpen={isOpen} user={user} onClose={onClose} />
        <Text lineHeight={0.5} fontSize="size7">
          {user?.position?.toUpperCase()}
        </Text>
        <Heading as="h1" size="xl" fontWeight={300} mb={4}>
          {user?.firstName?.toUpperCase()} {user?.lastName?.toUpperCase()}
          <IconButton
            h={8}
            minW={8}
            p={0}
            variant="main"
            icon={<MdEdit size={16} />}
            ml={8}
            aria-label="Edytuj"
            onClick={onOpen}
          />
        </Heading>
        <Grid templateColumns="auto 2fr" fontSize="size4">
          <GridRow label="DATA URODZENIA">
            {user?.dateOfBirth && formatDate(user.dateOfBirth)}
          </GridRow>
          <GridRow label="NUMER TELEFONU">
            {user?.phoneNumber?.trim() || '-'}
          </GridRow>
          <GridRow label="NUMER AWARYJNY">
            {user?.emergencyNumber?.trim() || '-'}
          </GridRow>
          <GridRow label="ADRES">{user?.streetName?.trim() || '-'}</GridRow>
          <GridRow label="">{user?.city?.trim() || '-'}</GridRow>
        </Grid>
      </Box>
    </Grid>
  );
};
