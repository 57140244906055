import { Stack, Text } from '@chakra-ui/react';
import { UserHolidayPool } from '../../../redux/holidayPools';
import { removeTrailingZeros } from '../../../utils/numberFormatting';

export function mapHolidayPoolsDetailsForEmployee(
  holidayPools: UserHolidayPool[]
) {
  return (
    <Stack
      direction={['row', null, null, null, 'column']}
      gap="1.25rem"
      whiteSpace="nowrap"
      overflowX="auto"
      pb="10px"
      mr="3rem"
      mb="5rem"
    >
      {holidayPools.map(({ userData, usedHolidayPool, leftHolidayPool }) => (
        <Stack
          borderLeft="1px solid"
          borderColor="active"
          key={userData.id}
          display="inline-block"
          spacing={1}
          pl={4}
          pt={1}
          fontSize="size4"
        >
          <Text textTransform="uppercase">
            {userData.firstName} {userData.lastName}
          </Text>
          <Text>WYKORZYSTANE: {removeTrailingZeros(usedHolidayPool)}</Text>
          <Text>DO WYKORZYSTANIA: {removeTrailingZeros(leftHolidayPool)}</Text>
        </Stack>
      ))}
    </Stack>
  );
}
