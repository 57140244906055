import { Navigate, Route, Routes } from 'react-router-dom';
import { FC } from 'react';
import { UsersList } from '../../../pages/CompanyAdmin/UsersList';
import { Statistics } from '../../../pages/CompanyAdmin/Statistics';
import { Layout } from '../../Layout';
import { Profile } from '../../../pages/CompanyAdmin/UsersList/Profile';
import { AdminCalendar } from '../../../pages/CompanyAdmin/AdminCalendar';
import { calendarAdminPath } from '../../../utils/paths';

export const CompanyAdminRoutes: FC = () => (
  <Layout isAdminView>
    <Routes>
      <Route path="/calendar" element={<AdminCalendar />} />
      <Route path="/statistics" element={<Statistics />} />
      <Route path="/users-list" element={<UsersList />} />
      <Route
        path="/users-list/:userId/:userFirstLastName"
        element={<Profile />}
      />
      <Route path="*" element={<Navigate to={calendarAdminPath} replace />} />
    </Routes>
  </Layout>
);
