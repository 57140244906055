import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import {
  useFormContext,
  get,
  FieldError,
  RegisterOptions,
} from 'react-hook-form';

export interface InputWrapperProps extends PropsWithChildren {
  name: string;
  label?: string;
  helperTxt?: string;
  isStandalone?: boolean;
  variant?: string;
}

export interface DefaultInputProps {
  name: string;
  label?: string;
  helperTxt?: string;
  isStandalone?: boolean;
  registerProps?: RegisterOptions;
  variant?: string;
}

export const InputWrapper: FC<InputWrapperProps> = ({
  name,
  label,
  helperTxt,
  isStandalone,
  children,
  variant = 'floating',
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl variant={variant} isInvalid={!!get(errors, name)}>
      {children}
      <FormLabel color={!get(errors, name) ? 'inputError' : undefined}>
        {label}
      </FormLabel>
      {!isStandalone && (
        <>
          <FormErrorMessage alignItems="top" minH={5} fontSize="size2" m={0}>
            {(get(errors, name) as FieldError)?.message}
          </FormErrorMessage>
          {!get(errors, name) && (
            <FormHelperText minH={5} fontSize="size2" m={0}>
              {helperTxt}
            </FormHelperText>
          )}
        </>
      )}
    </FormControl>
  );
};
