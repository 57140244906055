import { Td, TableColumnHeaderProps, Box } from '@chakra-ui/react';
import { FC } from 'react';

interface Props extends TableColumnHeaderProps {
  innerBackgroundColor?: string;
  innerPaddingLeft?: (string | null)[] | string;
  innerPaddingRight?: (string | null)[] | string;
  isForNames?: boolean;
}

export const StatsTableTd: FC<Props> = ({
  children,
  innerBackgroundColor,
  innerPaddingLeft,
  innerPaddingRight,
  isForNames,
  ...props
}) => (
  <Td
    paddingTop="0"
    paddingBottom="0"
    paddingInlineStart="0"
    paddingInlineEnd="0"
    textAlign={isForNames ? 'left' : 'center'}
    width={isForNames ? '19%' : '3.375%'}
    height="fit-content"
    borderRightColor={isForNames ? 'active' : undefined}
    borderRightWidth={isForNames ? '1px' : undefined}
    fontWeight="300"
    {...props}
  >
    <Box
      backgroundColor={innerBackgroundColor}
      paddingTop="4px"
      paddingBottom="4px"
      paddingLeft={innerPaddingLeft}
      paddingRight={innerPaddingRight}
    >
      {children}
    </Box>
  </Td>
);
