import {
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FC } from 'react';
import { UserDetails } from '../../../../../redux/users';
import avatarPlaceholderUrl from '../../../../../images/assets/avatarPlaceholder.png';
import { FormWrapper } from '../../../../../components/form/FormWrapper';
import { useForm } from 'react-hook-form';
import { TextInput } from '../../../../../components/form/inputs/TextInput';
import { EditProfileForm } from '../../../../../types/forms';
import { useAppDispatch } from '../../../../../store';
import { partiallyUpdateUser } from '../../../../../redux/users/thunks';
import { phoneNumberValidation } from '../../../../../utils/validation';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  user?: UserDetails;
}

export const EditUserData: FC<Props> = ({ onClose, isOpen, user }) => {
  const methods = useForm<EditProfileForm>({
    defaultValues: {
      position: user?.position,
      phoneNumber: user?.phoneNumber,
      emergencyNumber: user?.emergencyNumber,
      streetName: user?.streetName,
      city: user?.city,
    },
  });
  const dispatch = useAppDispatch();

  const onSubmit = async (data: EditProfileForm) => {
    if (!user) return;
    try {
      await dispatch(partiallyUpdateUser({ ...data, id: user.id })).unwrap();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" autoFocus={false}>
      <ModalOverlay backdropFilter="blur(30px)" />
      <ModalContent px={24} py={16}>
        <FormWrapper methods={methods} onSubmit={onSubmit}>
          <ModalHeader textAlign="center">
            <Heading as="h1" m={4} size="lg" fontWeight={300}>
              {user?.firstName?.toUpperCase()} {user?.lastName?.toUpperCase()}
            </Heading>
            <Image
              w={24}
              h={24}
              display="block"
              src={user?.image?.imageUrl ?? avatarPlaceholderUrl}
              fallbackSrc={avatarPlaceholderUrl}
              borderRadius="100%"
              m="auto"
              alt="avatar"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TextInput name="position" label="Stanowisko" />
            <TextInput
              name="phoneNumber"
              registerProps={phoneNumberValidation}
              label="Numer telefonu"
            />
            <TextInput
              name="emergencyNumber"
              registerProps={phoneNumberValidation}
              label="Numer telefonu awaryjnego"
            />
            <TextInput
              name="streetName"
              label="Adres"
              helperTxt="Ulica i numer budynku"
            />
            <TextInput
              name="city"
              label="Adres"
              helperTxt="Miasto i kod pocztowy"
            />
          </ModalBody>
          <ModalFooter>
            <Button m="auto" variant="main" type="submit">
              ZAPISZ
            </Button>
          </ModalFooter>
        </FormWrapper>
      </ModalContent>
    </Modal>
  );
};
