import { EventInput } from '@fullcalendar/react';
import { createSelector } from '@reduxjs/toolkit';
import { getMonth } from 'date-fns/esm';
import {
  HolidayWithAnonymizedTypeModel,
  HolidayType,
  HolidayWithUserModel,
  HolidayModel,
} from '.';
import { RootState } from '../../store';
import { colors } from '../../theme';
import { HolidayFilter } from '../../types/holidayFilter';
import { ALL_HOLIDAYS } from '../../utils/consts';
import {
  correctToCalendarDate,
  formatToBackendDate,
  formatDate,
} from '../../utils/date';
import { PublicHolidayType } from '../publicHolidays';
import { selectSickLeaveEvents } from '../socialSecurityBenefits/selectors';

const ALLOWED_HOLIDAYS_TO_DELETE_OR_ACCEPT = [
  HolidayType.Unpaid,
  HolidayType.Compassionate,
  HolidayType.Leisure,
  HolidayType.OnDemand,
];

const EVENT_COLORS = {
  [PublicHolidayType.HolidayComp]: colors.nationalHoliday,
  [PublicHolidayType.NationalHoliday]: colors.nationalHoliday,
  [PublicHolidayType.Company]: colors.companyHoliday,
} as const;

export const HOLIDAY_NAMES = {
  [HolidayType.Compassionate]: 'okolicznościowy',
  [HolidayType.Care]: 'opiekuńczy',
  [HolidayType.Unpaid]: 'bezpłatny',
  [HolidayType.Leisure]: 'wypoczynkowy',
  [HolidayType.OnDemand]: 'na żądanie',
  [HolidayType.HolidayComp]: 'za święto',
  [HolidayType.Company]: 'firmowy',
  [HolidayType.DelegationComp]: 'za delegację',
  [HolidayType.NationalHoliday]: 'za święto narodowe',
} as const;

export const selectCurrentHolidays = (state: RootState) =>
  state.holidays.currentHolidays;

export const selectUserHolidays = (state: RootState) =>
  state.holidays.userProfileHolidays;

export const selectHolidayUsers = (state: RootState) =>
  state.holidays.holidayUsers;

export const selectIsLoadingGenerateReport = (state: RootState) =>
  state.holidays.isLoadingGenerateReport;

export const selectIsHolidaysLoading = (state: RootState) =>
  state.holidays.isLoading;

const selectHolidayId = (state: RootState, holidayId: number) => holidayId;

const selectUserWitHolidayId = (state: RootState, userId: number) => userId;

const getHolidayColor = (
  { isAcceptedByEmployee, isAcceptedByAdmin, user }: HolidayWithUserModel,
  userId?: number
) => {
  if (user === userId) {
    return isAcceptedByEmployee && isAcceptedByAdmin
      ? colors.holidayAccepted
      : colors.holidayUnaccepted;
  } else {
    return isAcceptedByEmployee && isAcceptedByAdmin
      ? colors.holidayAcceptedOthers
      : colors.holidayUnacceptedOthers;
  }
};

const isDeletable = (
  holiday: HolidayWithUserModel,
  isForAdmin: boolean,
  currentUserId?: number
) => {
  const holidayTypeInHolidaTypesAllowedToDeleteOrAccept = holiday.type
    ? ALLOWED_HOLIDAYS_TO_DELETE_OR_ACCEPT.includes(holiday.type)
    : false;

  return (
    (holiday &&
      holidayTypeInHolidaTypesAllowedToDeleteOrAccept &&
      holiday.isAcceptedByAdmin &&
      holiday.isAcceptedByEmployee &&
      holiday.dateFrom >= formatToBackendDate(new Date()) &&
      holiday.user === currentUserId) ||
    isForAdmin
  );
};

const isAcceptable = (
  holiday: HolidayWithAnonymizedTypeModel,
  isForAdmin: boolean
) => {
  const holidayTypeInHolidaTypesAllowedToDeleteOrAccept = holiday.type
    ? ALLOWED_HOLIDAYS_TO_DELETE_OR_ACCEPT.includes(holiday.type)
    : false;

  return (
    holiday &&
    !holiday.isAcceptedByAdmin &&
    holiday.isAcceptedByEmployee &&
    holiday.dateFrom >= formatToBackendDate(new Date()) &&
    ((holidayTypeInHolidaTypesAllowedToDeleteOrAccept &&
      !holiday.isAcceptedByAdmin &&
      holiday.isAcceptedByEmployee &&
      holiday.dateFrom >= formatToBackendDate(new Date())) ||
      isForAdmin)
  );
};

export const selectUserEvents = (
  state: RootState,
  isForAdmin: boolean,
  currentUserId?: number
): EventInput[] =>
  state.holidays.currentHolidays?.holidaySet?.map((holiday) => ({
    id: holiday.id.toString(),
    className: holiday.isHalfDay ? 'half-day' : undefined,
    title: `${holiday.firstName} ${holiday.lastName.substring(0, 1)}.`,
    start: holiday.dateFrom.toString(),
    end: correctToCalendarDate(holiday.dateTo),
    color: getHolidayColor(holiday, state.user.current?.id),
    display: 'auto',
    extendedProps: {
      hasTrashIcon: isDeletable(holiday, isForAdmin, currentUserId),
      idHoliday: holiday.id,
      hasAcceptIcon: isAcceptable(holiday, isForAdmin),
      isHalfDay: holiday.isHalfDay ? true : false,
      isAcceptedByEmployee: holiday.isAcceptedByEmployee ? true : false,
      alertInfo: holiday.type
        ? `${formatDate(holiday.dateFrom)}-${formatDate(holiday.dateTo)} - ${
            HOLIDAY_NAMES[holiday.type]
          }`
        : `${formatDate(holiday.dateFrom)}-${formatDate(holiday.dateTo)}`,
    },
  })) ?? [];

export const selectPublicEvents = (
  state: RootState,
  isForAdmin?: boolean
): EventInput[] =>
  state.publicHolidays.publicHolidaySet.map(
    ({ dateFrom, dateTo, type, additionalInfo }) => ({
      title: 'FiInfo',
      start: dateFrom,
      end: isForAdmin ? dateTo : correctToCalendarDate(dateTo),
      color: EVENT_COLORS[type],
      display: 'background',
      extendedProps: {
        additionalInfo: additionalInfo,
        type: type,
      },
    })
  );

export const selectUsersWithHolidays = createSelector(
  [selectHolidayUsers, selectUserWitHolidayId],
  (usersWithHolidays, userId) => {
    return userId === ALL_HOLIDAYS
      ? usersWithHolidays
      : usersWithHolidays.filter(
          (userWithHolidays) => userWithHolidays.id === userId
        );
  }
);

export const selectCurrentEvents = createSelector(
  [selectUserEvents, selectPublicEvents, selectSickLeaveEvents],
  (currentUserEvents, currentPublicEvents, sickLeaveEvents) => {
    return [
      ...(currentUserEvents ?? []),
      ...(currentPublicEvents ?? []),
      ...(sickLeaveEvents ?? []),
    ];
  }
);

export const selectNationalHolidayEvents = createSelector(
  [selectUserEvents],
  (currentUserEvents) => {
    return currentUserEvents
      ? currentUserEvents.filter(
          (event) => event.extendedProps?.type === HolidayType.NationalHoliday
        )
      : [];
  }
);

export const selectHolidayById = createSelector(
  [selectUserHolidays, selectHolidayId],
  (holidays, holidayId) => {
    if (holidays) {
      return holidays.find((holiday) => holiday.id === holidayId);
    }
  }
);

const checkHolidayFilterMatch = (
  holiday: HolidayModel,
  holidayFilter: HolidayFilter
) => {
  if (holidayFilter.fieldName === 'dateFromMonthFrom') {
    if (typeof holidayFilter.valuesToMatch[0] !== 'number') return false;

    return (
      holidayFilter.valuesToMatch[0] <= getMonth(new Date(holiday.dateFrom))
    );
  } else if (holidayFilter.fieldName === 'dateFromMonthTo') {
    if (typeof holidayFilter.valuesToMatch[0] !== 'number') return false;

    return (
      holidayFilter.valuesToMatch[0] >= getMonth(new Date(holiday.dateFrom))
    );
  } else if (holidayFilter.fieldName === 'dateToMonthFrom') {
    if (typeof holidayFilter.valuesToMatch[0] !== 'number') return false;

    return holidayFilter.valuesToMatch[0] <= getMonth(new Date(holiday.dateTo));
  } else if (holidayFilter.fieldName === 'dateToMonthTo') {
    if (typeof holidayFilter.valuesToMatch[0] !== 'number') return false;

    return holidayFilter.valuesToMatch[0] >= getMonth(new Date(holiday.dateTo));
  } else {
    return holidayFilter.valuesToMatch.includes(
      holiday[holidayFilter.fieldName]
    );
  }
};

const selectHolidayFilters = (
  state: RootState,
  holidayFilter: HolidayFilter[]
) => holidayFilter;

export const selectFilteredHolidays = createSelector(
  [selectUserHolidays, selectHolidayFilters],
  (holidays, holidayFilters) => {
    return holidays.filter((holiday) => {
      const filterChecks = holidayFilters.map((holidayFilter) =>
        checkHolidayFilterMatch(holiday, holidayFilter)
      );
      return filterChecks.every((filterCheck) => filterCheck);
    });
  }
);
