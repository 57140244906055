import { createSlice } from '@reduxjs/toolkit';
import {
  deleteUserHolidayPool,
  fetchUserHolidayPools,
  addUserHolidayPool,
  editUserHolidayPool,
  fetchSpecificYearUsersHolidayPool,
} from './thunks';

export interface UserHolidayPool {
  id: number;
  year: number;
  days: string;
  daysFromPreviousYear: string;
  fullHolidayPool: string;
  usedHolidayPool: string;
  leftHolidayPool: string;
  pendingAcceptCompanyHolidaysDays: number;
  acceptedCompanyHolidaysDays: number;
  acceptedCompanyHolidaysDaysAddInfo: string[];
  userData: { id: number; firstName: string; lastName: string };
}

export interface HolidayPoolState {
  isLoading: boolean;
  isLoadingAdd: boolean;
  userHolidayPools: UserHolidayPool[];
  usersSpecificYearHolidayPool: UserHolidayPool[];
}

const initialState: HolidayPoolState = {
  isLoading: false,
  isLoadingAdd: false,
  userHolidayPools: [],
  usersSpecificYearHolidayPool: [],
};

const holidayPoolSlice = createSlice({
  name: 'holidayPool',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserHolidayPools.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserHolidayPools.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userHolidayPools = action?.payload ?? [];
      })
      .addCase(fetchUserHolidayPools.rejected, (state) => {
        state.isLoading = false;
        state.userHolidayPools = [];
      })
      .addCase(fetchSpecificYearUsersHolidayPool.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSpecificYearUsersHolidayPool.fulfilled, (state, action) => {
        state.isLoading = false;
        state.usersSpecificYearHolidayPool = action?.payload ?? [];
      })
      .addCase(fetchSpecificYearUsersHolidayPool.rejected, (state) => {
        state.isLoading = false;
        state.usersSpecificYearHolidayPool = [];
      })
      .addCase(addUserHolidayPool.pending, (state) => {
        state.isLoadingAdd = true;
      })
      .addCase(addUserHolidayPool.fulfilled, (state, { payload }) => {
        state.isLoadingAdd = false;

        if (state.userHolidayPools && payload) {
          state.userHolidayPools = [...state.userHolidayPools, payload].sort(
            (userHolidayPool, payload) => {
              return payload.year - userHolidayPool.year;
            }
          );
        }
      })
      .addCase(addUserHolidayPool.rejected, (state) => {
        state.isLoadingAdd = false;
      })
      .addCase(editUserHolidayPool.fulfilled, (state, { payload }) => {
        if (!payload) return;

        const { id, ...restOfItem } = payload;
        state.userHolidayPools = state.userHolidayPools?.map((item) =>
          item.id === id ? { ...item, ...restOfItem } : item
        );
      })
      .addCase(editUserHolidayPool.rejected, (state) => {
        state.userHolidayPools = [];
      })
      .addCase(deleteUserHolidayPool.fulfilled, (state, { payload }) => {
        state.userHolidayPools = state.userHolidayPools.filter(
          (userHolidayPool) => userHolidayPool.id !== payload?.id
        );
      });
  },
});

export default holidayPoolSlice.reducer;
