import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { deleteUserSocialSecurityBenefits } from '../../../../../redux/socialSecurityBenefits/thunks';
import { useAppDispatch } from '../../../../../store';

interface Props {
  onClose: () => void;
  id: number | undefined;
}

export const DeleteSocialSecurityBenefits: FC<Props> = ({ id, onClose }) => {
  const dispatch = useAppDispatch();
  const onSubmit = async () => {
    if (!id) return;
    try {
      await dispatch(deleteUserSocialSecurityBenefits({ id })).unwrap();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Modal isOpen onClose={onClose} autoFocus={false}>
      <ModalOverlay backdropFilter="blur(30px)" />
      <ModalContent px={12} py={28} maxW="680px">
        <ModalBody justifyContent="center">
          <Text textAlign="center" fontSize="size13" pb={8}>
            CZY NA PEWNO CHCESZ USUNĄĆ?
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="center" gap={16}>
          <Button variant="main" onClick={onSubmit}>
            TAK
          </Button>
          <Button variant="main" onClick={onClose}>
            NIE
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
