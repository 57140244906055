import 'react-datepicker/dist/react-datepicker.css';
import { FC } from 'react';
import { HStack } from '@chakra-ui/react';
import Select, { ActionMeta, SingleValue } from 'react-select';
import { BaseFilterProps, MultiSelectOption } from '../../../types/multiSelect';
import '../../form/inputs/multiSelect.scss';
import { DropdownIndicator } from '../../form/inputs/DropDownIcon';
import { Option } from './../../form/inputs/Option';
import { commonDefaultProps } from './commonDefaultProps';
import { FilterIcon } from './filterIcon';

interface Props extends BaseFilterProps {
  onChange: (
    newValue: SingleValue<MultiSelectOption>,
    actionMeta: ActionMeta<MultiSelectOption>
  ) => void;
}

export const FilterSelect: FC<Props> = ({
  options,
  onChange,
  onMenuClose,
  placeholder,
}) => (
  <HStack>
    <FilterIcon />
    <Select
      placeholder={placeholder}
      onChange={onChange}
      onMenuClose={onMenuClose}
      options={options}
      isMulti={false}
      components={{ DropdownIndicator, Option }}
      closeMenuOnSelect={true}
      blurInputOnSelect={true}
      {...commonDefaultProps}
    />
  </HStack>
);
