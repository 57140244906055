import { extendTheme } from '@chakra-ui/react';

export const colors = {
  unActive: '#F3F3F3',
  active: '#00CCFF',
  light: '#FFFFFF',
  dark: '#151523',
  drawerBackground: '#001b22',
  main: '#6F81FF',
  secondary: '#bcbcc0',
  unActiveInput: '#707070',
  activeInput: '#007FFF',
  invalidInput: '#D32F2F',
  holidayUnaccepted: '#D9DEFE',
  holidayUnacceptedOthers: '#CDF1FC',
  nationalHoliday: '#B2F4D5',
  holidayAccepted: '#6F81FF',
  holidayAcceptedOthers: '#5CC9FA',
  companyHoliday: '#FEC8C8',
  sickLeaveHoliday: '#B8B8BD',
  nationalHolidayIconColor: '#16A360',
  companyHolidayIconColor: '#ED5565',
  disabledButton: '#d4d9fd',
  enabledButton: '#6f81fa',
  onHoverOrFocusButton: '#4d5ab2',
  holidayApplicationAccepted: '#16A360',
  holidayApplicationCancelled: '#ED5565',
  scrollbarTrack: '#9ae8fc',
  stripedTableColumnBackground: '#EDFAFD',
  checkboxBorderColor: 'darkgray',
  shadow: '#00000029',
  failureIcon: '#c96666',
  companyHolidayAdmin: '#FEC8C840',
  adminCalendarBorder: '#A0CFE1',
  transparent: 'transparent',
};

export const gradients = {
  main: 'linear-gradient(90deg, #6f81ff, #0cf)',
  inverted: 'linear-gradient(270deg, #6f81ff, #0cf)',
};

export const sizes = {
  defaultMainContainer: '115rem',
};

export const inputStyle = {
  sizes: {
    lg: {
      field: {
        px: 0,
        height: 10,
        fontSize: 'size9',
        fontWeight: 300,
        option: {
          fontSize: 'size6',
        },
      },
    },
    md: {
      field: {
        height: 6,
        pl: 1.5,
        fontSize: 'size6',
        fontWeight: 300,
      },
    },
  },
  baseStyle: {
    field: {
      borderColor: 'unActiveInput',
      border: 'none',
      _disabled: {
        opacity: 0.6,
      },
      _focusWithin: {
        borderColor: 'activeInput',
        borderWidth: '2px',
      },
      _invalid: {
        borderColor: 'invalidInput',
        borderWidth: '2px',
      },
    },
  },
  defaultProps: {
    variant: 'flushed',
  },
};

const components = {
  Button: {
    baseStyle: {
      textTransform: 'uppercase',
    },
    variants: {
      main: {
        background: 'enabledButton',
        color: 'light',
        borderRadius: '1.875rem',
        px: 8,
        fontWeight: 300,
        _disabled: {
          backgroundColor: 'disabledButton',
        },
        _hover: {
          backgroundColor: 'onHoverOrFocusButton',
          _disabled: { backgroundColor: 'disabledButton' },
        },
        _focus: {
          backgroundColor: 'onHoverOrFocusButton',
        },
      },
      iconButtonCalendar: {
        background: 'light',
        color: 'main',
        borderRadius: '50%',
        border: 0.5,
      },
      iconButton: {
        background: 'light',
        color: 'main',
        borderRadius: '50%',
        border: 3,
      },

      submitForm: {
        borderRadius: '3.125rem',
        bgColor: 'enabledButton',
        color: 'light',
        fontSize: 'size4',
        _disabled: {
          backgroundColor: 'disabledButton',
        },
        _hover: {
          backgroundColor: 'onHoverOrFocusButton',
          _disabled: { backgroundColor: 'disabledButton' },
        },
        _focus: {
          backgroundColor: 'onHoverOrFocusButton',
        },
      },
      inverted: {
        background: 'transparent',
        color: 'active',
        borderRadius: '1.875rem',
        px: 8,
        fontWeight: 300,
        _hover: {
          color: 'main',
        },
      },
    },
  },
  Text: {
    variants: {
      secondary: {
        color: 'unActiveInput',
      },
    },
  },
  Input: inputStyle,
  Select: inputStyle,
  NumberInput: inputStyle,
  MultiSelect: inputStyle,

  Form: {
    variants: {
      floating: {
        container: {
          '_focusWithin': {
            label: {
              transform: 'scale(0.85) translateY(-1.2rem)',
              color: 'activeInput',
            },
          },
          'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, div[data-value] + label':
            {
              transform: 'scale(0.85) translateY(-1.3rem)',
            },
          'label': {
            _invalid: {
              color: 'invalidInput',
            },
            top: 0,
            left: 0,
            p: 0,
            position: 'absolute',
            pointerEvents: 'none',
            transformOrigin: 'left top',
          },
          // because we use Menu component from Chakra UI instead of native HTML select,
          // we need to style button that opens this menu (MenuButton component that renders button with name "menu-button")
          // to look like an input field to reach desired visual efect
          'button[name="menu-button"]': {
            _focusWithin: {
              borderBottomColor: 'activeInput',
              borderBottomWidth: '3px',
            },
            _invalid: {
              borderBottomColor: 'invalidInput',
              borderBottomWidth: '3px',
            },
          },
          '.chakra-form__helper-text, .chakra-form__error-message': {
            mb: 3,
          },
        },
      },
    },
  },
  Table: {
    baseStyle: { tr: { th: { color: 'dark' } } },
  },
  Heading: {
    variants: {
      main: {
        fontSize: 'size12',
        fontWeight: 'normal',
      },
    },
  },
  Modal: {
    defaultProps: {
      isCentered: true,
    },
  },
  Checkbox: {
    baseStyle: {
      icon: {
        bg: 'main',
        py: 0.5,
      },
      control: {
        _checked: {
          borderColor: 'main',
        },
      },
    },
  },
};

const styles = {
  global: {
    'html, body, #root': {
      height: '100%',
    },
    'div#root *': {
      '::-webkit-scrollbar': {
        width: '1.2em',
        height: '1.2em',
      },
      '::-webkit-scrollbar-track': {
        borderRadius: '30px',
        backgroundColor: 'scrollbarTrack',
        border: '5px transparent solid',
        backgroundClip: 'padding-box',
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: '30px',
        backgroundColor: 'active',
        border: '5px transparent solid',
        backgroundClip: 'padding-box',
      },
    },
    '.blur-effect-list': {
      position: 'absolute',
      display: 'flex',
      height: '100%',
      width: '100%',
      pointerEvents: 'none',
      top: '0',
      mt: '3rem',
      right: '0',
      background: [
        'linear-gradient(90deg, #ffffff00 80%, #fff)',
        null,
        null,
        null,
        'linear-gradient(180deg, #ffffff00 80%, #fff)',
      ],
    },
    '.react-datepicker': {
      border: 'none',
      boxShadow: `-1px 2px 6px ${colors.shadow}`,
    },
    '.react-datepicker__header': {
      background: 'white',
      border: 'none',
    },
    '.react-datepicker__day, .react-datepicker__day:hover': {
      borderRadius: '100%',
    },
    '.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected':
      {
        backgroundColor: 'main',
        borderRadius: '100%',
      },
    '.react-datepicker__year-dropdown-container--scroll:last-child': {
      marginLeft: '25px',
    },
    '.react-datepicker__year-dropdown': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      height: 'fit-content',
      width: 'fit-content',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      gap: '0.5rem',
      backgroundColor: 'light',
    },
    '.react-datepicker__year-option': {
      width: '80px',
      height: 'fit-content',
      paddingBottom: '10px',
      paddingTop: '10px',
      borderRadius: '1.875rem',
      marginTop: '1px',
      marginBottom: '1px',
    },
    '.react-datepicker__navigation--years': {
      position: 'absolute',
    },
    '.react-datepicker__year-option--selected_year': {
      backgroundColor: 'main',
      color: 'light',
    },
    '.react-datepicker__year-option--selected': {
      display: 'none',
    },
    '.react-datepicker__year-option:hover': {
      backgroundColor: 'main',
      color: 'light',
    },
    '.react-datepicker__month-dropdown': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      height: 'fit-content',
      width: 'fit-content',
      padding: '0.5rem',
      gap: '0.5rem',
      backgroundColor: 'light',
    },
    '.react-datepicker__month-option': {
      width: '110px',
      height: 'fit-content',
      paddingBottom: '10px',
      paddingTop: '10px',
      borderRadius: '1.875rem',
      marginTop: '1px',
      marginBottom: '1px',
    },
    '.react-datepicker__navigation--month': {
      position: 'absolute',
    },
    '.react-datepicker__month-option--selected_month': {
      backgroundColor: 'main',
      color: 'light',
    },
    '.react-datepicker__month-option--selected': {
      display: 'none',
    },
    '.react-datepicker__month-option:hover': {
      backgroundColor: 'main',
      color: 'light',
    },
    '.react-datepicker__year-option:has(a)': {
      gridColumn: '1/-1',
      borderRadius: 'none',
      backgroundColor: 'light',
    },
    '.react-datepicker__year-option:has(a):hover': {
      backgroundColor: 'light',
    },
    '.react-datepicker__navigation-icon--previous::before': {
      transform: 'none',
      borderTop: '5px solid transparent',
      borderBottom: '5px solid transparent',
      borderRight: `5px solid ${colors.dark}`,
    },
    '.react-datepicker__navigation-icon--next::before': {
      transform: 'none',
      borderTop: '5px solid transparent',
      borderRight: `5px solid transparent`,
      borderBottom: '5px solid transparent',
      borderLeft: `5px solid ${colors.dark}`,
    },
    '.react-datepicker__navigation--previous:hover ::before': {
      borderColor: 'gray',
      borderTop: '5px solid transparent',
      borderBottom: '5px solid transparent',
    },
    '.react-datepicker__navigation--next:hover ::before': {
      borderColor: 'gray',
      borderTop: '5px solid transparent',
      borderRight: `5px solid transparent`,
      borderBottom: '5px solid transparent',
    },
    '.react-datepicker__month-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow':
      {
        transform: 'none',
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderTop: `5px solid ${colors.dark}`,
        marginTop: '8px',
      },
    '.react-datepicker__current-month': {
      display: 'none',
    },
    '.react-datepicker__navigation-icon': {
      marginTop: 4,
    },
    '.react-datepicker__header__dropdown': {
      fontSize: 'size6',
    },
    '.react-datepicker__day--selected:hover': {
      background: '#6419bd',
      borderRadius: '100%',
    },
    'html, body': {
      color: 'dark',
    },
    'ul.list-with-invisible-scrollbar': {
      '::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
};

export const fonts = {
  heading: 'Roboto, sans-serif',
  body: 'Roboto, sans-serif',
};

const fontSizes = {
  size1: '0.6rem',
  size2: '0.75rem',
  size3: '0.85rem',
  size4: '0.875rem',
  size5: '0.9rem',
  size6: '1rem',
  size7: '1.125rem',
  size8: '1.25rem',
  size9: '1.375rem',
  size10: '1.5rem',
  size11: '1.875rem',
  size12: '2.3125rem',
  size13: '2.4375rem',
  size14: '2.9375rem',
};

export const theme = extendTheme({
  components,
  colors,
  fonts,
  fontSizes,
  styles,
});
