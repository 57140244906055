import { Th, TableColumnHeaderProps, Box } from '@chakra-ui/react';
import { FC } from 'react';

interface Props extends TableColumnHeaderProps {
  innerBackgroundColor?: string;
  innerPaddingLeft?: (string | null)[] | string;
  innerPaddingRight?: (string | null)[] | string;
  innerPaddingTop?: (string | null)[] | string;
  innerPaddingBottom?: (string | null)[] | string;
  isForNames?: boolean;
}

export const StatsTableTh: FC<Props> = ({
  children,
  innerBackgroundColor,
  innerPaddingLeft,
  innerPaddingRight,
  innerPaddingTop,
  innerPaddingBottom,
  isForNames,
  ...props
}) => (
  <Th
    paddingTop="0"
    paddingBottom="0"
    paddingInlineStart="0"
    paddingInlineEnd="0"
    textAlign={isForNames ? 'left' : 'center'}
    borderRightColor={isForNames ? 'active' : undefined}
    borderRightWidth={isForNames ? '1px' : undefined}
    fontWeight="300"
    {...props}
  >
    <Box
      minWidth="55px"
      paddingTop={innerPaddingTop ? innerPaddingTop : '0'}
      paddingBottom={innerPaddingBottom ? innerPaddingBottom : '0'}
      paddingLeft={innerPaddingLeft}
      paddingRight={innerPaddingRight}
      backgroundColor={innerBackgroundColor}
    >
      {children}
    </Box>
  </Th>
);
