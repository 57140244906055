import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteUserContract, EditUserContractForm } from '../../types/forms';
import { EditUserContractThunk, ThunkError } from '../../types/thunk';
import { AddUserContractForm } from '../../types/forms';
import { displaySuccessToast } from '../../utils/toasts';
import { UserContracts } from '.';
import client from '../../api/axiosConfig';
import { handleError } from '../../utils/handleError';
import { formatToBackendDate } from '../../utils/date';

export const addUserContract = createAsyncThunk<
  UserContracts | undefined,
  AddUserContractForm
>(
  'contractId/addContract',

  async (
    { user, dateFrom, dateTo, type, holidayPool },
    { rejectWithValue }
  ) => {
    if (!dateFrom) return; // required in validation
    const formattedDateFrom = formatToBackendDate(dateFrom);
    const formattedDateTo = dateTo ? formatToBackendDate(dateTo) : null;

    try {
      const response = await client.post<{ results: UserContracts }>(
        `people/contracts/`,
        {
          user,
          dateFrom: formattedDateFrom,
          dateTo: formattedDateTo,
          type,
          holidayPool,
        }
      );
      if (response.data.results)
        displaySuccessToast({ title: 'Dodano pomyślnie' });
      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

export const fetchUserContracts = createAsyncThunk<
  UserContracts[] | undefined,
  number
>('contractId/fetch', async (id, { rejectWithValue }) => {
  try {
    const response = await client.get<{ results: UserContracts[] }>(
      `people/contracts/?user=${id}`
    );
    return response.data.results;
  } catch (error) {
    handleError(error as ThunkError);
    return rejectWithValue((error as ThunkError)?.response?.data);
  }
});

export const deleteUserContract = createAsyncThunk<
  DeleteUserContract | undefined,
  number
>('contractId/delete', async (id, { rejectWithValue }) => {
  try {
    const response = await client.delete<{ results: undefined }>(
      `people/contracts/${id}/`
    );
    return { results: response.data.results, id };
  } catch (error) {
    handleError(error as ThunkError);
    return rejectWithValue((error as ThunkError)?.response?.data);
  }
});

export const editUserContract = createAsyncThunk<
  EditUserContractThunk | undefined,
  EditUserContractForm
>(
  'contractId/editContract',
  async ({ id, dateTo, dateFrom, type, holidayPool }, { rejectWithValue }) => {
    const formattedDateFrom = dateFrom
      ? formatToBackendDate(dateFrom)
      : undefined;
    const formattedDateTo = dateTo ? formatToBackendDate(dateTo) : undefined;

    try {
      const response = await client.patch<{ results: EditUserContractThunk }>(
        `people/contracts/${id}/`,
        {
          dateFrom: formattedDateFrom,
          dateTo: formattedDateTo,
          type,
          holidayPool,
        }
      );
      if (response.data.results)
        displaySuccessToast({ title: 'Edytowano pomyślnie' });
      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);
