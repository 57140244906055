import { Stack, Text, HStack, Tooltip } from '@chakra-ui/react';
import { FiInfo } from 'react-icons/fi';
import { UserHolidayPool } from '../../../redux/holidayPools';
import { displayHolidayPoolField } from '../../../utils/holidayPool';
import { removeTrailingZeros } from '../../../utils/numberFormatting';

export function formatHolidayPoolDetailsForOneUser(
  currentYear: number,
  isForAdmin: boolean,
  socialSecurityBenefitsDaysSum?: number,
  holidayPool?: UserHolidayPool
) {
  return (
    <Stack
      borderLeft="1px solid"
      borderColor="active"
      p={4}
      spacing={3}
      fontSize="size4"
    >
      <Text fontWeight="bold">
        TWOJA PULA URLOPOWA:{' '}
        {displayHolidayPoolField(holidayPool?.fullHolidayPool)}
      </Text>
      <Text>
        URLOP Z {currentYear - 1}:{' '}
        {displayHolidayPoolField(holidayPool?.daysFromPreviousYear)}
      </Text>
      <Text>
        URLOP NA {currentYear}: {displayHolidayPoolField(holidayPool?.days)}
      </Text>
      <HStack>
        <Text>
          URLOP FIRMOWY:{' '}
          {displayHolidayPoolField(
            holidayPool?.acceptedCompanyHolidaysDays.toString()
          )}
        </Text>
        {holidayPool &&
        holidayPool.acceptedCompanyHolidaysDaysAddInfo.length > 0 ? (
          <Tooltip
            key={holidayPool.id}
            label={
              <Stack>
                {holidayPool.acceptedCompanyHolidaysDaysAddInfo.map(
                  (addInfo, index) => (
                    <Text key={index} color="light">
                      {addInfo}
                    </Text>
                  )
                )}
              </Stack>
            }
            placement="right-start"
            fontSize="size6"
          >
            <span>
              <FiInfo
                size={18}
                style={{ margin: '0.3em', position: 'relative' }}
                color="dark"
              />
            </span>
          </Tooltip>
        ) : null}
      </HStack>
      <Text>
        WYKORZYSTANE: {displayHolidayPoolField(holidayPool?.usedHolidayPool)}
      </Text>
      <HStack>
        <Text>
          DO WYKORZYSTANIA:{' '}
          {displayHolidayPoolField(holidayPool?.leftHolidayPool)}
        </Text>
        {holidayPool && holidayPool.pendingAcceptCompanyHolidaysDays > 0 ? (
          <Tooltip
            key={holidayPool.id}
            label={`${
              removeTrailingZeros(
                holidayPool.pendingAcceptCompanyHolidaysDays.toString()
              ) || ''
            } dni urlopu firmowego jest jeszcze niezaakceptowane`}
            placement="right-start"
            fontSize="size6"
          >
            <span>
              <FiInfo
                size={18}
                style={{ margin: '0.3em', position: 'relative' }}
                color="dark"
              />
            </span>
          </Tooltip>
        ) : null}
      </HStack>
      {isForAdmin ? null : <Text>L4: {socialSecurityBenefitsDaysSum}</Text>}
    </Stack>
  );
}
