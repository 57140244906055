import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CenteredSpinner } from '../components/common/CenteredSpinner';
import { HOLIDAY_ACCEPTED_MESSAGE } from '../utils/consts';
import {
  sendHolidayActionRequest,
  useNavigateAfterHolidayAcion,
} from '../utils/holidayActionRequests';
import { acceptHolidayAdminUrl, calendarAdminPath } from '../utils/paths';

export const AcceptHolidayAdmin: FC = () => {
  const { holidayAlterToken } = useParams<{ holidayAlterToken: string }>();
  const navigateAfterHolidayAction = useNavigateAfterHolidayAcion();

  useEffect(() => {
    if (!holidayAlterToken) return;

    let isCancelled = false;
    void sendHolidayActionRequest(
      acceptHolidayAdminUrl,
      holidayAlterToken
    ).then((data) => {
      if (isCancelled) return;

      navigateAfterHolidayAction(
        data,
        HOLIDAY_ACCEPTED_MESSAGE,
        calendarAdminPath
      );

      return;
    });

    return () => {
      isCancelled = true;
    };
  }, [holidayAlterToken, navigateAfterHolidayAction]);

  return <CenteredSpinner />;
};
