import { IconButton, IconButtonProps, Icon } from '@chakra-ui/react';
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';
import { FC } from 'react';

interface Props extends IconButtonProps {
  currentStatsYear: number;
  setCurrentStatsYear: (currentStatsYear: number) => void;
  isForNextYear?: boolean;
}

export const ChangeYearForStatsButton: FC<Props> = ({
  isForNextYear,
  currentStatsYear,
  setCurrentStatsYear,
  ...props
}) => (
  <IconButton
    backgroundColor="transparent"
    fontSize="size12"
    onClick={() =>
      setCurrentStatsYear(
        isForNextYear ? currentStatsYear + 1 : currentStatsYear - 1
      )
    }
    _hover={{ bg: 'transparent' }}
    _focus={{ bg: 'transparent' }}
    {...props}
  >
    <Icon color="main" as={isForNextYear ? MdNavigateNext : MdNavigateBefore} />
  </IconButton>
);
