import { Box, HStack, Text, useDisclosure } from '@chakra-ui/react';
import ReactApexChart from 'react-apexcharts';
import { addDays, addHours, format, lastDayOfMonth } from 'date-fns';
import { ApexOptions } from 'apexcharts';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  selectHolidayUsers,
  selectIsHolidaysLoading,
  selectUsersWithHolidays,
  selectNationalHolidayEvents,
} from '../../../redux/holidays/selectors';
import { selectSocialSecurityBenefits } from '../../../redux/socialSecurityBenefits/selectors';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchHolidayUsersAndSetCurrentHolidays } from '../../../redux/holidays/thunks';
import {
  ALL_HOLIDAYS,
  FULL_MONTH_LABELS,
  SCROLL_OFF_SET_HEIGHT,
} from '../../../utils/consts';
import { Legend } from '../../../components/calendar/Legend';
import { NavigationButtons } from './NavigationButtons';
import { selectCurrentUserId } from '../../../redux/user/selectors';
import { GenerateReport } from '../../../components/calendar/GenerateReport';
import { NationalHolidayApplication } from '../../../components/calendar/PublicHolidaysAdmin/AddNationalHolidayAdmin';
import { CompanyHolidayApplication } from '../../../components/calendar/PublicHolidaysAdmin/AddCompanyHolidayAdmin';
import { fetchPublicHolidays } from '../../../redux/publicHolidays/thunks';
import { fetchAllUsersSocialSecurityBenefits } from '../../../redux/socialSecurityBenefits/thunks';
import { HolidayInfo } from './HolidayInfo';
import { fetchSpecificYearUsersHolidayPool } from '../../../redux/holidayPools/thunks';
import { selectSpecificYearUsersHolidayPools } from '../../../redux/holidayPools/selectors';
import { CustomTooltip, DateRange } from '../../../types/holidayAdmin';
import { xAxisDateFormatter } from '../../../utils/helpers';
import { adminCalendar } from './styles';
import { useApexChartOptions } from './config';
import { CenteredSpinner } from '../../../components/common/CenteredSpinner';
import { useHolidaysSeries } from './hooks/useHolidaysSeries';

export const AdminCalendar = () => {
  const dispatch = useAppDispatch();

  const [[dateStart, dateEnd], setDateRange] = useState<DateRange>([
    null,
    null,
  ]);
  const [clickedNationalAdmin, setClickedNationalAdmin] = useState(false);
  const [clickedCompAdmin, setClickedCompAdmin] = useState(false);
  const [date, setDate] = useState(new Date());
  const [selectedUserId, setSelectedUserId] = useState(ALL_HOLIDAYS);
  const [isScrolled, setIsScrolled] = useState(false);

  const generateReportDisclosure = useDisclosure();
  const addHolidayDisclosure = useDisclosure();

  const isLoading = useAppSelector(selectIsHolidaysLoading);
  const holidays = useAppSelector(selectHolidayUsers);
  const selectedHolidays = useAppSelector((state) =>
    selectUsersWithHolidays(state, selectedUserId)
  );
  const currentUserId = useAppSelector(selectCurrentUserId);
  const socialBenefits = useAppSelector(selectSocialSecurityBenefits);
  const nationalHolidayEvents = useAppSelector((state) =>
    selectNationalHolidayEvents(state, true, currentUserId)
  );
  const specificYearUsersHolidayPool = useAppSelector(
    selectSpecificYearUsersHolidayPools
  );

  const month = useMemo(() => date.getMonth() + 1, [date]);
  const currentYear = useMemo(() => date.getFullYear(), [date]);
  const monthName = useMemo(() => FULL_MONTH_LABELS[date.getMonth()], [date]);

  const selectedMonthStartDay = useMemo(
    () => addHours(new Date(`${month}/01/${date.getFullYear()}`), 1).getTime(),
    [month, date]
  );

  const selectedMonthEndDay = useMemo(
    () =>
      addDays(
        new Date(
          `${month}/${lastDayOfMonth(date).getDate()}/${date.getFullYear()}`
        ),
        1
      ).getTime(),
    [date, month]
  );

  useEffect(() => {
    const handleScroll = () => {
      const wrappedElementBottomHeight = document
        ?.getElementById('root')
        ?.getBoundingClientRect()?.bottom;

      if (!wrappedElementBottomHeight) {
        return;
      }
      wrappedElementBottomHeight + SCROLL_OFF_SET_HEIGHT < window.innerHeight
        ? setIsScrolled(true)
        : setIsScrolled(false);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const onOpenAddHoliday = () => {
    setDateRange([null, null]);
    addHolidayDisclosure.onOpen();
  };

  const onOpenAddNationalAdmin = () => {
    setDateRange([null, null]);
    setClickedNationalAdmin(true);
    setClickedCompAdmin(false);
    addHolidayDisclosure.onOpen();
  };

  const onOpenAddCompAdmin = () => {
    setDateRange([null, null]);
    setClickedNationalAdmin(false);
    setClickedCompAdmin(true);
    addHolidayDisclosure.onOpen();
  };

  const onOpenGenerateReport = () => {
    setDateRange([null, null]);
    generateReportDisclosure.onOpen();
  };

  const tooltipCustom = ({ seriesIndex, dataPointIndex, w }: CustomTooltip) => {
    const data = w.globals?.initialSeries[seriesIndex].data[dataPointIndex];
    const name = w.globals?.initialSeries[seriesIndex].name;
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${data.x} <br/> ${format(
      new Date(data.dateFrom),
      'dd-MM-yyyy'
    )} - ${format(new Date(data?.dateTo as string), 'dd-MM-yyyy')} - ${name}`;
  };

  useEffect(() => {
    void dispatch(fetchAllUsersSocialSecurityBenefits({ isCancelled: false }));
    void dispatch(
      fetchHolidayUsersAndSetCurrentHolidays(
        ALL_HOLIDAYS,
        'last_name,first_name'
      )
    );
    void dispatch(fetchPublicHolidays());
    void dispatch(
      fetchSpecificYearUsersHolidayPool({
        year: currentYear,
        orderingField: 'user__last_name,user__first_name',
      })
    );
  }, [dispatch, currentYear, dateStart, dateEnd]);

  const handleReturnAddHoliday = useCallback(() => {
    const { isOpen, onClose } = addHolidayDisclosure;
    if (!isOpen) {
      return null;
    }

    if (clickedNationalAdmin && !clickedCompAdmin) {
      return <NationalHolidayApplication onClose={onClose} isOpen={isOpen} />;
    } else if (clickedCompAdmin && !clickedNationalAdmin) {
      return (
        <CompanyHolidayApplication
          onClose={onClose}
          isOpen={isOpen}
          holidays={holidays}
          setCurrentDate={setDate}
          currentYear={currentYear}
        />
      );
    }
  }, [
    addHolidayDisclosure,
    clickedCompAdmin,
    clickedNationalAdmin,
    currentYear,
    holidays,
  ]);

  const series = useHolidaysSeries(
    selectedHolidays,
    nationalHolidayEvents,
    socialBenefits,
    month,
    currentUserId
  );
  const options = useApexChartOptions(
    tooltipCustom,
    selectedMonthStartDay,
    selectedMonthEndDay,
    date,
    xAxisDateFormatter,
    isScrolled
  );

  if (isLoading) return <CenteredSpinner />;

  return (
    <HStack gap={8}>
      <HolidayInfo
        setSelectedUserId={setSelectedUserId}
        selectedUserId={selectedUserId}
        currentYear={currentYear}
        specificYearUsersHolidayPool={specificYearUsersHolidayPool}
      />
      <Box alignSelf="flex-start">
        {handleReturnAddHoliday()}
        {generateReportDisclosure.isOpen && (
          <GenerateReport
            onClose={generateReportDisclosure.onClose}
            isOpen={generateReportDisclosure.isOpen}
            holidays={holidays}
          />
        )}
        <NavigationButtons date={date} setDate={setDate}></NavigationButtons>
        <Text sx={adminCalendar.text}>
          {monthName.toLowerCase()} {date.getFullYear()}
        </Text>
        <ReactApexChart
          options={options}
          series={series as ApexOptions['series']}
          height={
            selectedUserId === ALL_HOLIDAYS
              ? `${holidays.length ? holidays.length * 28 : 28}px`
              : 100
          }
          type="rangeBar"
        />
        <Legend
          onOpenAddHoliday={onOpenAddHoliday}
          onOpenAddNationalAdmin={onOpenAddNationalAdmin}
          onOpenAddCompAdmin={onOpenAddCompAdmin}
          onOpenGenerateReport={onOpenGenerateReport}
          isForAdmin
        />
      </Box>
    </HStack>
  );
};
