import { FlexProps, GridProps } from '@chakra-ui/react';

export const filtersGridStyling: GridProps = {
  width: '100%',
  columnGap: '5%',
  gridTemplateColumns: '1fr 1fr',
  paddingBottom: '1rem',
};

export const userProfileListElementGridStyling: GridProps = {
  w: '100%',
  gridTemplateColumns: '1.5fr 1.4fr 0.1fr',
  fontSize: 'size4',
};

const commonListItemElementStyling: FlexProps = {
  alignItems: 'center',
  gap: '8%',
};

export const datesListItemElementStyling: FlexProps = {
  ...commonListItemElementStyling,
  pr: 5,
};

export const typeListItemElementStyling: FlexProps = {
  ...commonListItemElementStyling,
  pr: 4,
};

export const tooltipListItemElementStyling: FlexProps = {
  ...commonListItemElementStyling,
};
