import { IconType } from 'react-icons';
import { FC } from 'react';
import {
  Button,
  Icon,
  Text,
  IconButton,
  useMediaQuery,
  theme,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { colors } from '../../../theme';

interface Props {
  text: string;
  icon: IconType;
  isActive?: boolean;
  pathName: string;
}

export const HeaderButton: FC<Props> = ({
  text,
  icon,
  isActive,
  pathName,
}: Props) => {
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
  if (isMobile) {
    return (
      <IconButton
        aria-label={text}
        variant={isActive ? 'activeHeaderButton' : 'headerButton'}
        as={Link}
        to={pathName}
        borderRadius="100%"
        p="0.8rem"
        w="4.8rem"
        h="4.8rem"
      >
        <Icon
          bg="light"
          color={isActive ? 'main' : 'active'}
          borderRadius="100%"
          p="0.8rem"
          w="4.8rem"
          h="4.8rem"
          as={icon}
        />
      </IconButton>
    );
  } else {
    return (
      <Button
        color={isActive ? 'light' : 'dark'}
        bg={isActive ? 'active' : 'unActive'}
        as={Link}
        to={pathName}
        borderRadius="1.75rem"
        p="1.7rem 3.2rem 1.7rem 5.2rem"
        w="auto"
        maxWidth="450px"
        border="1px solid"
        borderColor="active"
        boxShadow={`0 3px 6px ${colors.shadow}`}
        position="relative"
      >
        <Icon
          position="absolute"
          bg="light"
          borderRadius="100%"
          p="0.8rem"
          left="-20px"
          color="active"
          w="4.8rem"
          h="4.8rem"
          as={icon}
        />
        <Text fontWeight={300}>{text}</Text>
      </Button>
    );
  }
};
