import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import { partiallyUpdateUser } from '../users/thunks';

import { deleteUserContract, editUserContract } from '../contracts/thunks';
import {
  deleteUserHolidayPool,
  editUserHolidayPool,
} from '../holidayPools/thunks';
import {
  addHolidayAsEmployee,
  cancelHolidayAsEmployee,
} from '../holidays/thunks';
import {
  acceptHolidayAsAdmin,
  cancelHolidayAsAdmin,
  cancelHolidayFromCalendarAsAdmin,
  acceptHolidayFromCalendarAsAdmin,
  editHoliday,
} from '../holidaysAdmin/thunks';
import {
  deleteUserSocialSecurityBenefits,
  editUserSocialSecurityBenefits,
} from '../socialSecurityBenefits/thunks';
import { addPublicHolidayAdmin } from '../publicHolidays/thunks';
import { deleteUserDelegation, editUserDelegation } from '../delegation/thunks';

export interface AppState {
  isModalActionLoading: boolean;
}

const initialState: AppState = {
  isModalActionLoading: false,
};

const setLoading = (isLoading: boolean) => (state: AppState) => {
  state.isModalActionLoading = isLoading;
};
const modalActionThunks = [
  partiallyUpdateUser,
  deleteUserContract,
  editUserContract,
  addHolidayAsEmployee,
  cancelHolidayAsEmployee,
  deleteUserHolidayPool,
  editUserHolidayPool,
  editHoliday,
  cancelHolidayAsAdmin,
  acceptHolidayAsAdmin,
  cancelHolidayFromCalendarAsAdmin,
  acceptHolidayFromCalendarAsAdmin,
  deleteUserSocialSecurityBenefits,
  editUserSocialSecurityBenefits,
  addPublicHolidayAdmin,
  deleteUserDelegation,
  editUserDelegation,
] as const;

const appSlice = createSlice({
  name: 'app data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(isPending(...modalActionThunks), setLoading(true))
      .addMatcher(isRejected(...modalActionThunks), setLoading(false))
      .addMatcher(isFulfilled(...modalActionThunks), setLoading(false));
  },
});

export default appSlice.reducer;
