import { Navigate, Route, Routes } from 'react-router-dom';
import { FC } from 'react';
import { Layout } from '../../Layout';
import { UserCalendar } from '../../../pages/User/Calendar';
import { calendarUserPath } from '../../../utils/paths';

export const UserRoutes: FC = () => (
  <Layout>
    <Routes>
      <Route path="/calendar" element={<UserCalendar />} />
      <Route path="*" element={<Navigate to={calendarUserPath} replace />} />
    </Routes>
  </Layout>
);
