import { SelectOption } from './SelectOptions';

export const options: SelectOption[] = [
  { label: 'L4', value: 'l4' },
  { label: 'Opieka nad dzieckiem', value: 'care' },
  { label: 'Macierzyński', value: 'maternal' },
  { label: 'Ojcowski', value: 'paternal' },
  { label: 'Rodzicielski', value: 'parental' },
  { label: 'Wychowawczy', value: 'child_care' },
];
