import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export const selectIsHolidayPoolLoading = (state: RootState) =>
  state.holidayPools.isLoading;
export const selectIsAddHolidayPoolLoading = (state: RootState) =>
  state.holidayPools.isLoadingAdd;
export const selectUserHolidayPools = (state: RootState) =>
  state.holidayPools.userHolidayPools;
export const selectSpecificYearUsersHolidayPools = (state: RootState) =>
  state.holidayPools.usersSpecificYearHolidayPool;

const selectUserHolidayId = (
  state: RootState,
  holidayPoolId: number | undefined
) => holidayPoolId;

export const selectUserHolidayPool = createSelector(
  [selectUserHolidayPools, selectUserHolidayId],
  (holidayPools, holidayPoolId) => {
    return holidayPools.find((holidayPool) => holidayPool.id == holidayPoolId);
  }
);

const selectUserId = (state: RootState, userId: number | undefined) => userId;

export const selectSpecificYearUserHolidayPool = createSelector(
  [selectSpecificYearUsersHolidayPools, selectUserId],
  (holidayPools, userId) => {
    return holidayPools.find(
      (holidayPool) => holidayPool.userData.id == userId
    );
  }
);
