import { IconButton } from '@chakra-ui/react';
import { FC } from 'react';
import { MdSort } from 'react-icons/md';
import {
  SortDescendingIcon,
  SortAscendingIcon,
} from '../../../components/common/Icons';
import { orderingFieldInterface } from '../../../types/thunk';

interface Props {
  modelFieldName: string;
  orderingInfo: orderingFieldInterface;
  handleClick: (modelFieldName: string) => void;
}

export const SortButton: FC<Props> = ({
  modelFieldName,
  orderingInfo,
  handleClick,
}) => (
  <IconButton
    icon={
      modelFieldName === orderingInfo.orderingFieldName ? (
        orderingInfo.desc ? (
          <SortDescendingIcon />
        ) : (
          <SortAscendingIcon />
        )
      ) : (
        <MdSort />
      )
    }
    aria-label={`Sort by ${modelFieldName}`}
    mr="1"
    ml="9"
    color="dark"
    size="xl"
    variant="iconButton"
    onClick={() => handleClick(modelFieldName)}
  />
);
