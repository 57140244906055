import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export const selectIsContractLoading = (state: RootState) =>
  state.contracts.isLoading;
export const selectIsAddContractLoading = (state: RootState) =>
  state.contracts.isLoadingAdd;
export const selectUserContracts = (state: RootState) =>
  state.contracts.userContracts;
export const selectUserContract = (state: RootState) =>
  state.contracts.userContract;

const selectContractId = (state: RootState, contractId: number) => contractId;

export const selectContract = createSelector(
  [selectUserContracts, selectContractId],
  (contracts, contractId) => {
    return contracts.find((contract) => contract.id === contractId);
  }
);
