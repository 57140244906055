import {
  Menu,
  MenuItemOption,
  MenuOptionGroup,
  MenuButton,
  MenuList,
  Button,
} from '@chakra-ui/react';
import { FC } from 'react';
import { FieldError } from 'react-hook-form';
import { MdArrowDropDown } from 'react-icons/md';
import { SelectOption } from '../../../types/SelectOptions';

interface Props {
  options: SelectOption[];
  onMenuOptionGroupChange: (value: string | string[]) => void;
  labelFontSize?: string;
  popperFontSize?: string;
  fontWeight?: number;
  paddingInlineStart?: string;
  currentValue?: string;
  defaultValue?: string;
  error?: FieldError;
}

export const Select: FC<Props> = ({
  error,
  options,
  onMenuOptionGroupChange,
  labelFontSize,
  popperFontSize,
  fontWeight,
  paddingInlineStart,
  currentValue,
  defaultValue,
}) => (
  <Menu matchWidth={true}>
    <MenuButton
      aria-invalid={error ? true : false}
      name="menu-button"
      borderBottom="1px solid"
      backgroundColor="light"
      fontSize={labelFontSize}
      fontWeight={fontWeight}
      textAlign="left"
      height="auto"
      width="100%"
      rightIcon={<MdArrowDropDown />}
      borderRadius="none"
      textTransform="none"
      paddingInlineStart={paddingInlineStart}
      paddingInlineEnd="none"
      as={Button}
    >
      {options.find((option) => option.value === currentValue)?.label}
    </MenuButton>
    <MenuList>
      <MenuOptionGroup
        onChange={onMenuOptionGroupChange}
        defaultValue={defaultValue}
      >
        {options.map(({ value, label }) => (
          <MenuItemOption
            key={label}
            value={value}
            border="none"
            fontSize={popperFontSize}
            fontWeight={fontWeight}
          >
            {label}
          </MenuItemOption>
        ))}
      </MenuOptionGroup>
    </MenuList>
  </Menu>
);
