import { VStack, GridItem, Button, Grid, Box } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  DateIcon,
  HolidayInfoIcon,
} from '../../../../../components/common/Icons';
import { DatePicker } from '../../../../../components/form/inputs/DatePicker';
import {
  AddUserDelegationForm,
  EditUserDelegationForm,
} from '../../../../../types/forms';
import { TextInput } from '../../../../../components/form/inputs/TextInput';
import { requiredValidation } from '../../../../../utils/validation';
import { getYear10YearsAgo } from '../../../../../utils/date';
import { selectIsDelegationLoading } from '../../../../../redux/delegation/selectors';
import { useAppSelector } from '../../../../../store';

interface Props {
  isEditing?: boolean;
  onOpen?: () => void;
}
export const FormDelegation: FC<Props> = ({ isEditing }) => {
  const { control, watch, setValue } = useFormContext<
    AddUserDelegationForm | EditUserDelegationForm
  >();

  const dateFromWatcher = useWatch({
    name: 'dateFrom',
    control,
  });

  useEffect(() => {
    const subscription = watch((value, { type, name }) => {
      if (
        name === 'dateFrom' &&
        type === 'change' &&
        value.dateFrom != null &&
        value.dateTo != null &&
        value.dateFrom > value.dateTo
      )
        setValue('dateTo', value.dateFrom);
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const isLoading = useAppSelector(selectIsDelegationLoading);

  return (
    <VStack
      alignItems="flex-start"
      width={isEditing ? undefined : ['100%', null, 'sm']}
    >
      <Grid
        paddingLeft={isEditing ? undefined : 4}
        alignItems={isEditing ? 'center' : 'inherit'}
        borderLeft={isEditing ? undefined : '1px solid'}
        borderColor={isEditing ? undefined : 'active'}
        templateColumns="0.1fr 1fr 1fr"
        columnGap={3}
        rowGap={isEditing ? 8 : 4}
        width={isEditing ? undefined : ['100%', null, 'sm']}
      >
        <GridItem color="active">
          <DateIcon />
        </GridItem>
        <DatePicker
          label="data od"
          name="dateFrom"
          minDate={getYear10YearsAgo()}
          registerProps={requiredValidation}
          isStandalone
        />
        <DatePicker
          label="data do"
          name="dateTo"
          minDate={dateFromWatcher}
          isStandalone
        />
        <GridItem color="active">
          <HolidayInfoIcon />
        </GridItem>
        <GridItem colSpan={2}>
          <TextInput name="addInfo" isStandalone label="dodatkowe uwagi" />
        </GridItem>
      </Grid>
      <Box paddingTop={2} alignSelf={isEditing ? 'center' : 'flex-end'}>
        <Button isDisabled={isLoading} type="submit" variant="submitForm">
          Zapisz
        </Button>
      </Box>
    </VStack>
  );
};
