import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { FormWrapper } from '../../../../../components/form/FormWrapper';
import { selectContract } from '../../../../../redux/contracts/selectors';
import { editUserContract } from '../../../../../redux/contracts/thunks';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { EditUserContractForm } from '../../../../../types/forms';
import { FormContract } from './FormContract';
import { getDirtyValues } from '../../../../../utils/hookForm';

interface Props {
  onClose: () => void;
  contractId: number;
}
export const EditContract: FC<Props> = ({ contractId, onClose }) => {
  const dispatch = useAppDispatch();
  const result = useAppSelector((state) => selectContract(state, contractId));

  const methods = useForm<EditUserContractForm>({
    defaultValues: {
      id: contractId,
      dateTo: result?.dateTo ? new Date(result.dateTo) : null,
      dateFrom: result?.dateFrom ? new Date(result.dateFrom) : null,
      type: result?.type,
      holidayPool: result?.holidayPool,
    },
  });
  const dirtyFields = methods.formState.dirtyFields;

  const handleSubmit = async (data: EditUserContractForm) => {
    if (!contractId) return;

    dirtyFields.dateFrom = true;
    dirtyFields.dateTo = true;

    const changeset = getDirtyValues(dirtyFields, data);

    try {
      await dispatch(
        editUserContract({ ...changeset, id: contractId })
      ).unwrap();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal isOpen onClose={onClose} size="xl" autoFocus={false}>
      <ModalOverlay backdropFilter="blur(30px)" />
      <ModalContent px={['12', '24']} py={['8', '16']} justifyContent="center">
        <ModalHeader textAlign="center">
          <Heading as="h1" m={4} pb={[0, 10]} size="lg" fontWeight={300}>
            EDYTUJ UMOWĘ
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormWrapper methods={methods} onSubmit={handleSubmit}>
            <FormContract isEditing={true} />
          </FormWrapper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
