import { ThunkError } from '../types/thunk';
import { displayErrorToast } from './toasts';

export enum ErrorCode {
  InvalidToken = 'invalid_token',
  NotAuthenticated = 'not_authenticated',
  TokenUsed = 'token_used',
  TokenMismatch = 'token_mismatch',
  // Field Validations
  HolidayOverlap = 'overlapping holiday exists',
  PublicHolidayOverlap = 'overlapping public holiday exist',
  DelegationOverlap = 'overlapping delegation exists',
  BenefitsOverlap = 'overlapping benefit exists',
  DateFromExceededDateTo = 'date_from exceeded date_to',
  OneHolidayPoolPerYear = 'each user can only have one holiday pool per each year',
}

const toastErrors = {
  [ErrorCode.DateFromExceededDateTo]:
    'Data początkowa urlopu nie może być większa od daty końcowej',
  [ErrorCode.HolidayOverlap]:
    'Urlopy kolidują ze sobą, w razie problemów skontaktuj się z działem People',
  [ErrorCode.PublicHolidayOverlap]:
    'Urlop publiczny został już dodany w tym terminie',
  [ErrorCode.DelegationOverlap]: 'Delegacje kolidują ze sobą, zweryfikuj daty',
  [ErrorCode.BenefitsOverlap]:
    'Świadczenia ZUS kolidują ze sobą, zweryfikuj daty',
  [ErrorCode.InvalidToken]: 'Token jest nieprawidłowy',
  [ErrorCode.TokenUsed]: 'Token został już użyty',
  [ErrorCode.TokenMismatch]: 'Token nie istnieje',
  [ErrorCode.OneHolidayPoolPerYear]:
    'Użytkownik może mieć tylko jedną pulę urlopów na rok',
  generalError: 'Wystąpił błąd',
};

export const handleError = (error: ThunkError) => {
  const errorCode =
    error?.response?.data?.details?.message?.[0] ?? error?.response?.data?.code;

  switch (errorCode) {
    case ErrorCode.HolidayOverlap:
    case ErrorCode.PublicHolidayOverlap:
    case ErrorCode.DelegationOverlap:
    case ErrorCode.BenefitsOverlap:
    case ErrorCode.DateFromExceededDateTo:
    case ErrorCode.InvalidToken:
    case ErrorCode.TokenUsed:
    case ErrorCode.TokenMismatch:
    case ErrorCode.OneHolidayPoolPerYear:
      displayErrorToast({ title: toastErrors[errorCode] });
      break;
    default:
      displayErrorToast({ title: toastErrors.generalError });
      break;
  }

  console.error(error);
};

export const getHolidayActionErrorVerboseMessage = (error: ThunkError) => {
  const errorCode =
    error?.response?.data?.details?.message?.[0] ?? error?.response?.data?.code;

  switch (errorCode) {
    case ErrorCode.InvalidToken:
    case ErrorCode.TokenUsed:
    case ErrorCode.TokenMismatch:
      return toastErrors[errorCode];
    default:
      return toastErrors.generalError;
  }
};
