import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { FormWrapper } from '../../form/FormWrapper';
import { useForm, useWatch } from 'react-hook-form';
import { TextInput } from '../../form/inputs/TextInput';
import { DatePicker } from '../../form/inputs/DatePicker';
import { useAppDispatch, useAppSelector } from '../../../store';
import { HolidayAddModelForm } from '../../../redux/holidays';
import {
  addHolidayAsEmployee,
  fetchHolidayUsersAndSetCurrentHolidays,
} from '../../../redux/holidays/thunks';
import { requiredValidation } from '../../../utils/validation';
import { selectCurrentUser } from '../../../redux/user/selectors';
import { FormSelect } from '../../form/inputs/Select';
import { options, subTypeOptions } from '../../../types/holidayTypes';
import { formatDate, getYear10YearsAgo } from '../../../utils/date';
import { fetchSpecificYearUsersHolidayPool } from '../../../redux/holidayPools/thunks';
import { CheckboxInput } from '../../form/inputs/CheckboxInput';
import { HolidayType } from '../../../redux/holidays';
import { fetchUserSocialSecurityBenefits } from '../../../redux/socialSecurityBenefits/thunks';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  dateFrom: Date | null;
  dateTo: Date | null;
  year: number;
  setCurrentDate: Dispatch<SetStateAction<Date>>;
}

export const HolidayApplication: FC<Props> = ({
  onClose,
  isOpen,
  dateFrom,
  dateTo,
  year,
  setCurrentDate,
}) => {
  const user = useAppSelector(selectCurrentUser);

  const methods = useForm<HolidayAddModelForm>({
    defaultValues: {
      user: user?.id,
      isHalfDay: false,
      dateFrom,
      dateTo,
    },
  });
  const { watch, setValue, control } = methods;

  const dispatch = useAppDispatch();

  const submitHandler = async (data: HolidayAddModelForm) => {
    if (!user) return;

    try {
      if (!data.compassionateSubType) {
        await dispatch(addHolidayAsEmployee(data)).unwrap();
      } else if (!data.addInfo && data.compassionateSubType) {
        await dispatch(
          addHolidayAsEmployee({
            ...data,
            addInfo: data.compassionateSubType,
          })
        ).unwrap();
      } else if (data.addInfo && data.compassionateSubType) {
        await dispatch(
          addHolidayAsEmployee({
            ...data,
            addInfo: `${data.compassionateSubType} - ${data.addInfo}`,
          })
        ).unwrap();
      }

      void dispatch(
        fetchHolidayUsersAndSetCurrentHolidays(user.id, 'first_name,last_name')
      );

      void dispatch(
        fetchUserSocialSecurityBenefits({ id: user.id, isCancelled: false })
      );

      if (data.dateFrom) setCurrentDate(data.dateFrom);

      onClose();

      await dispatch(
        fetchSpecificYearUsersHolidayPool({
          year: year,
          orderingField: 'user__first_name,user__last_name',
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const dateFromWatcher = useWatch({ name: 'dateFrom', control });
  const isCheckedWatcher = useWatch({ name: 'isHalfDay', control });
  const typeWatcher = useWatch({ name: 'type', control });

  const [compassionateTypeSelected, setCompassionateTypeSelected] =
    useState(false);

  useEffect(() => {
    const subscription = watch((value, { type, name }) => {
      if (
        (name === 'dateFrom' &&
          type === 'change' &&
          value.dateFrom != null &&
          value.dateTo != null &&
          value.dateFrom > value.dateTo &&
          value.isHalfDay === false) ||
        (name === 'isHalfDay' &&
          type === 'change' &&
          value.dateFrom &&
          value.isHalfDay === true) ||
        (name === 'dateFrom' &&
          type === 'change' &&
          value.dateFrom &&
          value.isHalfDay === true)
      )
        setValue('dateTo', value.dateFrom);
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  useEffect(() => {
    if (typeWatcher == HolidayType.Compassionate) {
      setCompassionateTypeSelected(true);
    } else {
      setCompassionateTypeSelected(false);
    }
  }, [setCompassionateTypeSelected, typeWatcher]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      autoFocus={false}
      returnFocusOnClose={false}
    >
      <ModalOverlay backdropFilter="blur(30px)" />
      <ModalContent>
        <ModalHeader>
          <HStack justifyContent="space-between">
            <Text fontWeight="light" fontSize="size7" variant="secondary">
              {user?.firstName} {user?.lastName}
            </Text>
            <Text
              fontWeight="light"
              fontSize="size7"
              align="end"
              variant="secondary"
            >
              {formatDate(new Date())}
            </Text>
          </HStack>
        </ModalHeader>
        <Text
          align="center"
          pr={18}
          pl={18}
          fontSize="size13"
          pt={20}
          pb={20}
          fontFamily="Roboto"
        >
          WNIOSEK URLOPOWY
        </Text>
        <ModalBody pl="135px" pr="135px">
          <FormWrapper onSubmit={submitHandler} methods={methods}>
            <Grid gridTemplateColumns="1fr 1fr 1fr" columnGap={6}>
              <GridItem colSpan={3}>
                <FormSelect
                  name="type"
                  label="rodzaj urlopu"
                  options={options}
                  registerProps={requiredValidation}
                ></FormSelect>
              </GridItem>
              {compassionateTypeSelected ? (
                <GridItem colSpan={3} marginBottom={6}>
                  <FormSelect
                    name="compassionateSubType"
                    isStandalone
                    label="powód"
                    registerProps={requiredValidation}
                    options={subTypeOptions}
                  />
                </GridItem>
              ) : undefined}
              <DatePicker
                label="od"
                name="dateFrom"
                minDate={getYear10YearsAgo()}
                registerProps={requiredValidation}
              />
              <DatePicker
                label="do"
                name="dateTo"
                minDate={dateFromWatcher}
                registerProps={requiredValidation}
                disabled={isCheckedWatcher}
              />
              <GridItem maxHeight={12}>
                <CheckboxInput name="isHalfDay" label="0,5 dnia" />
              </GridItem>
              <GridItem colSpan={3}>
                <TextInput name="addInfo" label="uwagi" />
              </GridItem>
            </Grid>
            <VStack pt={3} pb={10}>
              <Button variant="main" size="md" height="44px" type="submit">
                WYŚLIJ
              </Button>
              <Button
                variant="inverted"
                size="md"
                height="44px"
                onClick={onClose}
              >
                Cofnij
              </Button>
            </VStack>
          </FormWrapper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
