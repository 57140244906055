const PHONE_NUMBER_REGEX = /^\d{9}$/g;

export const requiredValidation = {
  required: 'Pole jest wymagane',
} as const;

export const requiredAndPhoneNumberValidation = {
  ...requiredValidation,
  pattern: {
    value: PHONE_NUMBER_REGEX,
    message: 'Nieprawidłowy numer telefonu',
  },
} as const;

export const defaultValidation = {
  required: false,
} as const;

export const phoneNumberValidation = {
  ...defaultValidation,
  pattern: {
    value: PHONE_NUMBER_REGEX,
    message: 'Nieprawidłowy numer telefonu',
  },
} as const;
