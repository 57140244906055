import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { selectIsAddDelegationLoading } from '../../../../../redux/delegation/selectors';
import { addUserDelegation } from '../../../../../redux/delegation/thunks';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { FormWrapper } from '../../../../../components/form/FormWrapper';
import { AddUserDelegationForm } from '../../../../../types/forms';
import { FormDelegation } from './FormDelegation';
import { CenteredSpinner } from '../../../../../components/common/CenteredSpinner';

interface Props {
  userId: number;
}
export const AddDelegation: FC<Props> = ({ userId }) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsAddDelegationLoading);

  const methods = useForm<AddUserDelegationForm>({
    defaultValues: {
      user: userId,
    },
  });

  const handleSubmit = async (data: AddUserDelegationForm) => {
    methods.reset({
      user: userId,
      dateTo: undefined,
      dateFrom: undefined,
      addInfo: undefined,
    });
    try {
      await dispatch(addUserDelegation(data)).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  return isLoading ? (
    <CenteredSpinner />
  ) : (
    <FormWrapper methods={methods} onSubmit={handleSubmit}>
      <FormDelegation />
    </FormWrapper>
  );
};
