import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import user from './redux/user';
import contracts from './redux/contracts';
import thunkMiddleware from 'redux-thunk';
import users from './redux/users';
import holidays from './redux/holidays';
import publicHolidays from './redux/publicHolidays';
import app from './redux/app';
import holidayPools from './redux/holidayPools';
import socialSecurityBenefits from './redux/socialSecurityBenefits';
import delegation from './redux/delegation';
import statistics from './redux/statistics';

const rootReducer = combineReducers({
  user,
  users,
  holidays,
  publicHolidays,
  app,
  contracts,
  holidayPools,
  socialSecurityBenefits,
  delegation,
  statistics,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(thunkMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
