export const removeTrailingZeros = (numericString?: string) => {
  if (numericString === undefined) return;

  const numberFromString = Number(numericString);
  const hasOnlyZerosAfterDecimalPoint = numberFromString % 1 === 0;

  return hasOnlyZerosAfterDecimalPoint
    ? numberFromString.toFixed(0)
    : numericString;
};
