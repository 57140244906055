import { Input } from '@chakra-ui/react';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { DefaultInputProps, InputWrapper } from '../InputWrapper';
import { defaultValidation } from '../../../utils/validation';

export const TextInput: FC<DefaultInputProps> = ({
  name,
  registerProps = defaultValidation,
  ...rest
}) => {
  const { register } = useFormContext();
  return (
    <InputWrapper name={name} {...rest}>
      <Input placeholder=" " type="text" {...register(name, registerProps)} />
    </InputWrapper>
  );
};
