import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserDelegation } from '.';
import client from '../../api/axiosConfig';
import {
  DeleteUserDelegation,
  AddUserDelegationForm,
  EditUserDelegationForm,
} from '../../types/forms';
import { ThunkError, EditUserDelegationThunk } from '../../types/thunk';
import { displaySuccessToast } from '../../utils/toasts';
import { handleError } from '../../utils/handleError';
import { formatToBackendDate } from '../../utils/date';

export const addUserDelegation = createAsyncThunk<
  UserDelegation | undefined,
  AddUserDelegationForm
>(
  'delegationId/addDelegation',

  async ({ user, dateFrom, dateTo, addInfo }, { rejectWithValue }) => {
    if (!dateFrom || !dateTo) return; // required in validation
    try {
      const response = await client.post<{ results: UserDelegation }>(
        `people/delegation/`,
        {
          user,
          dateFrom: formatToBackendDate(dateFrom),
          dateTo: formatToBackendDate(dateTo),
          addInfo: addInfo === '' ? null : addInfo,
        }
      );
      if (response.data.results)
        displaySuccessToast({ title: 'Dodano pomyślnie' });
      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

export const editUserDelegation = createAsyncThunk<
  EditUserDelegationThunk | undefined,
  EditUserDelegationForm
>(
  'delegationId/editDelegation',
  async ({ id, dateTo, dateFrom, addInfo, user }, { rejectWithValue }) => {
    const formattedDateFrom = dateFrom
      ? formatToBackendDate(dateFrom)
      : undefined;
    const formattedDateTo = dateTo ? formatToBackendDate(dateTo) : undefined;

    try {
      const response = await client.patch<{ results: EditUserDelegationThunk }>(
        `people/delegation/${id}/`,
        {
          dateFrom: formattedDateFrom,
          dateTo: formattedDateTo,
          addInfo,
          user,
        }
      );
      if (response.data.results)
        displaySuccessToast({ title: 'Edytowano pomyślnie' });
      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

export const fetchUserDelegation = createAsyncThunk<
  UserDelegation[] | undefined,
  number
>('delegationId/fetch', async (id, { rejectWithValue }) => {
  try {
    const response = await client.get<{ results: UserDelegation[] }>(
      `people/delegation/?user=${id}`
    );
    return response.data.results;
  } catch (error) {
    handleError(error as ThunkError);
    return rejectWithValue((error as ThunkError)?.response?.data);
  }
});

export const deleteUserDelegation = createAsyncThunk<
  DeleteUserDelegation | undefined,
  number
>('delegationId/delete', async (id, { rejectWithValue }) => {
  try {
    const response = await client.delete<{ results: undefined }>(
      `people/delegation/${id}/`
    );
    return { results: response.data.results, id };
  } catch (error) {
    handleError(error as ThunkError);
    return rejectWithValue((error as ThunkError)?.response?.data);
  }
});
