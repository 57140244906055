import { Checkbox } from '@chakra-ui/react';
import { ChangeEvent, FC } from 'react';
import {
  Controller,
  ControllerFieldState,
  FieldValues,
  Noop,
  RefCallBack,
  useFormContext,
  UseFormStateReturn,
} from 'react-hook-form';
import { InputWrapper, DefaultInputProps } from '../InputWrapper';

interface RenderProps {
  field: {
    onChange?: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined;
    name: string;
    value?: boolean;
    onBlur: Noop;
    ref: RefCallBack;
  };
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
}

export const CheckboxInput: FC<DefaultInputProps> = ({
  name,
  label,
  ...rest
}) => {
  const { control } = useFormContext();

  return (
    <InputWrapper variant="" name={name} {...rest}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, onBlur } }: RenderProps) => (
          <Checkbox
            isDisabled={false}
            isChecked={value}
            name={name}
            onBlur={onBlur}
            mb={10}
            borderColor="darkgray"
            onChange={onChange}
            {...rest}
          >
            {label}
          </Checkbox>
        )}
      />
    </InputWrapper>
  );
};
