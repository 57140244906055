import { EventInput } from '@fullcalendar/react';
import { useMemo } from 'react';
import { UserWithHolidaysModel } from '../../../../redux/holidays';
import { SocialSecurityBenefitsWithUserModel } from '../../../../redux/socialSecurityBenefits';
import { currentEventsMapper } from '../utlis';

export const useSetHolidays = (
  data: UserWithHolidaysModel[],
  nationalHolidayEvents: EventInput[],
  socialBenefits: SocialSecurityBenefitsWithUserModel[]
) =>
  useMemo(
    () =>
      data.map((holiday) => {
        return [
          ...currentEventsMapper(nationalHolidayEvents, holiday),
          ...holiday.holidaySet,
          ...socialBenefits.filter(
            (socialBenefit) => socialBenefit.user === Number(holiday.id)
          ),
        ];
      }),
    [data, socialBenefits, nationalHolidayEvents]
  );
