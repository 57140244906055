import { createSlice } from '@reduxjs/toolkit';
import {
  deleteUserDelegation,
  fetchUserDelegation,
  addUserDelegation,
  editUserDelegation,
} from './thunks';

export interface UserDelegation {
  id: number;
  dateFrom: string;
  dateTo: string;
  addInfo: string | null;
}

export interface DelegationState {
  isLoading: boolean;
  isLoadingAdd: boolean;
  isLoadingDeleteDelegation: boolean;
  userDelegations: UserDelegation[];
}

const initialState: DelegationState = {
  isLoading: false,
  isLoadingAdd: false,
  isLoadingDeleteDelegation: false,
  userDelegations: [],
};

const delegationSlice = createSlice({
  name: 'delegation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDelegation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserDelegation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userDelegations = action?.payload ?? [];
      })
      .addCase(fetchUserDelegation.rejected, (state) => {
        state.isLoading = false;
        state.userDelegations = [];
      })
      .addCase(addUserDelegation.pending, (state) => {
        state.isLoadingAdd = true;
      })
      .addCase(addUserDelegation.fulfilled, (state, { payload }) => {
        state.isLoadingAdd = false;

        if (state.userDelegations && payload) {
          state.userDelegations = [...state.userDelegations, payload].sort(
            (userDelegations, payload) => {
              return payload.dateFrom.localeCompare(userDelegations.dateFrom);
            }
          );
        }
      })
      .addCase(addUserDelegation.rejected, (state) => {
        state.isLoadingAdd = false;
      })
      .addCase(editUserDelegation.fulfilled, (state, { payload }) => {
        if (!payload) return;

        const { id, ...restOfItem } = payload;
        state.userDelegations = state.userDelegations?.map((item) =>
          item.id === id ? { ...item, ...restOfItem } : item
        );
      })
      .addCase(editUserDelegation.rejected, (state) => {
        state.userDelegations = [];
      })
      .addCase(deleteUserDelegation.fulfilled, (state, { payload }) => {
        state.userDelegations = state.userDelegations.filter(
          (userDelegation) => userDelegation.id !== payload?.id
        );
      });
  },
});
export default delegationSlice.reducer;
