import { SimpleGrid, GridItem, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { HolidayLegend } from './HolidayLegend';
import { LegendButton } from './legendButton';

interface Props {
  onOpenAddHoliday: () => void;
  onOpenAddNationalAdmin: () => void;
  onOpenGenerateReport: () => void;
  onOpenAddCompAdmin: () => void;
  isForAdmin: boolean;
}
export const Legend: FC<Props> = ({
  onOpenAddHoliday,
  onOpenAddNationalAdmin,
  onOpenGenerateReport,
  onOpenAddCompAdmin,
  isForAdmin,
}) => {
  return (
    <SimpleGrid
      gridTemplateColumns={['minmax(0, 1fr)', null, null, '10fr 1fr']}
      pl={isForAdmin ? '12rem' : '0'}
      pr={isForAdmin ? '1rem' : '0'}
      pt={isForAdmin ? 2 : 8}
    >
      <GridItem>
        <HolidayLegend />
      </GridItem>
      {isForAdmin ? (
        <GridItem textAlign="right">
          <VStack>
            <LegendButton
              onClick={onOpenAddNationalAdmin}
              label="DODAJ ŚWIĘTO"
            />
            <LegendButton
              onClick={onOpenAddCompAdmin}
              label="DODAJ URLOP FIRMOWY"
            />
            <LegendButton
              onClick={onOpenGenerateReport}
              label="EKSPORTUJ DO CSV"
            />
          </VStack>
        </GridItem>
      ) : (
        <GridItem textAlign="right">
          <LegendButton onClick={onOpenAddHoliday} label="ZŁÓŻ WNIOSEK" />
        </GridItem>
      )}
    </SimpleGrid>
  );
};
