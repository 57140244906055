import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { cancelHolidayAsAdmin } from '../../../../../redux/holidaysAdmin/thunks';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { selectHolidayById } from '../../../../../redux/holidays/selectors';
import { fetchUserHolidayPools } from '../../../../../redux/holidayPools/thunks';

interface Props {
  onClose: () => void;
  holidayId: number;
  userId: number;
}

export const DeleteHoliday: FC<Props> = ({ holidayId, onClose, userId }) => {
  const dispatch = useAppDispatch();
  const holiday = useAppSelector((state) =>
    selectHolidayById(state, holidayId)
  );

  const onSubmit = async () => {
    if (!holiday) return;

    try {
      await dispatch(
        cancelHolidayAsAdmin({
          id: holiday.id,
        })
      ).unwrap();
      onClose();
      await dispatch(fetchUserHolidayPools(userId));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay backdropFilter="blur(30px)" />
      <ModalContent px={12} py={28} maxW="680px">
        <ModalBody justifyContent="center">
          <Text textAlign="center" fontSize="size13" pb={8}>
            CZY NA PEWNO CHCESZ USUNĄĆ?
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="center" gap={16}>
          <Button variant="main" onClick={onSubmit}>
            TAK
          </Button>
          <Button variant="main" onClick={onClose}>
            NIE
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
