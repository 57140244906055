import { Box } from '@chakra-ui/react';
import { colors, gradients } from '../../../theme';

export const LayoutBackground = () => (
  <Box
    position="absolute"
    height="17.5rem"
    width="100%"
    bgImage={gradients.main}
    boxShadow={`-10px 10px 6px ${colors.shadow};`}
    zIndex={-1}
  />
);
