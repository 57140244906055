import { HolidayType } from '../../../../../redux/holidays';
import { FULL_MONTH_LABELS } from '../../../../../utils/consts';

export const holidayNames = {
  [HolidayType.Compassionate]: 'urlop okolicznościowy',
  [HolidayType.Care]: 'urlop opiekuńczy',
  [HolidayType.Unpaid]: 'urlop bezpłatny',
  [HolidayType.Leisure]: 'urlop wypoczynkowy',
  [HolidayType.OnDemand]: 'urlop na żądanie',
  [HolidayType.HolidayComp]: 'urlop za święto',
  [HolidayType.Company]: 'urlop firmowy',
  [HolidayType.DelegationComp]: 'urlop za delegację',
  [HolidayType.NationalHoliday]: 'urlop za święto narodowe',
} as const;

export const booleanOptions = [
  { label: 'Tak', value: true },
  { label: 'Nie', value: false },
];

export const monthSelectOptions = FULL_MONTH_LABELS.map(
  (monthLabel, index) => ({
    label: monthLabel,
    value: index,
  })
);
