import { Container, Spacer, VStack } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { Footer } from './Footer';
import { Header } from './Header';
import { NavigationButtons } from './Header/NavigationButtons';
import { ContainerSizes } from '../../types/theme';
import { colors, gradients, sizes } from '../../theme';

interface Props extends PropsWithChildren {
  isFullBackground?: boolean;
  maxW?: ContainerSizes;
  isAdminView?: boolean;
}

export const Layout: FC<Props> = ({
  children,
  isFullBackground,
  isAdminView,
  maxW = sizes.defaultMainContainer,
}) => (
  <VStack
    minH="100%"
    alignItems="stretch"
    bgImage={isFullBackground ? gradients.main : undefined}
  >
    <Header isFullBackground={isFullBackground} />
    <VStack as="main" py={4} px={['2rem', '3rem', '4rem', null, '5rem']}>
      {isAdminView ? <NavigationButtons /> : <Spacer p={8} />}
      <Container
        boxShadow={`0px 12px 12px 12px ${colors.shadow}, -10px 10px 6px ${colors.shadow}`}
        maxW={maxW}
        p={9}
        background="light"
        borderRadius="12px"
      >
        {children}
      </Container>
    </VStack>
    <Spacer />
    <Footer />
  </VStack>
);
