import { HStack, Heading } from '@chakra-ui/react';
import { ChangeYearForStatsButton } from './changeYearForStatsButton';
import { FC } from 'react';

interface Props {
  currentStatsYear: number;
  setCurrentStatsYear: (currentStatsYear: number) => void;
}

export const StatsYearButtons: FC<Props> = ({
  currentStatsYear,
  setCurrentStatsYear,
}) => (
  <HStack justifyContent="center" width="100%">
    <ChangeYearForStatsButton
      aria-label="prev-year"
      currentStatsYear={currentStatsYear}
      setCurrentStatsYear={setCurrentStatsYear}
    />
    <Heading fontSize="size10" fontWeight="300">
      {currentStatsYear}
    </Heading>
    <ChangeYearForStatsButton
      aria-label="next-year"
      isForNextYear={true}
      currentStatsYear={currentStatsYear}
      setCurrentStatsYear={setCurrentStatsYear}
    />
  </HStack>
);
