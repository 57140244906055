import { addHours, startOfDay, endOfDay } from 'date-fns';
import { useMemo } from 'react';
import { HolidayWithUserModel } from '../../../../redux/holidays';
import { SocialSecurityBenefitsWithUserModel } from '../../../../redux/socialSecurityBenefits';
import { HolidayDataMap } from '../../../../types/holidayAdmin';
import {
  NUMBER_OF_HOURS_CHART_SHIFTED_DATE_FROM,
  MID_DAY_HOUR,
  NUMBER_OF_HOURS_CHART_SHIFTED_DATE_TO,
} from '../../../../utils/consts';

export const useMappedHolidays = (
  data: (
    | SocialSecurityBenefitsWithUserModel
    | HolidayDataMap
    | HolidayWithUserModel
  )[][],
  month: number
) =>
  useMemo(
    () =>
      data.map((holiday) => {
        return Object.values(holiday).map(
          ({
            firstName,
            lastName,
            isHalfDay,
            dateFrom,
            dateTo,
            type,
            addInfo,
            id,
            isAcceptedByAdmin,
            isAcceptedByEmployee,
            user,
          }) => {
            const parsedDateTo = new Date(dateTo);
            const parsedDateFrom = new Date(dateFrom);

            return {
              userName: `${lastName} ${firstName}`,
              datePeriod:
                parsedDateTo.getMonth() + 1 === month ||
                parsedDateFrom.getMonth() + 1 === month
                  ? isHalfDay
                    ? [
                        addHours(
                          startOfDay(parsedDateFrom),
                          NUMBER_OF_HOURS_CHART_SHIFTED_DATE_FROM
                        ).getTime(),
                        addHours(parsedDateTo, MID_DAY_HOUR).getTime(),
                      ]
                    : dateFrom === dateTo
                    ? [
                        addHours(
                          startOfDay(parsedDateFrom),
                          NUMBER_OF_HOURS_CHART_SHIFTED_DATE_FROM
                        ).getTime(),
                        addHours(
                          endOfDay(parsedDateTo),
                          NUMBER_OF_HOURS_CHART_SHIFTED_DATE_TO
                        ).getTime(),
                      ]
                    : [
                        addHours(
                          startOfDay(parsedDateFrom),
                          NUMBER_OF_HOURS_CHART_SHIFTED_DATE_FROM
                        ).getTime(),
                        addHours(
                          endOfDay(parsedDateTo),
                          NUMBER_OF_HOURS_CHART_SHIFTED_DATE_TO
                        ).getTime(),
                      ]
                  : [],
              type,
              addInfo,
              id,
              isAcceptedByAdmin,
              isAcceptedByEmployee,
              dateFrom,
              dateTo,
              userId: Number(user),
            };
          }
        );
      }),
    [data, month]
  );
