import { Circle, Flex, Grid, Text } from '@chakra-ui/react';
import { data } from './data';

export const HolidayLegend = () => (
  <Grid
    gridTemplateColumns={[
      ' minmax(0, 1fr)',
      ' minmax(0, 1fr)  minmax(0, 1fr)',
      null,
      null,
      'auto auto auto',
    ]}
    gridTemplateAreas={[
      `'unaccepted-mine' 'accepted-mine' 'unaccepted-others' 'accepted-others' 'statutorily-free' 'company' 'l4'`,
      `
        'unaccepted-mine   accepted-mine  '
        'unaccepted-others accepted-others'
        'statutorily-free  company        '
        'l4                l4             '
      `,
      null,
      null,
      `
        'unaccepted-mine unaccepted-others statutorily-free'
        'accepted-mine   accepted-others   company         '
        'l4              l4                l4              '
      `,
    ]}
    gap={2}
  >
    {data.map(({ id, color, text, gridArea }) => (
      <Flex key={id} pb={2} gridArea={gridArea}>
        <Circle size={5} bg={color} />
        <Text fontSize="size2" pl={4} textAlign="left">
          {text}
        </Text>
      </Flex>
    ))}
  </Grid>
);
