export const data = [
  {
    id: 0,
    gridArea: 'unaccepted-mine',
    text: 'URLOP NIEZATWIERDZONY',
    color: '#D9DEFE',
  },
  {
    id: 1,
    gridArea: 'unaccepted-others',
    text: 'NIEZAAKCEPTOWANY URLOP INNYCH',
    color: '#CDF1FC',
  },
  {
    id: 2,
    gridArea: 'statutorily-free',
    text: 'DZIEŃ USTAWOWO WOLNY',
    color: '#B2F4D5',
  },
  {
    id: 3,
    gridArea: 'accepted-mine',
    text: 'URLOP ZATWIERDZONY',
    color: '#6F81FF',
  },
  {
    id: 4,
    gridArea: 'accepted-others',
    text: 'ZAAKCEPTOWANY URLOP INNYCH',
    color: '#5CC9FA',
  },
  { id: 5, gridArea: 'company', text: 'URLOP FIRMOWY', color: '#FEC8C8' },
  { id: 6, gridArea: 'l4', text: 'L4 I INNE', color: '#B8B8BD' },
] as const;
