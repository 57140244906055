import { FC } from 'react';
import { Button } from '@chakra-ui/react';

interface Props {
  onClick: () => void;
  label: string;
}

export const LegendButton: FC<Props> = ({ onClick, label }) => {
  return (
    <Button
      variant="main"
      size="md"
      height="44px"
      width={['50%', null, null, '300px']}
      fontSize={['size2', null, null, 'size6']}
      borderRadius="30px"
      onClick={onClick}
    >
      {label}
    </Button>
  );
};
