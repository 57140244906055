import { ChakraProvider } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { MdCheckCircle, MdCancel } from 'react-icons/md';
import { Index } from './pages';
import { AcceptHolidayAdmin } from './pages/AcceptHolidayAdmin';
import { AcceptHolidayEmployee } from './pages/AcceptHolidayEmployee';
import { CancelHolidayAdmin } from './pages/CancelHolidayAdmin';
import { HolidayActionNotification } from './pages/HolidayActionNotification';
import {
  selectCurrentUser,
  selectIsCompanyAdmin,
  selectUserLoading,
} from './redux/user/selectors';
import { fetchUser } from './redux/user/thunks';
import { store, useAppDispatch, useAppSelector } from './store';
import { theme } from './theme';
import { CompanyAdminRoutes } from './components/routes/CompanyAdmin';
import { UserRoutes } from './components/routes/User';
import { ToastContainer } from './utils/toasts';
import { AppSpinner } from './components/Layout/AppSpinner';
import { CenteredSpinner } from './components/common/CenteredSpinner';

import '@fontsource/roboto/300.css';
import {
  acceptHolidayAdminUrl,
  acceptHolidayEmployeeUrl,
  calendarUserPath,
  cancelHolidayAdminUrl,
  holidayFailedPath,
  holidaySuccessfulPath,
} from './utils/paths';

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <AppSpinner />
        <AuthenticatedApp />
      </Provider>
      <ToastContainer />
    </ChakraProvider>
  );
};

const AuthenticatedApp = () => {
  const dispatch = useAppDispatch();
  const isUserLoading = useAppSelector(selectUserLoading);
  const currentUser = useAppSelector(selectCurrentUser);
  const isCompanyAdmin = useAppSelector(selectIsCompanyAdmin);

  useEffect(() => {
    void dispatch(fetchUser());
  }, [dispatch]);

  const defaultUrl = currentUser ? calendarUserPath : '/';

  return isUserLoading ? (
    <CenteredSpinner />
  ) : (
    <Router>
      <Routes>
        {!currentUser && <Route path="/" element={<Index />} />}
        {currentUser && <Route path="/user/*" element={<UserRoutes />} />}
        {isCompanyAdmin && (
          <Route path="/company-admin/*" element={<CompanyAdminRoutes />} />
        )}
        <Route
          path={`/${acceptHolidayAdminUrl}/:holidayAlterToken/`}
          element={<AcceptHolidayAdmin />}
        />
        <Route
          path={`/${cancelHolidayAdminUrl}/:holidayAlterToken/`}
          element={<CancelHolidayAdmin />}
        />
        <Route
          path={`/${acceptHolidayEmployeeUrl}/:holidayAcceptToken/`}
          element={<AcceptHolidayEmployee />}
        />
        <Route
          path={holidaySuccessfulPath}
          element={
            <HolidayActionNotification
              icon={MdCheckCircle}
              iconColor="active"
            />
          }
        />
        <Route
          path={holidayFailedPath}
          element={
            <HolidayActionNotification
              icon={MdCancel}
              iconColor="failureIcon"
            />
          }
        />
        <Route path="*" element={<Navigate to={defaultUrl} replace />} />
      </Routes>
    </Router>
  );
};

export default App;
