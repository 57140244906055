import { FC, PropsWithChildren } from 'react';
import { List } from '@chakra-ui/react';

interface Props extends PropsWithChildren {
  minHeight?: string | number;
}

export const UserProfileList: FC<Props> = ({ children, minHeight }) => (
  <List
    pb={1}
    pl={4}
    overflowY="auto"
    h={44}
    minHeight={minHeight}
    borderLeft="1px solid"
    borderColor="active"
    alignItems="flex-start"
    width="100%"
  >
    {children}
  </List>
);
