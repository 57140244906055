import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AddPublicHolidayModel,
  PublicHolidayModel,
  AddPublicHolidayModelReturn,
} from '.';
import client from '../../api/axiosConfig';
import { handleError } from '../../utils/handleError';
import { ThunkError } from '../../types/thunk';
import { displaySuccessToast } from '../../utils/toasts';
import { formatToBackendDate } from '../../utils/date';

export const fetchPublicHolidays = createAsyncThunk<
  PublicHolidayModel[] | undefined
>('public-holidays/fetch', async (_, { rejectWithValue }) => {
  try {
    const response = await client.get<{ results: PublicHolidayModel[] }>(
      `people/public-holidays/`
    );

    return response.data.results;
  } catch (error) {
    handleError(error as ThunkError);
    return rejectWithValue((error as ThunkError)?.response?.data);
  }
});

export const addPublicHolidayAdmin = createAsyncThunk<
  AddPublicHolidayModelReturn | undefined,
  AddPublicHolidayModel
>(
  'admin/add/national-holiday',
  async (
    { dateFrom, dateTo, type, additionalInfo, users },
    { rejectWithValue }
  ) => {
    try {
      if (!dateFrom || !dateTo) return;
      const response = await client.post<{
        results: AddPublicHolidayModelReturn;
      }>('people/public-holidays/', {
        dateFrom: formatToBackendDate(dateFrom),
        dateTo: formatToBackendDate(dateTo),
        type,
        additionalInfo,
        users,
      });
      if (response.data.results)
        displaySuccessToast({ title: 'Dodano pomyślnie' });
      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);
