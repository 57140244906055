import {
  addDays,
  addYears,
  format,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';

export const dateFormats = {
  main: 'dd.MM.yyyy',
  backend: 'yyyy-MM-dd',
  backendLastDay: 'yyyy-12-31',
  year: 'yyyy',
};

export const dateTimeFormats = {
  main: 'dd.MM.yyyy HH:mm',
};

export const formatDate = (date: string | Date | number) =>
  format(new Date(date), dateFormats.main);
export const formatDateTime = (date: string | Date | number) =>
  format(new Date(date), dateTimeFormats.main);
export const formatToYear = (date: Date | number) =>
  format(date, dateFormats.year);
export const formatToBackendDate = (date: Date | number) =>
  format(date, dateFormats.backend);
export const formatToLastDay = (date: Date | number) =>
  format(date, dateFormats.backendLastDay);

export const getLastDayOfNextYear = () =>
  formatToLastDay(addYears(new Date(), 1));
export const getLastMonth = () => subMonths(new Date(), 1);
export const getLastYear = () => formatToYear(subYears(new Date(), 1));
export const getYear10YearsAgo = () => subYears(new Date(), 10);

export const correctToCalendarDate = (date: string) =>
  formatToBackendDate(addDays(new Date(date), 1));
export const correctFromCalendarDate = (date: Date) => subDays(date, 1);
export const correctFromCalendarDateTo = (date: Date) =>
  formatDate(subDays(new Date(date), 1));
