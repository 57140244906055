import { FC } from 'react';
import { Link, VStack, Text, Icon } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { Link as ReactLink, useLocation, Navigate } from 'react-router-dom';
import { Layout } from '../components/Layout';
import { HolidayActionNavigateState } from '../types/holidayActions';

interface Props {
  icon: IconType;
  iconColor: string;
}

export const HolidayActionNotification: FC<Props> = ({ icon, iconColor }) => {
  const location = useLocation() as {
    state: HolidayActionNavigateState | undefined;
  };

  return location.state ? (
    <Layout isFullBackground maxW="container.md">
      <VStack paddingX="8.1875rem" paddingTop="5.67rem">
        <Icon fontSize="4.1875rem" color={iconColor} as={icon} />
        <Text
          textTransform="uppercase"
          textAlign="center"
          fontSize="size14"
          paddingBottom="4.72rem"
        >
          {location.state.displayMessage}
        </Text>
        <Link
          as={ReactLink}
          to={location.state.returnLinkDestination}
          fontSize="size7"
          fontWeight={300}
          padding="0.5625rem"
          display="inline-block"
          _hover={{
            textDecoration: 'none',
            color: 'active',
          }}
        >
          <Text color="active" fontSize="size6">
            Wróć do kalendarza urlopów
          </Text>
        </Link>
      </VStack>
    </Layout>
  ) : (
    <Navigate to="/" />
  );
};
