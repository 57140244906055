import {
  Table,
  Thead,
  Tr,
  Tbody,
  TableContainer,
  Tfoot,
} from '@chakra-ui/react';
import { FC } from 'react';
import { StatsTableTd } from './statsTableTd';
import { DaysUsedByMonth } from '../../redux/statistics';
import { MONTH_LABELS } from '../../utils/consts';
import { MonthLabelsTr } from './monthLabelsTr';
import { TypeLabelsTr } from './typeLabelsTr';
import { getTableBackgroundColors, topBottomCellPadding } from './utils';

interface Props {
  columnSpacing: (string | null)[] | string;
  backgroundColors: [string, string];
  userStatsData: DaysUsedByMonth[];
}

export const UserStatsTable: FC<Props> = ({
  columnSpacing,
  backgroundColors,
  userStatsData,
}) => {
  const labels = [...MONTH_LABELS, 'SUMA'];
  const tableBackgroundColors = getTableBackgroundColors(
    labels,
    backgroundColors
  );

  return (
    <TableContainer overflowY="auto" width="100%">
      <Table variant="unstyled">
        <Thead borderBottomColor="active" borderBottomWidth="1px">
          <MonthLabelsTr
            columnSpacing={columnSpacing}
            tableBackgroundColors={tableBackgroundColors}
            labels={labels}
            innerPaddingTop={topBottomCellPadding}
            innerPaddingBottom={topBottomCellPadding}
          />
          <TypeLabelsTr
            columnSpacing={columnSpacing}
            tableBackgroundColors={tableBackgroundColors}
            indexOffset={labels.length}
            innerPaddingBottom={topBottomCellPadding}
          />
        </Thead>
        <Tbody>
          {userStatsData.map(({ userId, name, usedDaysByMonth, sums }) => {
            const data = [
              ...usedDaysByMonth,
              {
                holidays: sums.holidays,
                socialSecurityBenefitsDaysSum: sums.socialSecurityBenefits,
              },
            ];

            return (
              <Tr key={userId}>
                <StatsTableTd isForNames={true}>{name}</StatsTableTd>
                {data.map(
                  ({ holidays, socialSecurityBenefitsDaysSum }, index) => (
                    <>
                      <StatsTableTd
                        key={index}
                        innerPaddingLeft={columnSpacing}
                        innerPaddingRight="0"
                        fontSize="size6"
                        innerBackgroundColor={tableBackgroundColors[index]}
                      >
                        {holidays}
                      </StatsTableTd>
                      <StatsTableTd
                        key={labels.length + index}
                        innerPaddingLeft="0"
                        innerPaddingRight={columnSpacing}
                        fontSize="size6"
                        innerBackgroundColor={tableBackgroundColors[index]}
                      >
                        {socialSecurityBenefitsDaysSum}
                      </StatsTableTd>
                    </>
                  )
                )}
              </Tr>
            );
          })}
        </Tbody>
        <Tfoot borderTopColor="active" borderTopWidth="1px">
          <TypeLabelsTr
            columnSpacing={columnSpacing}
            tableBackgroundColors={tableBackgroundColors}
            indexOffset={labels.length}
            innerPaddingTop={topBottomCellPadding}
          />
          <MonthLabelsTr
            columnSpacing={columnSpacing}
            tableBackgroundColors={tableBackgroundColors}
            labels={labels}
            innerPaddingTop={topBottomCellPadding}
            innerPaddingBottom={topBottomCellPadding}
          />
        </Tfoot>
      </Table>
    </TableContainer>
  );
};
