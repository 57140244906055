import { Tr } from '@chakra-ui/react';
import { FC } from 'react';
import { Nullable } from '../../utils/consts';
import { StatsTableTh } from './statsTableTh';

interface Props {
  columnSpacing: (string | null)[] | string;
  tableBackgroundColors: string[];
  indexOffset: number;
  innerPaddingTop?: Nullable<string>[] | string;
  innerPaddingBottom?: Nullable<string>[] | string;
}

export const TypeLabelsTr: FC<Props> = ({
  columnSpacing,
  tableBackgroundColors,
  indexOffset,
  innerPaddingTop,
  innerPaddingBottom,
}) => (
  <Tr>
    <StatsTableTh isForNames={true} />
    {tableBackgroundColors.map((backgroundColor, index) => (
      <>
        <StatsTableTh
          innerBackgroundColor={backgroundColor}
          innerPaddingLeft={columnSpacing}
          innerPaddingRight="0"
          innerPaddingTop={innerPaddingTop}
          innerPaddingBottom={innerPaddingBottom}
          fontSize="size4"
          key={index}
        >
          URL
        </StatsTableTh>
        <StatsTableTh
          innerBackgroundColor={backgroundColor}
          innerPaddingLeft="0"
          innerPaddingRight={columnSpacing}
          innerPaddingTop={innerPaddingTop}
          innerPaddingBottom={innerPaddingBottom}
          fontSize="size4"
          key={indexOffset + index}
        >
          NO
        </StatsTableTh>
      </>
    ))}
  </Tr>
);
