import { Button, GridItem, Grid, VStack, Box } from '@chakra-ui/react';
import {
  DateIcon,
  TypeIcon,
  HolidayInfoIcon,
} from '../../../../../components/common/Icons';
import { FC, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormSelect } from '../../../../../components/form/inputs/Select';
import {
  AddUserHolidayAdmin,
  EditUserHolidayAdmin,
} from '../../../../../types/forms';
import { requiredValidation } from '../../../../../utils/validation';
import { options, subTypeOptions } from '../../../../../types/holidayTypes';
import { DatePicker } from '../../../../../components/form/inputs/DatePicker';
import { TextInput } from '../../../../../components/form/inputs/TextInput';
import { HolidayType } from '../../../../../redux/holidays';
import { getYear10YearsAgo } from '../../../../../utils/date';
import { CheckboxInput } from '../../../../../components/form/inputs/CheckboxInput';

interface Props {
  isEditing?: boolean;
}

export const HolidayForm: FC<Props> = ({ isEditing }) => {
  const { control, setValue } = useFormContext<
    AddUserHolidayAdmin | EditUserHolidayAdmin
  >();
  const dateWatcher = useWatch({ name: 'dateFrom', control });
  const isCheckedWatcher = useWatch({ name: 'isHalfDay', control });
  const typeWatcher = useWatch({ name: 'type', control });

  const [compassionateTypeSelected, setCompassionateTypeSelected] =
    useState(false);

  useEffect(() => {
    if (isCheckedWatcher) setValue('dateTo', dateWatcher);
  }, [setValue, dateWatcher, isCheckedWatcher]);

  useEffect(() => {
    if (typeWatcher == HolidayType.Compassionate) {
      setCompassionateTypeSelected(true);
    } else {
      setCompassionateTypeSelected(false);
    }
  }, [setCompassionateTypeSelected, typeWatcher]);

  return (
    <VStack
      alignItems="flex-start"
      width={isEditing ? undefined : ['100%', null, 'sm']}
    >
      <Grid
        paddingLeft={isEditing ? undefined : 4}
        alignItems={isEditing ? 'center' : 'inherit'}
        borderLeft={isEditing ? undefined : '1px solid'}
        borderColor={isEditing ? undefined : 'active'}
        templateColumns="0.1fr 1fr 1fr 1fr"
        columnGap={3}
        rowGap={isEditing ? 8 : 4}
      >
        <GridItem color="active">
          <DateIcon />
        </GridItem>
        <DatePicker
          label="data od"
          name="dateFrom"
          minDate={getYear10YearsAgo()}
          registerProps={requiredValidation}
          isStandalone
        />
        <DatePicker
          label="data do"
          name="dateTo"
          minDate={dateWatcher ? new Date(dateWatcher) : undefined}
          registerProps={requiredValidation}
          disabled={isCheckedWatcher}
          isStandalone
        />
        <GridItem maxHeight={5}>
          <CheckboxInput name="isHalfDay" label="0,5 dnia" />
        </GridItem>
        <GridItem color="active">
          <TypeIcon />
        </GridItem>
        <GridItem colSpan={3}>
          <FormSelect
            name="type"
            isStandalone
            label="rodzaj urlopu"
            registerProps={requiredValidation}
            options={options}
          />
        </GridItem>
        {compassionateTypeSelected && !isEditing ? (
          <>
            <GridItem color="active">
              <TypeIcon />
            </GridItem>
            <GridItem colSpan={3}>
              <FormSelect
                name="compassionateSubType"
                isStandalone
                label="powód"
                registerProps={requiredValidation}
                options={subTypeOptions}
              />
            </GridItem>
          </>
        ) : undefined}
        <GridItem color="active">
          <HolidayInfoIcon />
        </GridItem>
        <GridItem colSpan={3}>
          <TextInput name="addInfo" isStandalone label="dodatkowe uwagi" />
        </GridItem>
      </Grid>
      <Box paddingTop={2} alignSelf={isEditing ? 'center' : 'flex-end'}>
        <Button type="submit" variant="submitForm">
          Zapisz
        </Button>
      </Box>
    </VStack>
  );
};
