export const getDirtyValues = (
  dirtyFields: object | boolean,
  allValues: object
): object => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;

  const keys = Object.keys(dirtyFields) as (keyof object)[];

  return Object.fromEntries(
    keys.map((key) => [key, getDirtyValues(dirtyFields[key], allValues[key])])
  );
};
