import { colors } from '../../../theme';

export const navigationButtons = {
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '0.05rem',
  },
  iconButton: (side: 'left' | 'right') => ({
    width: '5rem',
    height: '3rem',
    borderLeftRadius: side === 'left' ? '1rem' : 0,
    borderRightRadius: side === 'right' ? '1rem' : 0,
    backgroundColor: colors.main,
    color: colors.light,
  }),
  button: {
    color: colors.light,
    backgroundColor: colors.main,
    width: '10rem',
    height: '3rem',
    borderRadius: '1rem',
    marginLeft: '1rem',
    _hover: { backgroundColor: colors.main },
  },
};

export const holidayInfo = {
  collapseButton: (isOpen: boolean) => ({
    backgroundColor: colors.transparent,
    _hover: {
      backgroundColor: colors.transparent,
    },
    _focus: {
      backgroundColor: colors.transparent,
    },
    alignSelf: isOpen ? 'flex-end' : 'flex-start',
  }),
  list: {
    overflow: 'auto',
    alignItems: 'left',
    position: 'relative',
    marginTop: '1.5625rem',
    height: ['auto', null, null, null, '43.75rem', '56.25rem'],
  },
  listContainer: {
    position: 'relative',
    width: '100%',
    alignSelf: 'flex-start',
  },
  scrollContainer: {
    padding: '0.0625rem',
    width: '100%',
    alignSelf: 'flex-start',
    minWidth: '16.875rem',
  },
};

export const adminCalendar = {
  container: (mobile: boolean) => {
    return {
      display: 'flex',
      flexDirection: mobile ? 'column' : 'row',
      height: '100%',
      width: '100%',
    };
  },
  text: {
    justifyContent: 'center',
    display: 'flex',
    fontSize: '4xl',
  },
};
