import { createSlice } from '@reduxjs/toolkit';
import { fetchUserDetails, fetchUsers, partiallyUpdateUser } from './thunks';

export interface UserListModel {
  id: number;
  lastName: string;
  firstName: string;
  position: string;
  dateOfBirth: string;
  contractData: { type?: string; dateFrom?: string; dateTo?: string };
}

export interface UserDetails {
  id: number;
  email: string;
  lastName: string;
  firstName: string;
  position: string;
  dateOfBirth: string;
  phoneNumber: string;
  emergencyNumber: string;
  streetName: string;
  city: string;
  currentPosition: string;
  description: string;
  inRevolve: string;
  experienceSince: string;
  days_off?: {
    [year: number]: number;
  };
  image?: {
    imageUrl: string;
  };
}

export interface UsersState {
  rows: UserListModel[];
  userDetails?: UserDetails;
  isLoading: boolean;
}

const initialState: UsersState = {
  rows: [],
  isLoading: true,
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rows = action?.payload ?? [];
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.isLoading = false;
        state.rows = [];
      })
      .addCase(fetchUserDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userDetails = action?.payload;
      })
      .addCase(fetchUserDetails.rejected, (state) => {
        state.isLoading = false;
        state.userDetails = undefined;
      })
      .addCase(partiallyUpdateUser.fulfilled, (state, { payload }) => {
        if (state.userDetails)
          state.userDetails = { ...state.userDetails, ...payload };
      })

      .addCase(partiallyUpdateUser.rejected, (state) => {
        state.userDetails = undefined;
      });
  },
});

export default userSlice.reducer;
