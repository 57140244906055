import 'react-datepicker/dist/react-datepicker.css';
import { components, OptionProps } from 'react-select';

import { SelectOption } from '../../../types/SelectOptions';

export const Option = <T = SelectOption,>(props: OptionProps<T>) => {
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        readOnly
        className="checkbox"
        style={{ marginRight: '10px', marginLeft: '2px' }}
      />
      {props.label}
    </components.Option>
  );
};
