import {
  DaysUsedByMonthSplit,
  HolidayTypeCountsByMonth,
} from '../../redux/statistics';
import { commonChartConfig, legendPaddingRight } from './utils';

export const getDatasetsForDaysUsedChart = (data: DaysUsedByMonthSplit) => {
  return [
    {
      label: 'Urlop w ramach puli',
      data: data.deductibleHolidays.map((value) => +value),
      borderColor: '#6F81FF',
      backgroundColor: '#6F81FF',
      ...commonChartConfig,
    },
    {
      label: 'Urlop poza pulą',
      data: data.nonDeductibleHolidays.map((value) => +value),
      borderColor: '#FF77B1',
      backgroundColor: '#FF77B1',
      ...commonChartConfig,
    },
    {
      label: 'L4 + opieka na dziecko',
      data: data.l4SocialSecurityBenefits,
      borderColor: '#FF9A80',
      backgroundColor: '#FF9A80',
      ...commonChartConfig,
    },
    {
      label: 'Pozostałe świadczenia',
      data: data.nonL4SocialSecurityBenefits,
      borderColor: '#008A65',
      backgroundColor: '#008A65',
      ...commonChartConfig,
    },
  ];
};

export const getDatasetsForHolidayTypesChart = (
  data: HolidayTypeCountsByMonth
) => {
  return [
    {
      label: 'Wypoczynkowy',
      data: data.leisure,
      borderColor: '#FF9AA2',
      backgroundColor: '#FF9AA2',
      ...commonChartConfig,
    },
    {
      label: 'Na żądanie',
      data: data.onDemand,
      borderColor: '#FFDAC1',
      backgroundColor: '#FFDAC1',
      ...commonChartConfig,
    },
    {
      label: 'Firmowy',
      data: data.company,
      borderColor: '#B5EAD7',
      backgroundColor: '#B5EAD7',
      ...commonChartConfig,
    },
    {
      label: 'Bezpłatny',
      data: data.unpaid,
      borderColor: '#C7CEEA',
      backgroundColor: '#C7CEEA',
      ...commonChartConfig,
    },
    {
      label: 'Okolicznościowy' + legendPaddingRight,
      data: data.compassionate,
      borderColor: '#C6DBDF',
      backgroundColor: '#C6DBDF',
      ...commonChartConfig,
    },
    {
      label: 'Opiekuńczy',
      data: data.care,
      borderColor: '#FFF5BA',
      backgroundColor: '#FFF5BA',
      ...commonChartConfig,
    },
    {
      label: 'Za święto',
      data: data.holidayComp,
      borderColor: '#D291BC',
      backgroundColor: '#D291BC',
      ...commonChartConfig,
    },
    {
      label: 'Za delegację',
      data: data.delegationComp,
      borderColor: '#E7FFAC',
      backgroundColor: '#E7FFAC',
      ...commonChartConfig,
    },
    {
      label: 'Suma',
      data: data.sum,
      borderColor: '#8ED3F3',
      backgroundColor: '#8ED3F3',
      ...commonChartConfig,
    },
  ];
};
