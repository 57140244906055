import { Button, Heading, VStack, Link } from '@chakra-ui/react';
import { FC, FormEvent } from 'react';
import { logOut } from '../redux/user';
import { useAppDispatch } from '../store';
import { getCookie } from 'typescript-cookie';
import {
  CSRF_TOKEN_COOKIE_NAME,
  CSRF_HIDDEN_FORM_FIELD_NAME,
} from '../api/axiosConfig';
import { Layout } from '../components/Layout';
import { FcGoogle } from 'react-icons/fc';
import { AuthHTMLFormElement } from '../types/forms';
import { colors } from '../theme';

export const Index: FC = () => {
  const dispatch = useAppDispatch();
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    // update CSRF token hidden field value on submit
    (e.target as AuthHTMLFormElement)[CSRF_HIDDEN_FORM_FIELD_NAME].value =
      getCookie(CSRF_TOKEN_COOKIE_NAME);
  };

  return (
    <Layout isFullBackground maxW="container.sm">
      <Heading textAlign="center" fontWeight={300} size="lg" my={8}>
        ZALOGUJ SIĘ
        <br />
        DO CZĘŚCI URLOPOWEJ
      </Heading>
      <VStack as="nav">
        <form
          method="POST"
          action={`${
            process.env.REACT_APP_BACKEND_URL || ''
          }/accounts/google/login/`}
          onSubmit={handleSubmit}
        >
          <input type="hidden" name={CSRF_HIDDEN_FORM_FIELD_NAME} value="" />
          <Button
            type="submit"
            bg="light"
            boxShadow={`0 3px 6px ${colors.shadow}`}
            borderRadius={32}
            leftIcon={<FcGoogle size={32} />}
            px={4}
            py={6}
            mb={8}
            onClick={() => dispatch(logOut())}
          >
            ZALOGUJ PRZEZ GOOGLE
          </Button>
        </form>
        <Link color="active" href={process.env.REACT_APP_INTRANET_URL}>
          Cofnij
        </Link>
      </VStack>
    </Layout>
  );
};
