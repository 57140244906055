import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  acceptHolidayAsAdmin,
  acceptHolidayFromCalendarAsAdmin,
  addHolidayAsAdmin,
  cancelHolidayAsAdmin,
  cancelHolidayFromCalendarAsAdmin,
  editHoliday,
  fetchUserHolidays as fetchUserHolidaysAdmin,
} from '../holidaysAdmin/thunks';
import { addPublicHolidayAdmin } from '../publicHolidays/thunks';
import {
  addHolidayAsEmployee,
  cancelHolidayAsEmployee,
  fetchUsersWithHolidays,
  generateHolidayReport,
} from './thunks';
import { ALL_HOLIDAYS } from '../../utils/consts';

export enum HolidayType {
  Leisure = 'leisure',
  OnDemand = 'on_demand',
  Company = 'company',
  Unpaid = 'unpaid',
  Compassionate = 'compassionate',
  Care = 'care',
  HolidayComp = 'holiday_comp',
  DelegationComp = 'delegation_comp',
  NationalHoliday = 'national_holiday',
}

export interface HolidayModel {
  id: number;
  dateFrom: string;
  dateTo: string;
  type: HolidayType;
  addInfo: string | null;
  cancellationInfo: string | null;
  isAcceptedByAdmin: boolean;
  isAcceptedByEmployee: boolean;
  isCancelled: boolean;
  isHalfDay: boolean;
  createdAt: string;
  cancelledAt: string | null;
}

export interface HolidayWithAnonymizedTypeModel
  extends Omit<HolidayModel, 'type'> {
  type: HolidayType | null;
}

export interface HolidayAddModel {
  dateTo: Date | null;
  dateFrom: Date | null;
  type: string;
  addInfo?: string;
  user: number;
  isAcceptedByEmployee: boolean;
  isHalfDay: boolean;
}

export interface HolidayAddModelForm extends HolidayAddModel {
  compassionateSubType?: string;
}

export interface HolidayWithUserModel extends HolidayWithAnonymizedTypeModel {
  lastName: string;
  firstName: string;
  user: number;
}

export interface UserWithHolidaysModel {
  id: number;
  lastName: string;
  firstName: string;
  holidaySet: HolidayWithUserModel[];
  userId?: string;
  addInfo?: string;
}

export interface DataForReportInterface {
  reportType: string;
  dateFrom: Date;
  dateTo: Date;
  reportScope: string;
  contractsType?: string;
  employees?: number[];
}

export interface HolidaysState {
  currentHolidays: UserWithHolidaysModel | null;
  holidayUsers: UserWithHolidaysModel[];
  userProfileHolidays: HolidayModel[];
  userId?: number;
  isLoading: boolean;
  isLoadingGenerateReport: boolean;
}

export interface DeleteUserHolidayForm {
  id: number;
  isCancelled?: boolean;
  cancellationInfo?: string;
}

export interface DeleteUserHolidayFormReturn extends DeleteUserHolidayForm {
  user: number;
}

const initialState: HolidaysState = {
  currentHolidays: null,
  holidayUsers: [],
  userProfileHolidays: [],
  isLoading: true,
  isLoadingGenerateReport: false,
};

const holidaySlice = createSlice({
  name: 'holiday',
  initialState,
  reducers: {
    setCurrentHolidays: (state, { payload }: PayloadAction<number>) => {
      // When payload is 0, then all holidays should be set to current
      state.currentHolidays =
        payload === ALL_HOLIDAYS
          ? {
              ...state.holidayUsers.reduce(
                ({ holidaySet }, { holidaySet: prevHolidaySet }) => ({
                  holidaySet: [...prevHolidaySet, ...holidaySet],
                  id: payload,
                  firstName: '',
                  lastName: '',
                })
              ),
            }
          : state.holidayUsers.find(({ id }) => id === payload) ?? null;
    },
  },
  extraReducers: (builder) => {
    builder
      // ---------------------------- CALENDAR HOLIDAYS ----------------------------
      .addCase(fetchUsersWithHolidays.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUsersWithHolidays.fulfilled, (state, action) => {
        state.isLoading = false;

        if (action.payload != null) {
          state.holidayUsers = action.payload;
        } else {
          state.currentHolidays = null;
        }
      })
      .addCase(fetchUsersWithHolidays.rejected, (state) => {
        state.isLoading = false;
        state.holidayUsers = [];
      })
      .addCase(addHolidayAsEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addHolidayAsEmployee.fulfilled, (state, { payload }) => {
        if (state.currentHolidays?.holidaySet && payload) {
          state.currentHolidays.holidaySet = [
            ...state.currentHolidays.holidaySet,
            payload,
          ];
        }

        state.isLoading = false;
      })
      .addCase(addHolidayAsEmployee.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(cancelHolidayAsEmployee.fulfilled, (state, { payload }) => {
        if (!payload) return;

        if (state.currentHolidays?.holidaySet) {
          state.currentHolidays.holidaySet =
            state.currentHolidays.holidaySet.filter(
              (holidayUsers) => holidayUsers.id !== payload.id
            );
        }

        const deletedHolidayUserIndex = state.holidayUsers.findIndex(
          ({ id }) => id === payload.user
        );

        if (deletedHolidayUserIndex !== -1) {
          state.holidayUsers[deletedHolidayUserIndex].holidaySet =
            state.holidayUsers[deletedHolidayUserIndex].holidaySet.filter(
              (holidayUser) => holidayUser.id !== payload.id
            );
        }
      })
      .addCase(addPublicHolidayAdmin.fulfilled, (state, { payload }) => {
        if (state.currentHolidays?.holidaySet && payload?.createdHolidays) {
          state.currentHolidays.holidaySet = [
            ...state.currentHolidays.holidaySet,
            ...payload.createdHolidays,
          ];
        }
      })
      .addCase(
        acceptHolidayFromCalendarAsAdmin.fulfilled,
        (state, { payload }) => {
          if (payload && state.currentHolidays?.holidaySet) {
            const { id, ...rest } = payload;

            state.currentHolidays.holidaySet =
              state.currentHolidays.holidaySet.map((holiday) => {
                const newHoliday = { ...holiday, ...rest };

                return holiday.id === id ? newHoliday : holiday;
              });
          }
        }
      )
      .addCase(
        cancelHolidayFromCalendarAsAdmin.fulfilled,
        (state, { payload }) => {
          if (!payload) return;

          if (state.currentHolidays?.holidaySet) {
            state.currentHolidays.holidaySet =
              state.currentHolidays.holidaySet.filter(
                (holidayUsers) => holidayUsers.id !== payload.id
              );
          }

          const deletedHolidayUserIndex = state.holidayUsers.findIndex(
            ({ id }) => id === payload.user
          );

          if (deletedHolidayUserIndex !== -1) {
            state.holidayUsers[deletedHolidayUserIndex].holidaySet =
              state.holidayUsers[deletedHolidayUserIndex].holidaySet.filter(
                (holidayUser) => holidayUser.id !== payload.id
              );
          }
        }
      )

      // ---------------------------- END OF CALENDAR HOLIDAYS ----------------------------
      // ---------------------------- PROFILE HOLIDAYS ----------------------------
      .addCase(fetchUserHolidaysAdmin.rejected, (state) => {
        state.isLoading = false;
        state.userProfileHolidays = [];
      })
      .addCase(fetchUserHolidaysAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserHolidaysAdmin.fulfilled, (state, { payload }) => {
        state.isLoading = false;

        if (payload != null) {
          state.userProfileHolidays = payload ?? [];
        } else {
          state.userProfileHolidays = [];
        }
      })
      .addCase(addHolidayAsAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addHolidayAsAdmin.fulfilled, (state, { payload }) => {
        state.isLoading = false;

        if (state.userProfileHolidays && payload) {
          state.userProfileHolidays = [
            ...state.userProfileHolidays,
            payload,
          ].sort((userHolidays, payload) => {
            return payload.dateFrom.localeCompare(userHolidays.dateFrom);
          });
        }
      })
      .addCase(addHolidayAsAdmin.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(editHoliday.fulfilled, (state, { payload }) => {
        if (payload) {
          const { id, ...rest } = payload;

          state.userProfileHolidays = state.userProfileHolidays.map(
            (holiday) => {
              const newHoliday = { ...holiday, ...rest };

              return holiday.id === id ? newHoliday : holiday;
            }
          );
        }
      })
      .addCase(acceptHolidayAsAdmin.fulfilled, (state, { payload }) => {
        const index = state.userProfileHolidays.findIndex(
          (holiday) => payload?.id === holiday.id
        );

        if (index !== -1) {
          if (payload) state.userProfileHolidays[index] = payload;
        }
      })
      .addCase(cancelHolidayAsAdmin.fulfilled, (state, { payload }) => {
        const index = state.userProfileHolidays.findIndex(
          (holiday) => payload?.id === holiday.id
        );

        if (index !== -1) {
          if (payload) state.userProfileHolidays[index] = payload;
        }
      })
      .addCase(generateHolidayReport.rejected, (state) => {
        state.isLoadingGenerateReport = false;
      })
      .addCase(generateHolidayReport.pending, (state) => {
        state.isLoadingGenerateReport = true;
      })
      .addCase(generateHolidayReport.fulfilled, (state) => {
        state.isLoadingGenerateReport = false;
      });
    // ---------------------------- END OF PROFILE HOLIDAYS ----------------------------
  },
});

export const { setCurrentHolidays } = holidaySlice.actions;

export default holidaySlice.reducer;
