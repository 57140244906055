import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserHolidayPool } from '.';
import client from '../../api/axiosConfig';
import {
  DeleteUserHolidayPool,
  EditUserHolidayPoolForm,
} from '../../types/forms';
import { ThunkError } from '../../types/thunk';
import { AddUserHolidayPoolForm } from '../../types/forms';
import { displaySuccessToast } from '../../utils/toasts';
import { handleError } from '../../utils/handleError';

export const addUserHolidayPool = createAsyncThunk<
  UserHolidayPool | undefined,
  AddUserHolidayPoolForm
>('holidayPoolId/addHolidayPool', async (inputData, { rejectWithValue }) => {
  try {
    const response = await client.post<{ results: UserHolidayPool }>(
      `people/holiday-pool/`,
      inputData
    );
    if (response.data.results)
      displaySuccessToast({ title: 'Dodano pomyślnie' });
    return response.data.results;
  } catch (error) {
    handleError(error as ThunkError);
    return rejectWithValue((error as ThunkError)?.response?.data);
  }
});

export const editUserHolidayPool = createAsyncThunk<
  EditUserHolidayPoolForm | undefined,
  EditUserHolidayPoolForm
>(
  'holidayPoolId/editHolidayPool',
  async ({ id, ...rest }, { rejectWithValue }) => {
    try {
      const response = await client.patch<{ results: EditUserHolidayPoolForm }>(
        `people/holiday-pool/${id}/`,
        { ...rest }
      );
      displaySuccessToast({ title: 'Edytowano pomyślnie' });
      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

export const fetchUserHolidayPools = createAsyncThunk<
  UserHolidayPool[] | undefined,
  number
>('holidayPoolId/fetch', async (id, { rejectWithValue }) => {
  try {
    const response = await client.get<{ results: UserHolidayPool[] }>(
      `people/holiday-pool/?user=${id}&ordering=-year`
    );
    return response.data.results;
  } catch (error) {
    handleError(error as ThunkError);
    return rejectWithValue((error as ThunkError)?.response?.data);
  }
});

export const fetchSpecificYearUsersHolidayPool = createAsyncThunk<
  UserHolidayPool[] | undefined,
  { year: number; orderingField?: string }
>(
  'holidayPoolYearId/fetch',
  async ({ year, orderingField }, { rejectWithValue }) => {
    try {
      const response = await client.get<{ results: UserHolidayPool[] }>(
        `people/holiday-pool/?year=${year}${
          orderingField ? `&ordering=${orderingField}` : ''
        }`
      );
      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

export const deleteUserHolidayPool = createAsyncThunk<
  DeleteUserHolidayPool | undefined,
  number
>('holidayPoolId/delete', async (id, { rejectWithValue }) => {
  try {
    const response = await client.delete<{ results: undefined }>(
      `people/holiday-pool/${id}/`
    );
    return { results: response.data.results, id };
  } catch (error) {
    handleError(error as ThunkError);
    return rejectWithValue((error as ThunkError)?.response?.data);
  }
});
