import { createSlice } from '@reduxjs/toolkit';
import {
  fetchUsedDaysStatsForChart,
  fetchHolidayTypesStatsForChart,
  fetchStatsForTable,
} from './thunks';

export interface DaysUsedByMonth {
  userId: number;
  name: string;
  sums: {
    holidays: number;
    socialSecurityBenefits: number;
  };
  usedDaysByMonth: {
    holidays: number;
    socialSecurityBenefitsDaysSum: number;
  }[];
}

export interface DaysUsedByMonthSplit {
  deductibleHolidays: string[];
  nonDeductibleHolidays: string[];
  l4SocialSecurityBenefits: number[];
  nonL4SocialSecurityBenefits: number[];
}

export interface HolidayTypeCountsByMonth {
  leisure: number[];
  onDemand: number[];
  company: number[];
  unpaid: number[];
  compassionate: number[];
  care: number[];
  holidayComp: number[];
  delegationComp: number[];
  sum: number[];
}

export interface StatisticsState {
  isLoadingStatsForTable: boolean;
  isLoadingDaysUsedStatsForChart: boolean;
  isLoadingHolidayTypesStatsForChart: boolean;
  userStatsTable: DaysUsedByMonth[];
  userDaysUsedStatsChart?: DaysUsedByMonthSplit;
  userHolidayTypesStatsChart?: HolidayTypeCountsByMonth;
}

const initialState: StatisticsState = {
  isLoadingStatsForTable: false,
  isLoadingDaysUsedStatsForChart: false,
  isLoadingHolidayTypesStatsForChart: false,
  userStatsTable: [],
  userDaysUsedStatsChart: undefined,
};

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatsForTable.pending, (state) => {
        state.isLoadingStatsForTable = true;
      })
      .addCase(fetchStatsForTable.fulfilled, (state, action) => {
        state.isLoadingStatsForTable = false;
        state.userStatsTable = action?.payload ?? [];
      })
      .addCase(fetchStatsForTable.rejected, (state) => {
        state.isLoadingStatsForTable = false;
        state.userStatsTable = [];
      })
      .addCase(fetchUsedDaysStatsForChart.pending, (state) => {
        state.isLoadingDaysUsedStatsForChart = true;
      })
      .addCase(fetchUsedDaysStatsForChart.fulfilled, (state, action) => {
        state.isLoadingDaysUsedStatsForChart = false;
        state.userDaysUsedStatsChart = action?.payload;
      })
      .addCase(fetchUsedDaysStatsForChart.rejected, (state) => {
        state.isLoadingDaysUsedStatsForChart = false;
        state.userDaysUsedStatsChart = undefined;
      })
      .addCase(fetchHolidayTypesStatsForChart.pending, (state) => {
        state.isLoadingHolidayTypesStatsForChart = true;
      })
      .addCase(fetchHolidayTypesStatsForChart.fulfilled, (state, action) => {
        state.isLoadingHolidayTypesStatsForChart = false;
        state.userHolidayTypesStatsChart = action?.payload;
      })
      .addCase(fetchHolidayTypesStatsForChart.rejected, (state) => {
        state.isLoadingHolidayTypesStatsForChart = false;
        state.userDaysUsedStatsChart = undefined;
      });
  },
});
export default statisticsSlice.reducer;
