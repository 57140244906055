import Select, { ActionMeta, SingleValue } from 'react-select';
import { FC } from 'react';
import './select.scss';
import { MultiSelectOption } from '../../../types/multiSelectOption';
import { DropdownIndicator } from '../../form/inputs/DropDownIcon';

interface Props {
  options: MultiSelectOption[];
  onChange: (
    newValue: SingleValue<MultiSelectOption>,
    actionMeta: ActionMeta<MultiSelectOption>
  ) => void;
  onMenuClose?: () => void;
  placeholder?: string;
  currentValue?: MultiSelectOption;
  defaultValue?: MultiSelectOption;
}

export const ScrollableSelect: FC<Props> = ({
  options,
  onChange,
  onMenuClose,
  placeholder,
  currentValue,
  defaultValue,
}) => {
  return (
    <Select
      placeholder={placeholder}
      onChange={onChange}
      value={currentValue}
      defaultValue={defaultValue}
      onMenuClose={onMenuClose}
      autoFocus={false}
      options={options}
      hideSelectedOptions={false}
      className="scrollable-select"
      isClearable={false}
      isSearchable={false}
      isMulti={false}
      components={{ DropdownIndicator }}
    />
  );
};
