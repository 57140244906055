import {
  Controller,
  ControllerFieldState,
  FieldValues,
  Noop,
  RefCallBack,
  useFormContext,
  UseFormStateReturn,
} from 'react-hook-form';
import { DefaultInputProps, InputWrapper } from '../InputWrapper';
import { defaultValidation } from '../../../utils/validation';
import 'react-datepicker/dist/react-datepicker.css';
import { Box } from '@chakra-ui/react';
import Select, {
  GroupBase,
  Options,
  OptionsOrGroups,
  PropsValue,
} from 'react-select';
import { SelectOption } from '../../../types/SelectOptions';
import './multiSelect.scss';
import { DropdownIndicator } from './DropDownIcon';
import { Option } from './Option';
import { BaseProps } from '../../../types/multiSelect';

interface Props<T> extends DefaultInputProps, BaseProps<T> {
  options: OptionsOrGroups<T, GroupBase<T>> | undefined;
  propsValue?: PropsValue<T>;
  isOptionSelected?:
    | ((option: T, selectValue: Options<T>) => boolean)
    | undefined;
  isAll: boolean;
}

interface RenderProps<T> {
  field: {
    onBlur: Noop;
    name: string;
    value: PropsValue<T> | undefined;
    ref: RefCallBack;
  };
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
}
export const MultiSelect = <T extends SelectOption>({
  registerProps = defaultValidation,
  name,
  label,
  helperTxt,
  options,
  isStandalone,
  isOptionSelected,
  propsValue,
  isAll,
  onChange,
  onMenuClose,
}: Props<T>) => {
  const { control } = useFormContext();

  return (
    <InputWrapper
      name={name}
      label={label}
      helperTxt={helperTxt}
      isStandalone={isStandalone}
    >
      <Controller
        rules={registerProps}
        control={control}
        name={name}
        render={({ field: { onBlur, value } }: RenderProps<T>) => (
          <Box data-value={value?.toString() === '' ? undefined : value}>
            <Select
              placeholder=""
              onChange={onChange}
              onMenuClose={onMenuClose}
              value={isAll ? propsValue : value}
              onBlur={onBlur}
              autoFocus={false}
              options={options}
              isOptionSelected={isOptionSelected}
              controlShouldRenderValue={false}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              hideSelectedOptions={false}
              isMulti={true}
              className="multi"
              components={{ DropdownIndicator, Option }}
            />
          </Box>
        )}
      />
    </InputWrapper>
  );
};
