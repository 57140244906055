import { createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../api/axiosConfig';
import { UserDetails, UserListModel } from '.';
import {
  EditProfileThunk,
  ThunkError,
  orderingFieldInterface,
} from '../../types/thunk';
import { displaySuccessToast } from '../../utils/toasts';
import { handleError } from '../../utils/handleError';

export const fetchUsers = createAsyncThunk<
  UserListModel[] | undefined,
  orderingFieldInterface | undefined
>('users/fetch', async (orderingInfo, { rejectWithValue }) => {
  try {
    const response = await client.get<{ results: UserListModel[] }>(
      'users/users/',
      {
        params: {
          ...(orderingInfo
            ? {
                ordering: `${orderingInfo.desc ? '-' : ''}${
                  orderingInfo.orderingFieldName
                }`,
              }
            : {}),
        },
      }
    );

    return response.data.results;
  } catch (error) {
    handleError(error as ThunkError);
    return rejectWithValue((error as ThunkError)?.response?.data);
  }
});

export const fetchUserDetails = createAsyncThunk<
  UserDetails | undefined,
  number
>('userId/fetch', async (id, { rejectWithValue }) => {
  try {
    const response = await client.get<{ results: UserDetails }>(
      `users/users/${id}/`
    );

    return response.data.results;
  } catch (error) {
    handleError(error as ThunkError);
    return rejectWithValue((error as ThunkError)?.response?.data);
  }
});

export const partiallyUpdateUser = createAsyncThunk<
  EditProfileThunk | undefined,
  EditProfileThunk
>('userId/partiallyUpdate', async ({ id, ...rest }, { rejectWithValue }) => {
  try {
    const response = await client.patch<{ results: EditProfileThunk }>(
      `users/users/${id}/`,
      { ...rest }
    );
    if (response.data.results)
      displaySuccessToast({ title: 'Edytowano pomyślnie' });

    return { ...response.data.results, id };
  } catch (error) {
    handleError(error as ThunkError);
    return rejectWithValue((error as ThunkError)?.response?.data);
  }
});
