import { useNavigate } from 'react-router-dom';
import client from '../api/axiosConfig';
import { ThunkError } from '../types/thunk';
import { getHolidayActionErrorVerboseMessage } from './handleError';
import { holidayFailedPath, holidaySuccessfulPath } from './paths';

export const sendHolidayActionRequest = async (
  holidayActionUrl: string,
  holidayAlterToken: string
): Promise<'success' | ThunkError> => {
  try {
    await client.patch(`people/${holidayActionUrl}/${holidayAlterToken}/`);
    return 'success' as const;
  } catch (error) {
    return error as ThunkError;
  }
};

export function useNavigateAfterHolidayAcion() {
  const navigate = useNavigate();

  const navigateAfterHolidayAction = (
    data: 'success' | ThunkError,
    successMessage: string,
    returnLinkDestination: string
  ) => {
    if (data === 'success') {
      navigate(holidaySuccessfulPath, {
        state: {
          displayMessage: successMessage,
          returnLinkDestination: returnLinkDestination,
        },
      });
    } else {
      navigate(holidayFailedPath, {
        state: {
          displayMessage: getHolidayActionErrorVerboseMessage(data),
          returnLinkDestination: returnLinkDestination,
        },
      });
    }
  };

  return navigateAfterHolidayAction;
}
